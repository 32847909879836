import gql from 'graphql-tag';

export const monthStatisticsFragment = gql`
  fragment monthStatisticsFragment on MonthStatistics {
    average
    current
    m1
    m2
    m3
    m4
    m5
    m6
  }
`;
export const monthStatisticsFloatFragment = gql`
  fragment monthStatisticsFloatFragment on MonthStatisticsFloat {
    average
    current
    m1
    m2
    m3
    m4
    m5
    m6
  }
`;

export const keyStatisticsFragment = gql`
  fragment keyStatisticsFragment on KeyStatistics {
    availabilities {
      ...monthStatisticsFragment
    }
    newNurseries {
      ...monthStatisticsFragment
    }
    nurseriesWithAvailabilities {
      ...monthStatisticsFragment
    }
    registrationAccepted {
      ...monthStatisticsFragment
    }
    reservationAccepted {
      ...monthStatisticsFragment
    }
    reservationCancelled {
      ...monthStatisticsFragment
    }
    reservationRefused {
      ...monthStatisticsFragment
    }
    revenues {
      ...monthStatisticsFloatFragment
    }
    revenuesCancelled {
      ...monthStatisticsFloatFragment
    }
    revenuesPAJE {
      ...monthStatisticsFloatFragment
    }
    revenuesPSU {
      ...monthStatisticsFloatFragment
    }
  }
  ${monthStatisticsFragment}
  ${monthStatisticsFloatFragment}
`;
