

















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AlertsModule } from '@store';
import { Alerts, Forms } from '@constructors';
import StarRating from '../StarRating/StarRating.vue';
import FormContainer from '../Forms/FormContainer.vue';
import { EventBus, Events } from '@services';
import Colors from '@colors';

@Component({
  components: {
    FormContainer,
  },
})
export default class AlertsContainer extends Vue {
  $refs: {
    baseAlert: HTMLElement;
    alertWindow: HTMLElement;
  };

  get isForm() {
    return this.alertContent.type === 'form';
  }

  get width() {
    return this.alertContent.width;
  }

  get title() {
    return this.alertContent.title;
  }

  get getForm() {
    return this.alertContent.getForm;
  }

  set getForm(value: Forms.Form<any>) {
    this.alertContent.getForm = value;
  }

  get values() {
    return this.getForm?.fieldsValues;
  }

  get getTheme() {
    return (button: Alerts.Action) => {
      if (button.type === 'confirm') {
        return {
          theme: 'yellow',
        };
      } else if (button.type === 'action' || button.type === 'link') {
        return { theme: 'white' };
      } else {
        return {
          theme: 'white',
        };
      }
    };
  }

  get rightButtons() {
    return this.alertContent.actions.filter((m) => !!m).filter((m) => m.type !== 'cancel');
  }
  get leftButtons() {
    return this.alertContent.actions.filter((m) => !!m).filter((m) => m.type === 'cancel');
  }

  get description() {
    return this.alertContent.description;
  }

  get alertContent() {
    return AlertsModule.state.content;
  }

  touchForm() {
    if (this.getForm.$v && this.getForm.$v.form) {
      this.getForm.$v.form.$touch();
    }
  }

  deleteAlert(e) {
    AlertsModule.actions.deleteAlert();
  }
}
