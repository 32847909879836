





















import Vue, { VNode, Component as VueComponent } from 'vue';
import { Component, Prop, Model, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import Colors from '@colors';
import { hexToRgba } from '@methods';

@Component({})
export default class VButton extends Vue {
  @Prop({ required: false })
  submitting!: boolean;
  @Prop({ required: false })
  disabled!: boolean;
  @Prop({ required: false, default: 'button' })
  type!: string;
  @Prop()
  to!: string | Location;
  @Prop() icon: string;
  @Prop({ default: 'yellow' })
  theme!: 'blue' | 'red' | 'dark' | 'yellow' | 'white';
  @Prop({ default: 'fill' }) buttonStyle: 'fill' | 'border' | 'shadow';
  @Prop({ type: [String, Object] }) textColor: string | Object;
  @Prop({ default: 'little' })
  size!: 'mini' | 'little' | 'alert' | 'medium';
  @Prop({ default: false }) rounded!: boolean;
  @Prop() handler: () => void;
  @Prop() active: boolean;
  @Prop() full: boolean;
  @Prop() form: string;

  public loading = false;

  $refs: {
    button: HTMLElement | Vue;
  };

  get getSize() {
    if (this.size === 'mini') return 14;
    return 16;
  }

  get activeTextColor(): string {
    if (typeof this.textColor === 'string') {
      return Colors[this.textColor] || this.textColor;
    } else if (this.textColor != null && typeof this.textColor === 'object') {
      let keys = Object.keys(this.textColor);
      let filtered = keys.filter((key) => this.textColor[key]);
      return filtered[0] || null;
    } else {
      return null;
    }
  }

  get themeVars(): { bg: string; text: string; bgHover: string } {
    if (this.buttonStyle === 'fill' || this.buttonStyle === 'shadow') {
      if (this.theme === 'blue') {
        return {
          bg: Colors.blue,
          text: 'white',
          bgHover: hexToRgba(Colors.blue, 0.8),
        };
      } else if (this.theme === 'red') {
        return {
          bg: Colors.red1,
          text: 'white',
          bgHover: hexToRgba(Colors.red1, 0.8),
        };
      } else if (this.theme === 'yellow') {
        return {
          bg: Colors.yellow,
          text: Colors.w255,
          bgHover: hexToRgba(Colors.yellow, 0.8),
        };
      } else if (this.theme === 'white') {
        return {
          bg: 'white',
          text: Colors.g80,
          bgHover: hexToRgba(Colors.w240, 0.8),
        };
      } else {
        return {
          bg: Colors.g60,
          text: 'white',
          bgHover: hexToRgba(Colors.g60, 0.8),
        };
      }
    } else {
      return {
        bg: 'white',
        text: Colors[this.theme] || this.theme,
        bgHover: hexToRgba(Colors.w240, 0.8),
      };
    }
  }

  get getLoaderColor(): string {
    return this.themeVars.text;
  }

  async emitClick(event: Event) {
    if (this.to) {
      event.preventDefault();
    } else if (!this.submitting && !this.disabled) {
      if (this.handler && this.handler instanceof Function) {
        try {
          this.loading = true;
          await this.handler();
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      } else {
        if (this.type == 'button') this.$emit('click', event);
        else if (this.type == 'submit') this.$emit('submit', event);
      }
    } else if (this.disabled) {
      event.preventDefault();
      this.$emit('disabledClick', event);
    } else {
      event.preventDefault();
    }
  }

  @Watch('theme')
  initTheme() {
    if (this.$refs.button instanceof Vue) {
      const button = this.$refs.button as Vue;
      const $el = button.$el as HTMLElement;
      $el.style.setProperty('--button-bg', this.themeVars.bg);
      $el.style.setProperty('--button-text', this.activeTextColor || this.themeVars.text);
      $el.style.setProperty('--button-bgHover', this.themeVars.bgHover);
    } else if (this.$refs.button instanceof HTMLElement) {
      this.$refs.button.style.setProperty('--button-bg', this.themeVars.bg);
      this.$refs.button.style.setProperty(
        '--button-text',
        this.activeTextColor || this.themeVars.text
      );
      this.$refs.button.style.setProperty('--button-bgHover', this.themeVars.bgHover);
    }
  }
  mounted() {
    this.initTheme();
  }
}
