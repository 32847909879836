



























import { Component, Vue } from 'vue-property-decorator';
import NavTab from './NavTab.vue';
import { NavLink } from '@models';
import { routesNames } from '@router';
import { DirectorNurseryModule, AuthModule } from '../../store';

@Component({ components: { NavTab } })
export default class NavBar extends Vue {
  public responsiveMenu = false;
  public navWidth: number = null;
  public $refs: {
    nav: HTMLElement;
  };

  get isCommunityNursery() {
    if (AuthModule.getters.isAdmin) return false;
    else return DirectorNurseryModule?.state?.nursery?.communityNursery === true;
  }

  public navLinksAdmin: NavLink[] = [
    {
      label: 'Crèches',
      link: {
        name: routesNames.admin.CRECHES,
      },
    },
    {
      label: 'Inscrits',
      link: {
        name: routesNames.admin.INSCRITS.LIST,
      },
    },
    {
      label: 'Gérants',
      link: {
        name: routesNames.admin.GERANTS,
      },
    },
    {
      label: 'Mailings',
      link: {
        name: routesNames.admin.MAILINGS,
      },
    },
    {
      label: 'Reporting',
      link: {
        name: routesNames.admin.REPORTING.HOME,
      },
    },
  ];
  public navLinksDirecteur: NavLink[] = [
    {
      label: 'Planning',
      link: {
        name: routesNames.directeur.PLANNING,
      },
    },
    {
      label: 'Demandes',
      link: {
        name: routesNames.directeur.DEMANDES.LIST,
      },
    },
    {
      label: 'Inscrits',
      link: {
        name: routesNames.directeur.INSCRITS.LIST,
      },
    },
    {
      label: 'Paiements',
      link: {
        name: routesNames.directeur.PAIEMENTS,
      },
    },
  ];

  public navLinksDirecteurCommunity: NavLink[] = [
    {
      label: 'Tableau de bord',
      link: {
        name: routesNames.directeur.DASHBORD,
      },
    },
    {
      label: 'Descriptif',
      link: {
        name: routesNames.directeur.CRECHEDETAIL,
      },
    },
    {
      label: 'Planning',
      link: {
        name: routesNames.directeur.PLANNING,
      },
    },
    {
      label: 'Demandes',
      link: {
        name: routesNames.directeur.DEMANDES.LIST,
      },
    },
    {
      label: 'Inscrits',
      link: {
        name: routesNames.directeur.INSCRITS.LIST,
      },
    },
    {
      label: 'Paiements',
      link: {
        name: routesNames.directeur.PAIEMENTS,
      },
    },
  ];

  get navLinksFiltered() {
    const links = AuthModule.getters.isAdmin
      ? this.navLinksAdmin
      : this.isCommunityNursery
      ? this.navLinksDirecteurCommunity
      : this.navLinksDirecteur;
    return links.filter((f) => {
      if (f.condition == null) return true;
      else return f.condition;
    });
  }

  checkResponsive() {
    if (this.$refs.nav) {
      if (!this.responsiveMenu) {
        if (this.$refs.nav.scrollWidth > this.$refs.nav.clientWidth || window.innerWidth < 480) {
          this.navWidth = this.$refs.nav.scrollWidth;
          this.responsiveMenu = true;
        }
      } else if (this.$refs.nav.scrollWidth >= this.navWidth) {
        this.responsiveMenu = false;
      }
    }
  }

  mounted() {
    this.checkResponsive();
  }

  created() {
    window.addEventListener('resize', this.checkResponsive);
  }
}
