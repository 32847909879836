import localForage from 'localforage';
export const tokenID = 'access_token';

export namespace JWT {
  export const fetch = async (): Promise<string | null> => {
    return await localForage.getItem(tokenID);
  };

  export const set = async (token: string) => {
    await localForage.setItem(tokenID, token);
  };

  export const clear = async () => {
    return await localForage.removeItem(tokenID);
  };
}
