
















































import { InfiniScrollMixin, RadioTabs } from '@components';
import { reservationStatusConstant, reservationTypeConstant } from '@constants';
import {
  IReservation,
  IReservationConnection,
  IreservationsConnectionArgs,
  IReservationStatus,
} from '@models';
import { differenceInMonths, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    RadioTabs,
  },
})
export default class Reservations extends InfiniScrollMixin<
  IReservationConnection,
  IreservationsConnectionArgs
> {
  @Prop() redirect: string;
  @Prop() handler: (args: IreservationsConnectionArgs) => Promise<IReservationConnection>;
  @Prop() params: IreservationsConnectionArgs;
  @Prop() title: string;

  get columns() {
    return this.status === 'CANCELED'
      ? ['Parent', 'Enfant', 'Réservation', "Date d'annulation", 'Statut', '']
      : ['Parent', 'Enfant', 'Réservation', 'Statut', ''];
  }

  public reservations: IReservation[] = [];

  get listHandler() {
    return this.handler;
  }

  get handlerParams() {
    return this.params;
  }

  get status() {
    return this.params.where.status;
  }

  get statusConstant() {
    return (status: IReservationStatus) => reservationStatusConstant[status];
  }

  get redirectRoute() {
    return (id: string) => ({
      name: this.redirect,
      params: {
        demandeId: id,
      },
      query: {
        type: 'reservation',
      },
    });
  }

  formatDate(date: Date) {
    return format(date, 'DD/MM/YYYY à HH:mm');
  }

  newContent(data: IReservation[]) {
    this.reservations = data;
  }

  @Watch('status')
  paramsChanged(value: IreservationsConnectionArgs) {
    this.newSearch();
  }

  get reservationsFiltered() {
    return this.reservations.map((m) => ({
      ...m,
      parent: m.child.parent,
      documents: [...m.child.parent.documents, ...m.child.documents],
      child: `${m.child.firstName}, ${differenceInMonths(new Date(), m.child.birthDate)} mois`,
      status: m.status,
      reservation: `${format(m.date, 'DD MMM YYYY', { locale: frLocale })} ${
        reservationTypeConstant[m.type]
      }`,
    }));
  }
}
