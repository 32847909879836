



























































































import { Component } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import {
  IPlanningDisplay,
  IAvailability,
  IPlanningBounds,
  IReservation,
  IRegistration,
  IWelcomingExtended,
  IClosingDate,
  ICommunityNurseryAvailability,
} from '@models';
import PlanningItem from './PlanningItem.vue';
import PlanningItemCommunity from './PlanningItemCommunity.vue';
import {
  getMonth,
  getYear,
  setYear,
  setMonth,
  subMonths,
  addMonths,
  getDaysInMonth,
  startOfMonth,
  getDay,
  setDate,
  getDate,
  endOfMonth,
  format,
  isSameDay,
  isBefore,
  startOfYear,
  endOfYear,
  subYears,
  addYears,
} from 'date-fns';
import { range, capitalize } from 'lodash';
import { formatHoursAndMinutes } from '@utils';
import BackLink from './BackLink.vue';
import frLocale from 'date-fns/locale/fr';
import { CalendarMixin } from '../../Mixins';
import { AuthModule, DirectorNurseryModule, SharedModule } from '@store';

@Component({
  components: { PlanningItem, PlanningItemCommunity, BackLink },
})
export default class Planning extends CalendarMixin {
  @Prop() reservations: IReservation[];
  @Prop() registrations: IRegistration[];
  @Prop() availabilities: IAvailability[];
  @Prop() welcomings: IWelcomingExtended[];
  @Prop() closingDates: IClosingDate[];
  @Prop() communityNurseryAvailabilities: ICommunityNurseryAvailability[];
  @Prop() loading: boolean;
  @Prop({ default: 'fullscreen' }) size: 'fullscreen' | 'widget';

  public planningLimit: Date = null;

  get isCommunityNursery() {
    if (AuthModule.getters.isAdmin) return false;
    else return DirectorNurseryModule?.state?.nursery?.communityNursery === true;
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get weekDaysDisplay() {
    if (this.size === 'fullscreen') {
      return this.weekDays;
    } else {
      return ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    }
  }

  get canGoNextMonth() {
    return isBefore(this.currentMonth, this.planningLimit);
  }

  get datesAndReservations() {
    return this.allDisplayDates.reduce<IPlanningDisplay[]>((acc, value) => {
      const reservationsThisDay = this.reservations.filter((resa) => isSameDay(resa.date, value));
      const registrationsThisDay = this.registrations.filter((reg) =>
        isSameDay(reg.reservation.date, value)
      );
      const availabilitiesThisDay = this.availabilities.filter((av) => isSameDay(av.date, value));
      const welcomingsThisDay = this.welcomings.filter((wel) => isSameDay(wel.date, value));
      const communityNurseryAvailabilitiesThisDay =
        this.communityNurseryAvailabilities?.filter((cna) => isSameDay(cna.date, value)) ?? [];
      const item = {
        date: value,
        reservations: reservationsThisDay,
        registrations: registrationsThisDay,
        availabilities: availabilitiesThisDay,
        welcomings: welcomingsThisDay,
        communityNurseryAvailabilities: communityNurseryAvailabilitiesThisDay,
      };
      return [...acc, item];
    }, []);
  }

  get startAndEnd(): IPlanningBounds {
    return {
      start: this.allDisplayDates[0],
      end: this.allDisplayDates[this.allDisplayDates.length - 1],
      saveCommunityData: false,
    };
  }

  async handleSubMonth() {
    const prevMonth = subMonths(this.currentMonth, 1);
    this.selectedMonth = getMonth(prevMonth);
    this.selectedYear = getYear(prevMonth);
    await this.$nextTick();
    this.startAndEnd.saveCommunityData = this.isCommunityNursery;
    this.$emit('render', this.startAndEnd);
  }
  async handleAddMonth() {
    const nextMonth = addMonths(this.currentMonth, 1);
    this.selectedMonth = getMonth(nextMonth);
    this.selectedYear = getYear(nextMonth);
    await this.$nextTick();
    this.startAndEnd.saveCommunityData = this.isCommunityNursery;
    this.$emit('render', this.startAndEnd);
  }

  async mounted() {
    this.$emit('render', this.startAndEnd);
    this.planningLimit = addYears(this.currentMonth, 1);
    await SharedModule.actions.fetchPublicHolidays();
  }
}
