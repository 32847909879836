













import { Component, Vue, Prop } from 'vue-property-decorator';
import { IRegistrationStatus, NavLink } from '@models';
import { Forms } from '@constructors';
import { Tabs } from '@components';
import { routesNames } from '@router';

@Component({
  components: { Tabs },
})
export default class ReportingRoot extends Vue {
  private tabs: NavLink[] = [
    { label: 'Chiffres Clés', link: { name: routesNames.admin.REPORTING.HOME }, exact: true },
    { label: 'Activité Crèche', link: { name: routesNames.admin.REPORTING.ACTIVITY } },
    // { label: 'Analytics', link: { name: routesNames.admin.REPORTING.ANALYTICS } },
  ];
}
