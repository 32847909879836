import { defineModule } from '@/vuex-typed-modules';
import { ICreateBanInput, ICreateBanWhereInput, ICustomerWithRegistration } from '@models';
import { customerQuery } from '@graphql';
import { Alerts, ErrorNotification, SuccessNotification } from '@constructors';
import { createBanMutation } from '@/graphql/Customer/customer.mutations';

interface IDirectorCustomerState {
  customer: ICustomerWithRegistration;
}

//State
const state: IDirectorCustomerState = {
  customer: null,
};

const mutations = {};

const actions = {
  async getOneCustomer(context, customerId: string) {
    const customer = await customerQuery(customerId);
    DirectorCustomersModule.updateState({
      customer,
    });
    return customer;
  },
  async createBan(
    context,
    { form, params }: Alerts.FormPayload<ICreateBanInput, ICreateBanWhereInput>
  ) {
    try {
      await createBanMutation({ where: params, data: form });
      const customer = await DirectorCustomersModule.actions.getOneCustomer(params.customer);
      DirectorCustomersModule.updateState({
        customer,
      });
      new SuccessNotification('Utilisateur bannis de la crèche');
    } catch (e) {
      var banishmentError = new ErrorNotification('Erreur lors du bannissement de la crèche');
    }
  },
};

export const DirectorCustomersModule = defineModule('DirectorCustomersModule', state, {
  mutations,
  actions,
});
