import { sgts } from '@services';
import {
  nurseryListFragment,
  nurseryFragment,
  nurseryPlanningFragment,
  dspNurseryZipCodesFragment,
} from '@fragments';
import {
  InurseriesConnectionArgs,
  InurseryPlanningArgs,
  InurseryKeyFiguresArgs,
  IdspNurseryZipCodesArgs,
} from '@models';

export const nurseriesConnectionQuery = ({ where, page, limit = 20 }: InurseriesConnectionArgs) => {
  return sgts
    .nurseriesConnection()
    .$fragment(nurseryListFragment)
    .$args({
      where: {
        active: true,
        ...where,
      },
      page,
      limit,
    })
    .$fetch();
};

export const getNurseryQuery = (id: string) => {
  return sgts.nursery().$fragment(nurseryFragment).$args({ where: { id } }).$fetch();
};

export const nurseryPlanningQuery = (data: InurseryPlanningArgs) => {
  return sgts.nurseryPlanning().$fragment(nurseryPlanningFragment).$args(data).$fetch();
};

export const nurseryKeyFiguresQuery = (data: InurseryKeyFiguresArgs) => {
  return sgts
    .nurseryKeyFigures()
    .$fragment(
      `
    availabilities
    revenues
    signUps
  `
    )
    .$args(data)
    .$fetch();
};

export const nurseryConfigQuery = (isJobSeeker: boolean = false) => {
  return sgts.nurseryConfig().$fragment('price').$args({ isJobSeeker }).$fetch();
};

export const getDspNurseryZipCodeQuery = (data: IdspNurseryZipCodesArgs) => {
  return sgts.dspNurseryZipCodes().$fragment(dspNurseryZipCodesFragment).$args(data).$fetch();
};
