
























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  Forms,
  UserNotification,
  Alerts,
  ErrorNotification,
  SuccessNotification,
} from '@constructors';
import { RadioTabs, InfiniScrollMixin, FormCalendar } from '@components';
import { routesNames } from '@router';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { isTesting } from '@constants';

import {
  IUser,
  IUserConnectionWhereInput,
  IRole,
  IusersConnectionArgs,
  INursery,
  IUserConnection,
  INurseryEdgeWithRevenues,
} from '@models';
import { sgts } from '@services';
import { UsersModule, AuthModule } from '@store';
import { nurseriesConnectionQuery } from '@graphql';
import { formatDateExport, nurseriesFormater } from '@utils';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { minSize, emailPnb } from '@validators';
import { startOfMonth, endOfMonth } from 'date-fns';

@Component({
  components: { RadioTabs, FormCalendar },
})
export default class GerantsRoot extends InfiniScrollMixin<IUserConnection, IusersConnectionArgs> {
  public module = UsersModule;
  public routesNames = routesNames;

  public loadingExport = false;

  public listHandler = this.module.actions.getUsers;

  get usersList() {
    if (this.filterValue === 'DIRECTOR') {
      return UsersModule.state.users.map((user) => {
        return {
          ...user,
          createdAt: format(user.createdAt, 'DD/MM/YYYY'),
        };
      });
    } else {
      return UsersModule.state.users.map((user) => ({
        ...user,
        name: user.firstName + ' ' + user.lastName,
        createdAt: format(user.createdAt, 'DD/MM/YYYY'),
      }));
    }
  }

  async exportData() {
    this.loadingExport = true;
    const dates = { start: this.calendar.value.start, end: this.calendar.value.end };
    const csvContent = await sgts.exportUsersLogsAndData().$args({ where: dates }).$fetch();
    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Gérants_${formatDateExport()}.csv`);
    link.click();
    link.remove();
    this.loadingExport = false;
  }

  getNew() {
    console.log('calendar');
  }

  get getTitle() {
    if (this.filterValue === 'DIRECTOR') {
      return 'Liste des directeurs / directrices de crèches';
    } else {
      return 'Liste des administrateurs People & Baby';
    }
  }

  get columns() {
    if (this.filterValue === 'DIRECTOR') {
      return ['Mail', 'Ajouté le', 'Crèche(s)', 'Actions', ''];
    } else {
      return ['Nom', 'Mail', 'Ajouté le', 'Actions', ''];
    }
  }

  public filterValue: IRole = 'DIRECTOR';

  get getFilterValue() {
    return this.filterValue;
  }
  set getFilterValue(value: IRole) {
    this.filterValue = value;
    this.newSearch();
  }

  get handlerParamsWithDates() {
    return {
      where: {
        searchQuery: this.search,
        role: this.getFilterValue,
      },
    };
  }

  get handlerParams(): IusersConnectionArgs {
    return {
      where: {
        searchQuery: this.search,

        role: this.getFilterValue,
      },
    };
  }

  createDirector() {
    var alert = new Alerts.FormAlert({
      title: 'Créer un directeur',
      formElement: {
        form: new Forms.Form({
          fields: {
            firstName: new Forms.TextForm({
              label: 'Prénom',
            }),
            lastName: new Forms.TextForm({
              label: 'Nom',
            }),
            email: new Forms.TextForm({
              label: 'Email',
              type: 'email',
            }),
            nurseries: new Forms.SelectMultiple<string[], INurseryEdgeWithRevenues>({
              label: 'Crèches du directeur',
              placeholder: 'Rechercher des crèches',
              handler: nurseriesConnectionQuery,
              formater: nurseriesFormater,
              search: (value) => ({ search: value }),
              errorMessages: {
                minSize: 'Vous devez au moins ajouter une crèche',
              },
            }),
          },
          validations: {
            nurseries: { required, minSize: minSize(1) },
            firstName: { required },
            lastName: { required },
            email: { required, email, ...(isTesting && emailPnb) },
          },
        }),
        submit: {
          handler: UsersModule.actions.createUser,
          params: { role: 'DIRECTOR' as IRole },
          text: 'Créer un directeur',
        },
      },
    });
  }
  editDirector(data) {
    const nurseries = data.nurseries.map((nursery) => ({ node: nursery }));

    var alert = new Alerts.FormAlert({
      title: 'Changement de crèches',
      message: 'Sélectionner une ou des crèche(s) que la directrice pourra administrer ',
      formElement: {
        form: new Forms.Form({
          fields: {
            nurseries: new Forms.SelectMultiple<string[], INurseryEdgeWithRevenues>({
              value: nurseries,
              label: 'Crèches du directeur',
              placeholder: 'Rechercher des crèches',
              handler: nurseriesConnectionQuery,
              formater: nurseriesFormater,
              search: (value) => ({ search: value }),
              errorMessages: {
                minSize: 'Vous devez au moins ajouter une crèche',
              },
            }),
          },
          validations: {
            nurseries: { required, minSize: minSize(1) },
          },
        }),
        submit: {
          handler: UsersModule.actions.updateUser,
          params: { id: data.id },
          text: 'Modifier',
        },
      },
    });
  }
  deleteUser({ email, id: userId }) {
    var alert = new Alerts.ConfirmAlert({
      title: `Supprimer le compte ${email}?`,
      message: 'Cette action est irréversible',
      actions: [
        new Alerts.ConfirmAction({
          text: 'Supprimer',
          handler: () => UsersModule.actions.deleteUser(userId),
        }),
      ],
    });
  }
  requestResetUserPassword({ email, id: userId }) {
    var alert = new Alerts.ConfirmAlert({
      title: `Réinitialiser le mot de passe de ${email}?`,
      message: 'Confirmez-vous l’envoi d’un e-mail de réinitialisation de mot de passe ? ',
      actions: [
        new Alerts.ConfirmAction({
          text: 'Réinitialiser',
          handler: async () => {
            try {
              await AuthModule.actions.requestResetUserPassword(userId);

              new SuccessNotification('Un e-mail a été envoyé');
            } catch (e) {
              var alert = new ErrorNotification(e);
            }
          },
        }),
      ],
    });
  }
  createAdmin() {
    var alert = new Alerts.FormAlert({
      title: 'Créer un administrateur',
      formElement: {
        form: new Forms.Form({
          fields: {
            firstName: new Forms.TextForm({
              label: 'Prénom',
            }),
            lastName: new Forms.TextForm({
              label: 'Nom',
            }),
            email: new Forms.TextForm({
              label: 'Email',
              type: 'email',
            }),
          },
          validations: {
            firstName: { required },
            lastName: { required },
            email: { required, email, ...(isTesting && emailPnb) },
          },
        }),
        submit: {
          handler: UsersModule.actions.createUser,
          params: { role: 'ADMIN' as IRole },
          text: 'Créer un admin',
        },
      },
    });
  }

  public tabs: Forms.IRadioItem<IRole>[] = [
    { value: 'DIRECTOR', text: 'Direction crèche' },
    { value: 'ADMIN', text: 'Administrateurs' },
  ];

  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };

  public calendar = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '260px',
  }).extract();

  get toCrecheList() {
    return { name: routesNames.admin.CRECHES };
  }

  destroyed() {
    this.module.updateState({
      users: [],
    });
  }
}
