







import { Component, Vue, Prop } from 'vue-property-decorator';
import OneDemandeTemplate from '../../../../Shared/Demandes/OneDemandeTemplate.vue';
import { IReservation, IRegistrationStatus, IRegistration } from '@models';

@Component({
  components: { OneDemandeTemplate },
})
export default class CrecheDemandeDetail extends Vue {
  @Prop() demande: IReservation;
  @Prop({ type: null }) registration: IRegistration;
  get title() {
    const { type } = this.$route.query;
    return `Demande ${type === 'reservation' ? 'de réservation' : "d'inscription"}`;
  }
}
