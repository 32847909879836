





















































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  IAvailability,
  IReservation,
  IRegistration,
  IPlanningEvent,
  IWelcomingExtended,
  IClosingDate,
  ISection,
  IAvailabilitySection,
  ICommunityNurseryAvailability,
} from '@models';
import { isSameDay, isSameMonth, getDate, format, isBefore, isAfter } from 'date-fns';
import PlanningSlot from './PlanningSlot.vue';
import { routesNames } from '../../../router';
import { AuthModule, SharedModule } from '../../../store';
import { Location } from 'vue-router';
import FormText from '../Forms/FormText.vue';

@Component({
  components: { PlanningSlot, FormText },
})
export default class PlanningItemCommunity extends Vue {
  @Prop() date: Date;
  @Prop() currentMonth: Date;
  @Prop() reservations: IReservation[];
  @Prop() registrations: IRegistration[];
  @Prop() welcomings: IWelcomingExtended[];
  @Prop() communityNurseryAvailabilities: ICommunityNurseryAvailability[];
  @Prop({ default: 'fullscreen' }) size: 'fullscreen' | 'widget';
  @Prop() closingDates: IClosingDate[];

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get morningAvailabilities() {
    return this.communityNurseryAvailabilities?.filter((av) => av.type === 'MORNING') ?? [];
  }

  get morningAvailabilitiesLabel() {
    const morningAvailabilities = this.morningAvailabilities;
    const remainingPlaces = morningAvailabilities
      .map((x) => x.remainingPlaces)
      .reduce((acc, value) => (acc += value), 0);
    const capacity = morningAvailabilities
      .map((x) => x.capacity)
      .reduce((acc, value) => (acc += value), 0);
    return `(${remainingPlaces}/${capacity})`;
  }

  get afternoonAvailabilities() {
    return this.communityNurseryAvailabilities?.filter((av) => av.type === 'AFTERNOON') ?? [];
  }

  get afternoonAvailabilitiesLabel() {
    const afternoonAvailabilities = this.afternoonAvailabilities;
    const remainingPlaces = afternoonAvailabilities
      .map((x) => x.remainingPlaces)
      .reduce((acc, value) => (acc += value), 0);
    const capacity = afternoonAvailabilities
      .map((x) => x.capacity)
      .reduce((acc, value) => (acc += value), 0);
    return `(${remainingPlaces}/${capacity})`;
  }

  get morningEvents(): IPlanningEvent {
    const reservations = this.reservations.filter((f) => f.type === 'MORNING');
    const registrations = this.registrations.filter((f) => f.reservation.type === 'MORNING');
    return {
      reservations,
      registrations,
    };
  }
  get afternoonEvents(): IPlanningEvent {
    const reservations = this.reservations.filter((f) => f.type === 'AFTERNOON');
    const registrations = this.registrations.filter((f) => f.reservation.type === 'AFTERNOON');
    return {
      reservations,
      registrations,
    };
  }

  get allDayEvents(): IPlanningEvent {
    const reservations = this.reservations.filter((f) => f.type === 'ALL_DAY');
    const registrations = this.registrations.filter((f) => f.reservation.type === 'ALL_DAY');
    return {
      reservations,
      registrations,
    };
  }

  get isClosed(): boolean {
    const publicHolidays = SharedModule.state.publicHolidays;
    return (
      this.closingDates.some(({ id, closingDateStart, closingDateEnd }) => {
        return (
          (isAfter(this.date, closingDateStart) || isSameDay(this.date, closingDateStart)) &&
          isBefore(this.date, closingDateEnd)
        );
      }) || publicHolidays.some((x) => isSameDay(this.date, x))
    );
  }

  get formatHour() {
    return (date: Date) => format(date, 'hh:mm');
  }

  get customerLink(): (id: string) => Location {
    return (id) => {
      const name = this.isAdmin
        ? routesNames.admin.INSCRITS.DETAIL
        : routesNames.directeur.INSCRITS.DETAIL;
      return {
        name,
        params: {
          customerId: id,
        },
      };
    };
  }

  get formattedDate() {
    return getDate(this.date);
  }

  get isToday() {
    return isSameDay(this.date, new Date());
  }
  get isInCurrentMonth() {
    return isSameMonth(this.date, this.currentMonth);
  }

  addCommunityAvailability(data: ICommunityNurseryAvailability) {
    if (data.remainingPlaces < data.capacity) data.remainingPlaces++;
  }

  subtractCommunityAvailability(data: ICommunityNurseryAvailability) {
    if (data.remainingPlaces > 0) data.remainingPlaces--;
  }
  hundelRemainingPlaces(data: ICommunityNurseryAvailability) {
    let value = parseInt(data.remainingPlaces + '');
    if (value > data.capacity) value = data.capacity;
    if (value < 0) value = 0;
    data.remainingPlaces = value;
  }
}
