import { sgts } from '@services';
import { IupdateDocumentArgs, IupdateReservationArgs, IupdateRegistrationArgs } from '@models';
import { documentFragment, reservationFragment, registrationFragment } from '@fragments';

export const updateDocumentMutation = (data: IupdateDocumentArgs) => {
  return sgts.updateDocument().$fragment(documentFragment).$args(data).$post();
};

export const updateReservationMutation = (data: IupdateReservationArgs) => {
  return sgts.updateReservation().$fragment(reservationFragment).$args(data).$post();
};

export const updateRegistrationMutation = (data: IupdateRegistrationArgs) => {
  return sgts.updateRegistration().$fragment(registrationFragment).$args(data).$post();
};
