






























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Modal from '../Display/Modal.vue';
import { IDocument, IDocumentType, IDocumentStatus, ICafDocument } from '@models';
import { documentNamesConstant, documentStatusNamesConstant } from '@constants';
import { getDocumentUrlQuery } from '@graphql';
import { API_URL } from '@services';
import { JWT } from '@store';

@Component({
  components: { Modal },
})
export default class DocumentViewer extends Vue {
  @Prop() show: boolean;
  @Prop() document: IDocument | ICafDocument;

  public documentUrl: string = null;
  public loading = false;
  public error = false;

  public imgWidth: string = null;
  public imgHeight: string = null;

  get documentId() {
    return this.document.id;
  }

  get updatedAt() {
    return this.document.updatedAt;
  }

  get isDocumentImage() {
    return this.document.format === 'IMAGE';
  }

  get isDocumentPdf() {
    return this.document.format === 'PDF';
  }

  get loadingText() {
    if (this.isDocumentImage) {
      return 'Chargement du document';
    } else {
      return 'Chargement du PDF';
    }
  }

  close() {
    this.$emit('update:show', false);
  }

  @Watch('show', { immediate: true })
  async getDocumentUrl(value: boolean) {
    if (!this.documentUrl && value) {
      this.documentUrl = null;
      try {
        this.loading = true;
        this.error = false;
        const url = await getDocumentUrlQuery({ where: { id: this.document.id } });
        const jwt = await JWT.fetch();
        const finalUrl = `${API_URL.replace('/graphql', '')}${url}?token=${jwt}&update=${
          this.updatedAt
        }`;
        if (this.isDocumentPdf) {
          this.documentUrl = finalUrl;
        } else {
          const image = new Image();

          image.onload = () => {
            this.documentUrl = finalUrl;
          };
          image.onerror = (e) => {
            this.error = true;
            console.log(e);
          };
          image.src = finalUrl;
        }
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
}
