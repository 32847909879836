import { AlertsModule } from '@store';
import { Forms } from './Form.constructor';
import { IAnyObject, IReadOnlyObject } from '@models';
import { RawLocation } from 'vue-router';
import shortid from 'shortid';
import Vue from 'vue';
// export type PartialForm<F> = { [K in keyof F]?: F[K] extends Object ? string : F[K] };

let resolveAlert: (...args: any) => unknown = null;
let rejectAlert: (...args: any) => unknown = null;

export namespace Alerts {
  type AlertType = 'success' | 'confirm' | 'warning' | 'error' | 'info' | 'form';
  export interface FormPayload<T = {}, P = {}> {
    form: T;
    params?: P;
  }
  type formParam<T, E = any, P extends IReadOnlyObject = IReadOnlyObject> = {
    form: Forms.Form<T>;
    submit: {
      params?: P;
      handler: (
        data: FormPayload<E extends any ? Forms.FormValues<T> : Forms.EditFormValues<T>, P>
      ) => Promise<any> | void;
      afterTrigger?: () => void;
      text?: string;
    };
  };

  export interface AlertPayload<T, E = any, P extends IReadOnlyObject = IReadOnlyObject> {
    [x: string]: any;
    type?: AlertType;
    title: string;
    description?: string;
    strict?: boolean;
    actions?: Action[];
    formElement?: formParam<T, E, P>;
    onClose?: Array<() => void>;
    width?: string;
  }

  export class Alert<T = {}, E = any, P extends IReadOnlyObject = IReadOnlyObject> {
    public type?: AlertType;
    public title: string;
    public description?: string;
    public strict?: boolean;
    public actions?: Action[];
    public formElement?: formParam<T, E, P>;
    public onClose?: Array<() => void>;
    public width?: string;

    constructor({ actions, ...fields }: AlertPayload<T, E, P>) {
      const filteredActions = actions.filter((f) => !!f);
      this.actions = filteredActions;
      this.description = fields.description;
      this.strict = fields.strict;
      this.title = fields.title;
      this.type = fields.type;
      this.formElement = fields.formElement;
      this.width = fields.width;
      AlertsModule.actions.createAlert(this);
    }

    get getForm() {
      return this.formElement && this.formElement.form;
    }

    set getForm(value: Forms.Form<any>) {
      this.formElement.form = value;
    }

    public wait(): Promise<any> {
      return new Promise((res, rej) => {
        resolveAlert = res;
        rejectAlert = rej;
      });
    }
  }

  export class FormAlert<
    T = {},
    E = any,
    P extends IReadOnlyObject = IReadOnlyObject
  > extends Alert<T, E, P> {
    constructor(fields?: AlertPayload<T, E, P>) {
      let actions = [];
      if (fields.actions) actions = fields.actions.filter((f) => !!f) || [];
      const confirmAction = new ConfirmAction({
        text: fields.formElement.submit.text || 'Valider',
        handler: async () => {
          await fields.formElement.submit.handler({
            form: fields.formElement.form.editMode
              ? (this.getForm.getModifiedData() as any)
              : this.getForm.getValues(),
            params: fields.formElement.submit.params,
          });
          if (fields.formElement.submit.afterTrigger) {
            await fields.formElement.submit.afterTrigger();
          }
        },
      });

      super({
        ...fields,
        title: fields.title || '',
        type: 'form',
        actions: [...actions, confirmAction, new CancelAction({})],
      });
    }
  }

  export class ConfirmAlert<
    T = {},
    E = never,
    P extends IReadOnlyObject = IReadOnlyObject
  > extends Alert<T, E, P> {
    constructor(fields?: AlertPayload<T, E, P>) {
      let actions = [];
      if (fields.actions) actions = fields.actions.filter((f) => !!f) || [];

      super({
        title: fields.title,
        type: 'confirm',
        strict: fields.strict,
        description: fields.description,
        onClose: fields.onClose,
        actions: [...actions, new CancelAction({})],
      });
    }
  }

  type ActionType = 'confirm' | 'cancel' | 'link' | 'action';

  interface ActionPayload {
    type?: ActionType;
    text?: string;
    handler?: (...args: any[]) => any;
    to?: RawLocation;
    chainAlert?: boolean;
  }

  export class Action {
    public id: string;
    public type: ActionType;
    public text?: string;
    public to: RawLocation;
    public handler: (...args: any[]) => any;
    public chainAlert?: boolean;

    constructor({ type = 'action', handler, ...fields }: ActionPayload) {
      this.type = type;
      this.text = fields.text;
      this.to = fields.to;
      this.chainAlert = fields.chainAlert;
      this.id = shortid.generate();
      this.handler = async () => {
        try {
          if (handler && typeof handler === 'function') {
            const response = await handler();
            if (resolveAlert) {
              return resolveAlert(response);
            }
          }
          if (rejectAlert) return rejectAlert(false);
        } catch (e) {
          console.log(e);
          if (rejectAlert) return rejectAlert(e);
          return Promise.reject(e);
        } finally {
          if (!fields.chainAlert) {
            AlertsModule.actions.deleteAlert();
          } else {
            AlertsModule.updateState({ showAlert: false });
            await Vue.nextTick();
            AlertsModule.updateState({ showAlert: true });
          }
        }
      };
    }
  }

  export class ConfirmAction extends Action {
    constructor({ type = 'confirm', text = 'Valider', ...fields }: ActionPayload) {
      super({ type, text, ...fields });
    }
  }

  export class CancelAction extends Action {
    constructor({ type = 'cancel', text = 'Annuler', ...fields }: ActionPayload) {
      super({ type, text, ...fields });
    }
  }

  export class LinkAction extends Action {
    constructor({ type = 'link', ...fields }: ActionPayload) {
      super({ type, ...fields });
    }
  }
}
