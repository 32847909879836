





















































import { Component, Vue } from 'vue-property-decorator';
import { AuthModule, DirectorNurseryModule } from '@store';
import { routesNames, Router } from '@router';
import { FormSelect } from '../Shared/Forms';
import { Forms } from '@constructors';
import NavBar from './NavBar.vue';
import { INurseryWithRevenues } from '@models';
import { nurseriesConnectionQuery } from '@graphql';

@Component({
  components: { NavBar, FormSelect },
})
export default class HeaderComponent extends Vue {
  public selectNursery =
    this.isDirector && this.selectedNursery
      ? new Forms.Select<string, INurseryWithRevenues>({
          placeholder: 'Choisir une crèche',
          value: this.selectedNursery.id,
          displayValue: this.selectedNursery.name,
          width: '200px',
          options: AuthModule.state.userInfos.nurseries.map((m) => ({
            text: m.name,
            value: m.id,
          })),
        }).extract()
      : null;

  get userInfos() {
    return AuthModule.state.userInfos;
  }

  get isDirector() {
    return AuthModule.getters.isDirector;
  }

  get selectedNursery() {
    if (this.isDirector) {
      return DirectorNurseryModule.state.nursery;
    }
    return null;
  }

  get getHomeLink() {
    if (AuthModule.getters.isAdmin) {
      return { name: routesNames.admin.HOME };
    } else {
      return { name: routesNames.directeur.HOME };
    }
  }

  updateSelectedNursery(value: string) {
    if (this.$route.matched.some((route) => route.meta.relatedContent)) {
      this.$router.push({ path: routesNames.directeur.HOME, params: { crecheId: value } });
    } else {
      this.$router.push({ name: this.$route.name, params: { crecheId: value } });
    }
  }

  disconnect() {
    AuthModule.actions.disconnectRequest();
  }
}
