import { sgts } from '@services';
import {
  communityNurseryTemplateAvailabilitiesFragment,
  communityNurseryZipCodesFragment,
} from '@fragments';
import {
  IcommunityNurseryTemplateAvailabilitiesArgs,
  IcommunityNurseryZipCodesArgs,
} from '@models';

export const getCommunityNurseryTemplateQuery = (
  data: IcommunityNurseryTemplateAvailabilitiesArgs
) => {
  return sgts
    .communityNurseryTemplateAvailabilities()
    .$fragment(communityNurseryTemplateAvailabilitiesFragment)
    .$args(data)
    .$fetch();
};

export const getCommunityNurseryZipCodeQuery = (data: IcommunityNurseryZipCodesArgs) => {
  return sgts
    .communityNurseryZipCodes()
    .$fragment(communityNurseryZipCodesFragment)
    .$args(data)
    .$fetch();
};
