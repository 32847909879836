import {
  IAllergy,
  IBanReason,
  IConstant,
  ICustomerGender,
  IDocumentRejectedReason,
  IDocumentStatus,
  IDocumentType,
  IKeyStatistics,
  IMailType,
  INurseryTag,
  IRegistrationStatus,
  IRejectReason,
  IReservationStatus,
  IReservationType,
  ICustomersConnectionFilterEnum,
  IBreastfedType,
  IHygieneType,
  IMotorSkillsType,
  ILangType,
} from '@models';

export * from './stages';

export const keyStatisticConstant: { [X in keyof IKeyStatistics]: string } = {
  newNurseries: 'Nouvelles crèches éligibles',
  nurseriesWithAvailabilities: 'Nombre de crèches avec dispos',
  availabilities: 'Nombre de 1/2 journées dispo',
  registrationAccepted: "Nombre d'inscription validées",
  reservationAccepted: 'Nombre de réservations validées',
  reservationCancelled: 'Nombre de réservations annulées',
  reservationRefused: 'Nombre de réservations refusées',
  revenues: "Chiffre d'affaire",
  revenuesCancelled: "Chiffre d'affaire annulé",
  revenuesPAJE: "Chiffre d'affaire PAJE",
  revenuesPSU: "Chiffre d'affaire PSU",
};

export const reservationTypeConstant: IConstant<IReservationType> = {
  AFTERNOON: 'Après-midi',
  MORNING: 'Matin',
  ALL_DAY: 'Toute la journée',
};

export const genderTypeConstant: IConstant<ICustomerGender> = {
  MALE: 'Monsieur',
  FEMALE: 'Madame',
};

export const nurseryTagConstant: IConstant<INurseryTag> = {
  BIO_ALIMENTATION: 'Alimentation 50% Bio',
  INTERNATIONNAL: 'Crèche internationale',
  GENERATION_DURABLE: 'Jardins et/ou espaces extérieurs',
  ATELIER: 'Graine de génie',
  SUMMER_CAMP: 'Summer camp',
};

export const BanReasonConstant: IConstant<IBanReason> = {
  CHILDREN_BEHAVIOR: "Comportement de l'enfant",
  PARENT_BEHAVIOR: 'Comportement des parents',
  RULES_FAILURE: 'Enfreint au règlement',
  UNPAID: 'Impayé',
};

export const reservationStatusConstant: IConstant<IReservationStatus> = {
  CANCELED: 'Annulée',
  EXPIRED: 'Expirée',
  DONE: 'Traitée',
  REFUSED: 'Rejetée',
  PENDING: 'En attente',
  PENDING_PAYMENT: 'Paiement en attente',
};

export const registrationStatusConstant: IConstant<IRegistrationStatus> = {
  PENDING: 'En attente',
  DONE: 'Traitée',
  REFUSED: 'Refusée',
};

export const documentNamesConstant: IConstant<IDocumentType> = {
  CHILD_HEALTH_RECORD: 'Carnet de santé',
  MEDICAL_CERTIFICATE: 'Certificat médical',
  BIRTH_CERTIFICATE: 'Certificat de naissance',
  PARENT_ID_RECTO: "Carte d'identité des parents (Recto)",
  PARENT_ID_VERSO: "Carte d'identité des parents (Verso)",
  INSURANCE_CERTIFICATE: "Certificat de carte d'assurance",
  CAF_IMMATRICULATION: 'Immatriculation CAF',
  PROOF_OF_ADDRESS: 'Justificatif de domicile (facultatif)',
  JOB_SEEKER_CERTIFICATE: "Attestation demandeur d'emploi",
  PAI_CERTIFICATE: "Attestation de Projet d'accueil individualisé",
};

export const documentStatusNamesConstant: IConstant<IDocumentStatus> = {
  OK: 'OK',
  DECLINED: 'Refusé',
  PENDING: 'En attente',
  EXPIRED: 'Expiré',
};

export const documentRejectedReasonConstant: IConstant<IDocumentRejectedReason> = {
  DOCUMENT_ERROR: 'Erreur sur le document',
  INCOMPLETE: 'Document incomplet',
  MISSING: 'Document manquant',
  OUTDATED: 'Document expiré',
  UNREADABLE: 'Document illisible',
};

export const allergyConstants: IConstant<IAllergy> = {
  EGGS: 'Oeufs',
  FISH: 'Poisson',
  GLUTEN: 'Gluten',
  MILK_AND_EGGS: 'Lait et oeufs',
  SULFUR_DIOXIDE: 'Dioxide de sulfure',
};

export const sleepHoursConstant = {
  0: '00 min',
  1: '30 min',
  2: '01h',
  3: '01h30',
  4: '02h',
  5: '02h30',
  6: '03h',
  7: '03h30',
  8: '04h',
};

export const rejectReasonConstant: IConstant<IRejectReason> = {
  NO_SPACE: 'Plus de place',
  NO_STAFF: 'Pas assez de personnel',
  NURSERY_CLOSED: 'Crèche fermée',
  BANNED_USER: 'Utilisateur banni',
};

export const weekDaysConstant = {
  1: 'Lundi',
  2: 'Mardi',
  3: 'Mercredi',
  4: 'Jeudi',
  5: 'Vendredi',
};

export const emailTypeConstant: IConstant<IMailType> = {
  REGISTRATION: 'Inscription',
  RESERVATION: 'Réservation',
};

export const emailLangConstant: IConstant<ILangType> = {
  FR: 'Version française',
  EN: 'Version anglaise',
};

export const documentStatusEnum: IConstant<ICustomersConnectionFilterEnum> = {
  DOC_MISSING: 'Documents manquants',
  DOC_NOT_COMPLETE: 'Documents incomplets',
  DOC_TO_VALIDATE: 'Docs à valider',
  DOC_VALIDATED: 'Docs validées',
};

export const breastfedType: IConstant<IBreastfedType> = {
  EXCLUSIVE: 'Exclusif',
  PARTIAL: 'Partiel',
  NO: 'Non',
};

export const hygieneType: IConstant<IHygieneType> = {
  YES: 'Oui',
  IN_TRAINING_POTTY: 'Pot (En apprentissage)',
  IN_TRAINING_WC: 'Wc (En apprentissage)',
  NO: 'Non',
};

export const motorSkillsType: IConstant<IMotorSkillsType> = {
  BACK_STOMACH: 'Dos/ventre',
  SITS: 'S’assoit',
  WALKS: 'Marche',
  CRAWLS: 'Rampe',
  UP_DOWN_STAIRS: 'Monte/descend les escaliers',
  NONE: 'Néant',
};
