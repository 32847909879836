





























import { Component, Vue, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';

@Component
export default class SearchField extends Vue {
  @Prop({ default: '' }) value: string;
  @Prop() loading: boolean;
  @Prop({ default: 300 }) debounce: number;
  @Prop({ default: 1 }) offset: number;
  @Prop({ default: 'Recherche...' }) placeholder: string;
  @Prop({ default: 'normal' }) size: 'normal' | 'big';
  @Prop() full: boolean;

  public handleResearch = null;

  get getIconSize() {
    if (this.size === 'normal') {
      return 16;
    } else {
      return 18;
    }
  }

  created() {
    this.handleResearch = debounce((value) => {
      if (value.length >= this.offset) {
        this.$emit('input', value);
      } else if (value.length === 0) {
        this.$emit('input', '');
        this.$emit('clear');
      }
    }, this.debounce);
  }
}
