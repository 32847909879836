import { sgts } from '@services';

export const uploadPictureMutation = (picture: File) => {
  return sgts
    .uploadPicture()
    .$fragment(
      `id
      url
      createdAt
    `
    )
    .$args({ picture })
    .$post();
};

export const deletePictureMutation = (id: string) => {
  return sgts.deletePicture().$args({ id }).$post();
};
