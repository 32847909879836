import { AuthModule } from '@store';
import {
  ApplicationInsights,
  ITelemetryItem,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';

const CLOUD_ROLE_NAME = 'CrechesLib';

class Analytics {
  private appInsights: ApplicationInsights | null = null;

  public load() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHTS,
        disableAjaxTracking: false,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ['*.azurewebsites.net', '*.queue.core.windows.net'],
      },
    });
    const telemetryInitializer = (envelope: ITelemetryItem) => {
      const user = AuthModule.state.userInfos;
      if (envelope.data && user && user.id) {
        envelope.data.userEmail = user.email ? user.email : 'unknown';
        envelope.data.userId = user.id ? user.id : 'unknown';
        envelope.data.role = user.role ? user.role : 'unknown';
      }
      if (envelope.tags) {
        envelope.tags['ai.cloud.role'] = CLOUD_ROLE_NAME;
      } else {
        // eslint-disable-next-line no-throw-literal
        throw '[App Insight] envelope.tags not found';
      }
    };

    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  startTrackEvent(event: string) {
    if (this.appInsights) {
      this.appInsights.startTrackEvent(event);
    }
  }

  stopTrackEvent(
    event: string,
    properties?: {
      [key: string]: string;
    },
    measurements?: {
      [key: string]: number;
    }
  ) {
    if (this.appInsights) {
      this.appInsights.stopTrackEvent(event, properties, measurements);
    }
  }

  startTrackPage(path: string) {
    if (this.appInsights) {
      this.appInsights.startTrackPage(path);
    }
  }

  stopTrackPage(path: string) {
    if (this.appInsights) {
      this.appInsights.stopTrackPage(path);
    }
  }

  traceTelemetry(msg, properties) {
    this.appInsights.trackTrace(
      { message: `[ADMIN]${msg}`, severityLevel: SeverityLevel.Information },
      properties
    );
  }

  exceptionTelemetry(exception, properties) {
    this.appInsights.trackException({ id: '[ADMIN]', exception }, properties);
  }
}

export const analytics = new Analytics();
