
























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IConnection } from '@models';
import Observer from './Observer.vue';
import LiquidGrid from './LiquidGrid.vue';

@Component({
  components: { Observer, LiquidGrid },
})
export default class InfiniScroll extends Vue {
  public defaultPage = 1;
  public defaultHasNextPage = true;
  public defaultHasPreviousPage = false;
  public defaultLoading = false;
  public lazyFinished = false;
  public ready = false;
  public fetchError = false;
  public totalPage: number = null;
  public pagination: number = null;

  @Prop() handler: (...args: any[]) => Promise<IConnection<any>>;
  @Prop() handlerParams: { [x: string]: any };
  @Prop({ default: 'scroll' }) type: 'scroll' | 'button' | 'pages';
  @Prop({ default: 40 }) spinnerSize: number;
  @Prop({ default: true }) firstFetch: boolean;
  @Prop() page: number;
  @Prop({ default: null }) hasNextPage: boolean;
  @Prop({ default: null }) hasPreviousPage: boolean;
  @Prop({ default: null }) loading: boolean;
  @Prop({ default: false }) grid: boolean;

  $refs!: {
    scrollRoot: HTMLElement;
    observer: Observer;
  };

  get usePage(): number {
    this.pagination = this.page || this.defaultPage;
    return this.page || this.defaultPage;
  }

  get usePagination() {
    return this.pagination;
  }
  set usePagination(value) {
    if (value <= this.totalPage && !isNaN(value)) {
      this.pagination = value;
    } else {
      this.pagination = this.totalPage;
    }
  }

  setPage() {
    if (this.pagination <= this.totalPage && !isNaN(this.pagination)) {
      this.getMore(this.pagination - this.usePage);
    }
  }

  get useHasNextPage(): boolean {
    return this.hasNextPage != null ? this.hasNextPage : this.defaultHasNextPage;
  }
  get useHasPreviousPage(): boolean {
    return this.hasPreviousPage != null ? this.hasPreviousPage : this.defaultHasPreviousPage;
  }

  get useLoading() {
    return this.loading != null ? this.loading : this.defaultLoading;
  }

  async getMore(count: number = 0) {
    try {
      this.fetchError = false;
      if (this.loading != null) this.$emit('update:loading', true);
      else this.defaultLoading = true;
      this.lazyFinished = false;
      const { edges, pageInfo } = await this.handler({
        ...this.handlerParams,
        page: this.usePage + count,
      });
      this.totalPage = pageInfo?.totalPage;
      if (this.page != null) this.$emit('update:page', this.page + count);
      else this.defaultPage += count;
      this.ready = true;
      this.$emit(
        'newContent',
        edges.map((e) => e.node)
      );
      if (this.hasNextPage != null) this.$emit('update:hasNextPage', pageInfo?.hasNextPage);
      else this.defaultHasNextPage = pageInfo?.hasNextPage;
      if (this.hasPreviousPage != null)
        this.$emit('update:hasPreviousPage', pageInfo?.hasPreviousPage);
      else this.defaultHasPreviousPage = pageInfo?.hasPreviousPage;
    } catch (e) {
      this.fetchError = true;
      if (this.hasNextPage != null) this.$emit('update:hasNextPage', false);
      else this.defaultHasNextPage = false;
    } finally {
      if (this.loading != null) this.$emit('update:loading', false);
      else this.defaultLoading = false;
      this.lazyFinished = true;
    }
  }

  mounted() {
    this.$refs.scrollRoot.addEventListener('touchstart', () => {}, { passive: true });
    this.$refs.scrollRoot.addEventListener('touchmove', () => {}, { passive: true });
    this.$refs.scrollRoot.addEventListener('scroll', () => {}, { passive: true });
  }

  created() {
    if (this.type === 'pages') this.pagination = this.usePage;
    if (this.firstFetch) this.getMore();
    else {
      this.ready = true;
    }
  }
}
