

























































import { Component, Vue } from 'vue-property-decorator';
import { NotificationsModule } from '@store';
import { INotification } from '@models';

@Component({})
export default class Notifications extends Vue {
  public deleteNotification = NotificationsModule.mutations.deleteNotification;

  get notificationList() {
    return NotificationsModule.state.notificationList;
  }

  get isLink() {
    return (notif: INotification) => {
      if (notif.link) {
        return 'router-link';
      }
      return 'li';
    };
  }
}
