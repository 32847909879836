













import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICustomer, IChild } from '@models';
import { AuthModule } from '../../../store';
import { routesNames } from '../../../router';
import { Location } from 'vue-router';
import { differenceInMonths } from 'date-fns';

@Component({})
export default class Customer extends Vue {
  @Prop() child: IChild;

  get avatar() {
    return {
      backgroundImage: `url(${
        this.child.picture ? this.child.picture.url : require('@images/user.jpg')
      })`,
    };
  }

  get childAge() {
    return differenceInMonths(new Date(), this.child.birthDate);
  }
}
