




































import { Component, Vue } from 'vue-property-decorator';
import { FormContainer, InfoMessage } from '@components';
import { required, email } from 'vuelidate/lib/validators';
import { AuthModule, ProgressBarModule } from '@store';
import { Forms } from '@constructors';
import { routesNames } from '@router';

@Component({
  components: { FormContainer, InfoMessage },
})
export default class Connexion extends Vue {
  public submitting = false;
  public formError = false;
  public formErrorMessage = null;

  public connexionForm = new Forms.Form({
    editMode: true,
    fields: {
      email: new Forms.TextForm({
        placeholder: 'Email',
        error: false,
        value: '',
        icon: 'icons/Forms/email.svg',
      }),
      password: new Forms.TextForm({
        type: 'password',
        value: '',
        placeholder: 'Mot de passe',
        error: false,
        icon: 'icons/Forms/password.svg',
      }),
    },
    validations: {
      email: { required, email },
      password: { required },
    },
  });

  get appName() {
    return process.env.NUXT_ENV_APP_NAME;
  }
  redirect() {
    const email = this.connexionForm.getValues().email;
    this.$router.push({
      path: routesNames.FORGOT_PASSWORD,
      query: { email: email },
    });
  }
  async submitForm() {
    const form = this.connexionForm.getValues();
    this.formError = false;
    this.formErrorMessage = null;

    try {
      this.submitting = true;
      const nextRoute = this.$route.query.nextRoute as string;
      await AuthModule.actions.connexionRequest({ ...form, nextRoute });
    } catch (e) {
      console.log(e);
      this.formError = true;
      this.formErrorMessage = e;
    } finally {
      this.submitting = false;
    }
  }
}
// admin : jawad@totem.paris Testtest1
// directeur :
