








import { Component, Vue, Prop } from 'vue-property-decorator';
import DemandesTemplate from '../../Shared/Demandes/DemandesTemplate.vue';
import { Forms } from '@constructors';
import { routesNames } from '@router';
import {
  NurseryModule,
  DirectorNurseryModule,
  getNurseryReservations,
  getNurseryRegistrations,
} from '@store';

@Component({
  components: {
    DemandesTemplate,
  },
})
export default class CrechesDemandes extends Vue {
  public listHandlers = {
    reservations: getNurseryReservations,
    registrations: getNurseryRegistrations,
  };
  public redirect = routesNames.directeur.DEMANDES.DETAIL;

  get title() {
    return `Demandes de ${this.nursery.name}`;
  }

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }
}
