















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { routesNames, Router } from '@router';
import { AuthModule, NurseryModule, NotificationsModule, updateDocument } from '@store';
import {
  BackLink,
  DateDisplay,
  Customer,
  DocumentWithAction,
  FormContainer,
  MissingDocument,
} from '@components';
import {
  Forms,
  Alerts,
  WarningNotification,
  ErrorNotification,
  SuccessNotification,
} from '@constructors';
import {
  required,
  decimal,
  maxLength,
  maxValue,
  minValue,
  numeric,
} from 'vuelidate/lib/validators';
import {
  IReservation,
  IDocument,
  IWhereUniqueInput,
  IDocumentStatusUpdateDataInput,
  IDocumentStatus,
  IRegistration,
  IChild,
  IReservationStatus,
  IReservationUpdateInput,
  IReservationWhereUniqueInput,
  IDocumentType,
  IEffortRate,
} from '@models';
import { oc } from 'ts-optchain';
import { differenceInMonths } from 'date-fns';
import { constantToSelectFormater, calculatePsuPrice } from '@utils';
import { documentRejectedReasonConstant, rejectReasonConstant } from '@constants';
import {
  updateCustomerMutation,
  updateReservationMutation,
  updateRegistrationMutation,
  nurseryConfigQuery,
  getAllEffortRatesQuery,
} from '@graphql';
import AcceptReservation from './AcceptReservation.vue';
import { positiveNumber } from '../../../../utils/validators';

@Component({
  components: {
    BackLink,
    DateDisplay,
    Customer,
    AcceptReservation,
    DocumentWithAction,
    FormContainer,
    MissingDocument,
  },
})
export default class OneDemandeTemplate extends Vue {
  @Prop() demande: IReservation;
  @Prop({ type: null }) registration: IRegistration;

  // @ts-ignore
  public cachedDemande = this.demande;
  // @ts-ignore
  public cachedRegistration = this.registration;
  public nurseryConfig: number = null;
  public effortRates: IEffortRate[] = [];

  public psuCalculateForm = new Forms.Form({
    fields: {
      children: new Forms.TextForm<number>({
        type: 'number',
        valueType: 'number',
        value: 1,
        label: "Nombre d'enfants à charge",
        halfWidth: true,
      }),
      salary: new Forms.TextForm<number>({
        label: 'Ressources annuelles (CAF)',
        valueType: 'number',
        type: 'number',
        placeholder: 'en €',
        halfWidth: true,
      }),
    },
    validations: {
      children: { required, maxValue: maxValue(10), minValue: minValue(1), numeric },
      salary: { required, maxValue: maxValue(1000000), minValue: minValue(0), numeric },
    },
  });

  pricePerHourForm = new Forms.Form({
    fields: {
      price: new Forms.TextForm<string>({
        label: 'Prix par heure en €',
        valueType: 'price',
        width: '200px',
        disabled: true,
      }),
    },
    validations: {
      price: { required, decimal, positiveNumber },
    },
  });

  public showConfirmReservation = false;

  public listDocument: IDocumentType[] = [
    'PARENT_ID_RECTO',
    'PARENT_ID_VERSO',
    'CAF_IMMATRICULATION',
    'CHILD_HEALTH_RECORD',
    'BIRTH_CERTIFICATE',
    'INSURANCE_CERTIFICATE',
    'MEDICAL_CERTIFICATE',
    'PROOF_OF_ADDRESS',
    'JOB_SEEKER_CERTIFICATE',
  ];

  get isCommunityNursery() {
    return this.nursery.communityNursery === true;
  }

  get isDspNursery() {
    return this.nursery.dspNursery === true;
  }

  get isJobSeeker() {
    return (
      this.customer.isJobSeeker &&
      this.documents.some((x) => x.type === 'JOB_SEEKER_CERTIFICATE' && x.status === 'OK')
    );
  }

  get isResident() {
    return this.customer.isResident;
  }

  get missingDocuments() {
    return this.listDocument
      .filter((x) => x !== 'PROOF_OF_ADDRESS' || this.isCommunityNursery || this.isDspNursery)
      .filter((x) => x !== 'JOB_SEEKER_CERTIFICATE' || this.isJobSeeker)
      .filter((x) => x !== 'CAF_IMMATRICULATION' || this.isResident)
      .filter((f) => !this.documents.find((doc) => doc.type === f));
  }

  get isResa() {
    return this.$route.query.type === 'reservation';
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get ResaType() {
    if (this.isResa) return this.cachedDemande.type;
    return this.registration.reservation?.type;
  }

  get getMessage() {
    if (this.isDemandeDone) {
      if (this.isAdmin) {
        return `La demande est validée !`;
      } else {
        return "La demande est validée par l'administrateur";
      }
    } else {
      if (this.isDemandePending) {
        if (!this.isAdmin) {
          return "Validation en cours par l'administrateur";
        }
        if (this.isDocValid) {
          return `Les documents sont valides et la date demandée disponible la demande sera validée. Le demandeur sera automatiquement avertis de l’état de la demande, et la reservation ajouté a votre planning.`;
        } else {
          return `Tous les documents ne sont pas encore valides. Il faut valider tous les documents présents dans la demande pour valider la demande`;
        }
      } else if (this.isDemandeRefused) {
        if (!this.isAdmin && this.cachedDemande.refusedBy === 'ADMIN') {
          return "La demande est refusée par l'administrateur";
        } else if (this.cachedDemande.refusedBy === 'DIRECTOR' && this.isAdmin) {
          return `La demande a été refusée par la directrice`;
        } else {
          return 'La demande a été refusée';
        }
      } else if (this.isDemandeCanceled) {
        return `La demande a été annulée`;
      } else {
        return `La demande est expirée`;
      }
    }
  }

  get isDocValid() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .every((m) => m.status === 'OK');
  }
  get isDocToValid() {
    return (
      !!this.missingDocuments.length ||
      this.documents
        .filter(
          (x) =>
            x.required ||
            (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
            (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
        )
        .some((m) => ['PENDING', 'DECLINED', 'EXPIRED'].includes(m.status))
    );
  }

  get isDemandeDone() {
    if (this.isResa) {
      return this.cachedDemande.status === 'DONE';
    } else {
      return this.cachedRegistration.status === 'DONE';
    }
  }

  get isDemandeCanceled() {
    if (this.isResa) {
      return this.cachedDemande.status === 'CANCELED';
    }
    return null;
  }

  get isDemandeExpired() {
    if (this.isResa) {
      return this.cachedDemande.status === 'EXPIRED';
    }
    return null;
  }

  get isDemandeRefused() {
    if (this.isResa) {
      return this.cachedDemande.status === 'REFUSED';
    } else {
      return this.cachedRegistration.status === 'REFUSED';
    }
  }

  get isDemandePending() {
    if (this.isResa) {
      return this.cachedDemande.status === 'PENDING';
    } else {
      return this.cachedRegistration.status === 'PENDING';
    }
  }

  public priceForm: Forms.Form<any> = null;

  @Watch('priceForm.fieldsValues.price')
  valueChanged(price, oldVal) {
    let value: number = 0;
    if (typeof price == 'string') value = +price.replace(',', '.');
    else value = price;
    if (value) {
      this.pricePerHourForm.fieldsValues.price = Number((+value / 6).toFixed(2)).toFixed(2);
    }
  }

  get isNurseryPaje() {
    return this.nursery.type === 'PAJE';
  }

  get nursery() {
    if (this.isResa) {
      return this.demande.nursery;
    } else {
      return this.registration.nursery;
    }
  }

  get date(): Date {
    if (this.isResa) {
      return this.cachedDemande.date;
    } else {
      if (this.registration.reservation) {
        return this.registration.reservation.date;
      } else {
        return null;
      }
    }
  }

  get welcomingDate(): Date {
    if (this.isResa) return null;
    return oc(this.registration.welcoming).date();
  }

  get child(): IChild {
    if (this.isResa) {
      return oc(this.cachedDemande).child();
    } else {
      return this.registration.child;
    }
  }

  get documents(): IDocument[] {
    return [...this.customer.documents, ...this.child.documents];
  }

  get childAge() {
    return differenceInMonths(new Date(), this.child.birthDate);
  }

  get getPrice(): number {
    if (this.isNurseryPaje) {
      return this.nurseryConfig;
    } else {
      if (this.isResa) {
        if (this.cachedDemande.type === 'ALL_DAY') {
          return this.cachedDemande.price / 2;
        } else {
          return this.cachedDemande.price;
        }
      } else {
        return this.customer.price;
      }
    }
  }

  get customer() {
    if (this.isResa) {
      return oc(this.cachedDemande.child).parent();
    } else {
      return this.registration.child.parent;
    }
  }

  get backLink() {
    if (AuthModule.getters.isAdmin) {
      const selectedNursery = NurseryModule.state.oneNursery;
      return {
        name: routesNames.admin.CRECHEDETAIL.DEMANDES.LIST,
        params: { id: selectedNursery.id },
      };
    } else {
      return { name: routesNames.directeur.DEMANDES.LIST };
    }
  }

  warningPaje() {
    var warning = new WarningNotification(
      'Le prix de la crèche est défini par la tarification conventionnelle'
    );
  }

  get isNotResident() {
    return !this.customer.isResident;
  }

  calculatePrice() {
    this.priceForm.fieldsValues.price = calculatePsuPrice(
      this.psuCalculateForm.getValues().salary,
      this.psuCalculateForm.getValues().children,
      this.isNotResident,
      this.effortRates
    );
  }

  updatePrice() {
    NotificationsModule.actions.addLoader({
      message: 'Modification du prix',
      successMessage: 'Prix modifié!',
      errorMessage: 'Erreur lors de la modification du prix',
      handler: async () => {
        const price = Number(this.priceForm.getValues().price);
        try {
          if (this.isResa) {
            const reservation = await updateReservationMutation({
              where: {
                id: this.demande.id,
              },
              data: {
                price,
              },
            });
            this.cachedDemande.price = reservation.price;
          } else {
            const customer = await updateCustomerMutation({
              where: {
                id: this.customer.id,
              },
              data: {
                price,
              },
            });
            if (this.isResa) {
              this.cachedDemande.child.parent.price = customer.price;
            } else {
              this.cachedRegistration.child.parent.price = customer.price;
            }
          }
        } catch (e) {
          console.log(e);
          return new Error(e);
        } finally {
          this.priceForm.reset();
        }
      },
    });
  }

  async handleUpdateDocument({
    form,
    params,
  }: Alerts.FormPayload<IDocumentStatusUpdateDataInput, IWhereUniqueInput>) {
    try {
      const document = await updateDocument({
        where: { id: params.id },
        data: {
          ...form,
          ...(this.isResa
            ? { reservation: { id: this.demande.id } }
            : { registration: { id: this.registration.id } }),
        },
      });
      if (document != null) {
        if (this.isResa) {
          let document2 =
            this.cachedDemande.child.parent.documents.find((f) => f.id === params.id) ||
            this.cachedDemande.child.documents.find((f) => f.id === params.id);
          document2.status = document.status;
          document2.updatedAt = document.updatedAt;
        } else {
          let document2 =
            this.cachedRegistration.child.parent.documents.find((f) => f.id === params.id) ||
            this.cachedRegistration.child.documents.find((f) => f.id === params.id);
          document2.status = document.status;
          document2.updatedAt = document.updatedAt;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleValidate(id: string) {
    var alert = new Alerts.ConfirmAlert({
      title: 'Valider le document?',
      actions: [
        new Alerts.ConfirmAction({
          text: 'Valider',
          handler: () =>
            this.handleUpdateDocument({
              form: {
                status: 'OK' as IDocumentStatus,
              },
              params: { id },
            }),
        }),
      ],
    });
  }

  handleDecline(id: string) {
    var alert = new Alerts.FormAlert({
      title: `Décliner le document`,
      formElement: {
        form: new Forms.Form({
          fields: {
            reason: new Forms.Select({
              label: 'Raison',
              options: constantToSelectFormater(documentRejectedReasonConstant),
            }),
            comment: new Forms.FieldForm({
              label: 'Description de la raison',
            }),
            status: 'DECLINED' as IDocumentStatus,
          },
          validations: {
            reason: { required },
            comment: { maxLength: maxLength(140) },
          },
        }),
        submit: {
          params: { id },
          handler: this.handleUpdateDocument,
        },
      },
    });
  }

  async acceptDemande(id: string) {
    var alert = new Alerts.ConfirmAlert({
      title: `Valider la demande ${this.isResa ? 'de réservation' : "d'inscription"}?`,
      actions: [
        new Alerts.ConfirmAction({
          text: 'Valider',
          handler: async () => {
            try {
              if (this.isResa) {
                const resa = await updateReservationMutation({
                  where: { id },
                  data: { status: 'DONE' },
                });
                this.cachedDemande.status = resa.status;
              } else {
                const registration = await updateRegistrationMutation({
                  where: { id },
                  data: { status: 'DONE' },
                });
                this.cachedRegistration.status = registration.status;
              }
              new SuccessNotification(
                `Demande ${this.isResa ? 'de réservation' : "d'inscription"} validée!`
              );

              if (!this.isResa && oc(this.registration).reservation.status() === 'PENDING') {
                this.showConfirmReservation = true;
              } else {
                if (this.isAdmin) this.$router.go(-1);
                else Router.replace({ name: routesNames.directeur.DEMANDES.LIST });
              }
            } catch (e) {
              console.log(e);
              var error = new ErrorNotification('Erreur lors de la validation de la demande');
            }
          },
        }),
      ],
    });
  }

  refuseDemande() {
    var alert = new Alerts.FormAlert({
      title: `Refuser la demande de réservation?`,
      formElement: {
        form: new Forms.Form({
          fields: {
            statusReason: new Forms.Select({
              label: 'Raison',
              options: constantToSelectFormater(rejectReasonConstant),
            }),
            reasonComment: new Forms.FieldForm({
              label: 'Description',
            }),
            status: 'REFUSED' as IReservationStatus,
          },
          validations: {
            statusReason: { required },
            reasonComment: { maxLength: maxLength(140) },
          },
        }),
        submit: {
          params: {
            id: this.demande.id,
          },
          handler: async ({
            form,
            params,
          }: Alerts.FormPayload<IReservationUpdateInput, IReservationWhereUniqueInput>) => {
            try {
              const reservation = await updateReservationMutation({
                where: params,
                data: form,
                rejectReasonConstant: rejectReasonConstant[form.statusReason],
              });
              this.cachedDemande = reservation;
              const name = this.isAdmin
                ? routesNames.admin.CRECHEDETAIL.DEMANDES.LIST
                : routesNames.directeur.DEMANDES.LIST;
              Router.replace({ name });
            } catch (e) {
              var error = new ErrorNotification('Échec lors du refus de la réservation');
            }
          },
        },
      },
    });
  }

  async generateForm() {
    if (this.isNurseryPaje) {
      const config = await nurseryConfigQuery(this.isJobSeeker);
      this.nurseryConfig = config.price;
    }
    this.priceForm = new Forms.Form({
      fields: {
        price: new Forms.TextForm({
          label: 'Prix en €',
          valueType: 'price',
          value: () =>
            this.getPrice ? Number(this.getPrice).toFixed(2).toString().replace('.', ',') : null,
          disabled: this.isNurseryPaje || !this.isAdmin,
          editMode: () => !!this.getPrice,
          width: '200px',
        }),
      },
      validations: {
        price: { required, decimal, positiveNumber },
      },
    });
  }

  async mounted() {
    this.generateForm();
    this.effortRates = await getAllEffortRatesQuery({ where: { id: '' } });
  }
}
