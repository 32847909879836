




import { Component, Vue, Prop } from 'vue-property-decorator';
import OneInscritTemplate from '../../Shared/OneInscritTemplate.vue';
import { DirectorCustomersModule } from '@store';

@Component({
  components: { OneInscritTemplate },
})
export default class OneInscritDirecteur extends Vue {
  get inscrit() {
    return DirectorCustomersModule.state.customer;
  }
}
