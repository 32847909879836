import { sgts } from '@services';
import {
  IupdateCommunityNurseryAvailabilitiesArgs,
  IupdateCommunityNurseryTemplateAvailabilitiesArgs,
  IupdateCommunityNurseryZipCodesArgs,
} from '@models';

export const updateCommunityNurseryAvailabilitiesMutation = async (
  data: IupdateCommunityNurseryAvailabilitiesArgs
) => {
  await sgts.updateCommunityNurseryAvailabilities().$args(data).$post();
};
export const updateCommunityNurseryTemplateAvailabilitiesMutation = async (
  data: IupdateCommunityNurseryTemplateAvailabilitiesArgs
) => {
  await sgts.updateCommunityNurseryTemplateAvailabilities().$args(data).$post();
};

export const updateCommunityNurseryZipCodesMutation = async (
  data: IupdateCommunityNurseryZipCodesArgs
) => {
  await sgts.updateCommunityNurseryZipCodes().$args(data).$post();
};
