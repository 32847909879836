import { IcreateBanArgs, IbanCustomerArgs, IdeleteBanArgs, IupdateCustomerArgs } from '@models';
import { sgts } from '@services';
import { bannedCustomerFragment, fullCustomerFragment, banFragment } from '@fragments';

export const createBanMutation = (data: IcreateBanArgs) => {
  return sgts.createBan().$fragment(banFragment).$args(data).$post();
};

export const updateCustomerMutation = (data: IupdateCustomerArgs) => {
  return sgts.updateCustomer().$fragment(fullCustomerFragment).$args(data).$post();
};

export const deleteBanMutation = (data: IdeleteBanArgs) => {
  return sgts.deleteBan().$args(data).$post();
};

export const banCustomerMutation = (data: IbanCustomerArgs) => {
  return sgts.banCustomer().$fragment('id').$args(data).$post();
};
