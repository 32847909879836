import { defineModule } from '@/vuex-typed-modules';
import { IUser, IusersConnectionArgs, ICreateUserInput, IUpdateUserInput, IRole } from '@models';
import {
  createUserMutation,
  updateUserMutation,
  usersConnectionQuery,
  deleteUserMutation,
} from '@graphql';
import { ErrorNotification, SuccessNotification, Alerts } from '@constructors';
import { sgts } from '@services';
import { userFragment } from '@fragments';

interface IUsersState {
  users: IUser[];
}

//State
const state: IUsersState = {
  users: [],
};

const mutations = {};

const actions = {
  async getUsers(context, payload: IusersConnectionArgs) {
    usersConnectionQuery.$abort();
    const { edges, pageInfo, aggregate } = await usersConnectionQuery.$args(payload).$fetch();
    UsersModule.updateState({
      users: edges.map((e) => e.node) || [],
    });
    return { edges, pageInfo, aggregate };
  },
  async createUser(
    context,
    { form, params }: Alerts.FormPayload<Omit<ICreateUserInput, 'role'>, { role: IRole }>
  ) {
    try {
      const user = await createUserMutation({ ...form, ...params });
      UsersModule.addListItem('users', user);
      new SuccessNotification(
        "Un email a été envoyé au directeur afin qu'il puisse définir son mot de passe"
      );
    } catch ({ message }) {
      console.log(message);
      if (message === 'GraphQL error: input-user-exists') {
        var alreadyExists = new ErrorNotification('Il existe déjà un user avec cet email');
      } else {
        var errorCreating = new ErrorNotification('Erreur lors de la création du directeur');
      }
      return Promise.reject(message);
    }
  },
  async updateUser(
    context,
    { form, params }: Alerts.FormPayload<IUpdateUserInput, { id: string }>
  ) {
    const user = await updateUserMutation({ ...form, ...params });
    UsersModule.updateListItem('users', user.id, user);
  },

  async deleteUser(context, userId: string): Promise<void> {
    try {
      await deleteUserMutation(userId);
      UsersModule.removeListItem('users', userId);
      new SuccessNotification('Compte supprimé avec succès');
    } catch (e) {
      var errorDeleting = new ErrorNotification('Erreur lors de la suppression du compte');
      return Promise.reject(e);
    }
  },
};

export const UsersModule = defineModule('UsersModule', state, {
  mutations,
  actions,
});
