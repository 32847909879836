

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICustomer, IDocument, IDocumentType } from '@models';
import Colors from '@colors';
import { randomNumber } from '@methods';
import { AuthModule, DirectorNurseryModule, NurseryModule } from '@store';
import { routesNames } from '@router';

@Component({})
export default class ProfileWithDoc extends Vue {
  @Prop() customer: ICustomer;
  @Prop() documents: IDocument[];
  @Prop() to;

  get avatar() {
    return {
      backgroundImage: `url(${this.customer.picture?.url || require('@images/user.jpg')})`,
    };
  }

  get isJobSeeker() {
    return this.customer.isJobSeeker;
  }

  get isResident() {
    return this.customer.isResident;
  }

  public listDocument: IDocumentType[] = [
    'PARENT_ID_RECTO',
    'PARENT_ID_VERSO',
    'CAF_IMMATRICULATION',
    'CHILD_HEALTH_RECORD',
    'BIRTH_CERTIFICATE',
    'INSURANCE_CERTIFICATE',
    'MEDICAL_CERTIFICATE',
    'PROOF_OF_ADDRESS',
    'JOB_SEEKER_CERTIFICATE',
  ];

  get isCommunityNursery() {
    return this.nursery?.communityNursery === true;
  }

  get isDspNursery() {
    return this.nursery?.dspNursery === true;
  }

  get nursery() {
    return this.isAdmin ? NurseryModule.state.oneNursery : DirectorNurseryModule.state.nursery;
  }

  get missingDocuments() {
    return this.listDocument
      .filter((x) => x !== 'PROOF_OF_ADDRESS' || this.isCommunityNursery || this.isDspNursery)
      .filter((x) => x !== 'JOB_SEEKER_CERTIFICATE' || this.isJobSeeker)
      .filter((x) => x !== 'CAF_IMMATRICULATION' || this.isResident)
      .filter(
        (f) =>
          !this.documents
            .filter(
              (x) =>
                x.required ||
                (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
                (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
            )
            .find((doc) => doc.type === f)
      );
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get redirect() {
    if (this.to) return this.to;
    else {
      if (this.isAdmin) {
        return {
          name: routesNames.admin.INSCRITS.DETAIL,
          params: {
            customerId: this.customer.id,
          },
        };
      } else {
        return {
          name: routesNames.directeur.INSCRITS.DETAIL,
          params: {
            customerId: this.customer.id,
          },
        };
      }
    }
  }

  get name() {
    return this.customer.firstName + ' ' + this.customer.lastName;
  }

  get color() {
    if (this.isDocValid) return Colors.green;
    if (this.isDocInvalid) return Colors.red1;
    else return Colors.grey;
  }

  get text() {
    if (this.isDocValid) return 'Docs validés';
    else if (this.isDocInvalid) return 'Docs non validés';
    else if (this.isDocIncomplete) return 'Docs incomplets';
    else if (this.isDocExpired) return 'Docs invalides';
    else return 'Docs à valider';
  }

  get isDocValid() {
    return (
      this.documents
        .filter(
          (x) =>
            x.required ||
            (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
            (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
        )
        .every((m) => m.status === 'OK') && !this.isDocIncomplete
    );
  }
  get isDocInvalid() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .some((m) => m.status === 'DECLINED');
  }
  get isDocNotValid() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .some((m) => m.status in ['PENDING', 'DECLINED', 'EXPIRED']);
  }
  get isDocToValid() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .some((m) => m.status in ['PENDING']);
  }
  get isDocIncomplete() {
    return this.missingDocuments.length > 0;
  }
  get isDocExpired() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .some((m) => m.status in ['EXPIRED']);
  }
}
