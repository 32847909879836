import { defineModule } from '@/vuex-typed-modules';
import {
  INursery,
  INurseryWithRevenues,
  IupdateNurseryArgs,
  InurseriesConnectionArgs,
  INurseryConfigInput,
} from '@models';
import {
  nurseriesConnectionQuery,
  getNurseryQuery,
  updateNurseryMutation,
  deletePictureMutation,
  updateNurseryConfigMutation,
} from '@graphql';
import { ErrorNotification, SuccessNotification, Alerts } from '@constructors';

interface INurseryState {
  nurseries: INurseryWithRevenues[];
  oneNursery: INursery;
}

//State
const state: INurseryState = {
  nurseries: [],
  oneNursery: null,
};

const mutations = {};

const actions = {
  async getNurseries(context, payload: InurseriesConnectionArgs) {
    const { edges, pageInfo, aggregate } = await nurseriesConnectionQuery(payload);
    NurseryModule.updateState({
      nurseries: edges.map((e) => e.node) || [],
    });
    return { edges, pageInfo, aggregate };
  },

  async getOneNursery(context, nurseryId: string) {
    const nursery = await getNurseryQuery(nurseryId);
    NurseryModule.updateState({
      oneNursery: nursery,
    });
    return nursery;
  },
  async updateNursery(context, payload: IupdateNurseryArgs) {
    try {
      const nursery = await updateNurseryMutation(payload);
      NurseryModule.updateState({
        oneNursery: nursery,
      });
      new SuccessNotification('Informations de la crèche modifiées');
    } catch (e) {
      var errorModifying = new ErrorNotification(
        'Erreur lors de la modification des informations de la crèche'
      );
    }
  },
  async deleteNurseryPicture(context, id: string) {
    try {
      await deletePictureMutation(id);
      const pictures = NurseryModule.state.oneNursery.pictures.filter((f) => f.id !== id);
      NurseryModule.updateState({
        oneNursery: {
          ...NurseryModule.state.oneNursery,
          pictures,
        },
      });
    } catch (e) {
      var errorDeleting = new ErrorNotification("Erreur lors de la suppression de l'image");
    }
  },
  async updateNurseryConfig(context, { form }: Alerts.FormPayload<INurseryConfigInput>) {
    try {
      await updateNurseryConfigMutation({ data: { price: form.price } });
      new SuccessNotification('Tarification modifiée');
    } catch (e) {
      var errorModifying = new ErrorNotification(
        'Erreur lors de la modification de la tarification'
      );
    }
  },
};

export const NurseryModule = defineModule('NurseryModule', state, {
  mutations,
  actions,
});
