import { defineModule } from '@/vuex-typed-modules';
import { ICronInput } from '@models';

import { executeCron } from '@graphql';

export interface ICronState {
  pending: boolean;
}

const initialState: ICronState = {
  pending: false,
};

//State
const state: ICronState = {
  ...initialState,
  pending: false,
};

const getters = {};
const mutations = {};

const actions = {
  async executeCron(ctx, { name, password }: ICronInput): Promise<void> {
    try {
      const res = await executeCron({ data: { name, password } });
    } catch (e) {
      console.log(e);
    }
  },
};

export const CronModule = defineModule('CronModule', state, {
  mutations,
  actions,
  getters,
});
