













import { Component, Vue, Prop } from 'vue-property-decorator';
import { format, getDate } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { IReservationStatus, IReservationType } from '@models';
import { reservationTypeConstant } from '@constants';
import { hoursFormater } from '@utils';

@Component({})
export default class DateDisplay extends Vue {
  @Prop({ required: true, type: null }) date: Date;
  @Prop({ type: null }) type: IReservationType;
  @Prop({ required: false, default: null }) fromHours: number;
  @Prop({ required: false, default: null }) toHours: number;

  get day() {
    return getDate(this.date);
  }
  get month() {
    return format(this.date, 'MMMM', { locale: frLocale });
  }

  get typeDisplay() {
    if (this.type) {
      return `${format(this.date, 'dddd', { locale: frLocale })}
        ${reservationTypeConstant[this.type]}
      `;
    }
    return null;
  }

  get timeDisplay() {
    if (this.fromHours && this.toHours) {
      return `de ${hoursFormater(this.fromHours)} à ${hoursFormater(this.toHours)}`;
    }
    return null;
  }
}
