















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { Router } from '@router';

@Component({})
export default class BackLink extends Vue {
  @Prop() to: string | Location;
  @Prop() iconRight: boolean;
  @Prop({ default: false }) previous: boolean;

  get processPrevious() {
    if (this.previous && this.display) {
      return null;
    } else {
      return this.to;
    }
  }

  get display() {
    if (this.previous) {
      return this.$store.state.RouterModule.from.name;
    }
    return true;
  }

  emitClick(event) {
    if (this.previous && this.display) {
      this.$router.go(-1);
    } else {
      this.$emit('click');
    }
  }
}
