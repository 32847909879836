var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-box",class:{
    noMargin: _vm.data.noMargin,
    halfWidth: _vm.data.halfWidth,
    formError: _vm.formError,
    formValid: _vm.formValid,
    disabled: _vm.data.disabled,
    focus: _vm.isFocused,
  }},[(_vm.data.label)?_c('label',{staticClass:"input-label",attrs:{"for":_vm.formId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('Popup',{ref:"popup",attrs:{"select-mode":true,"outside":"","shadow":false,"tabindex":"0"},on:{"open":function($event){return _vm.handleFocus()},"close":function($event){return _vm.handleBlur()}},scopedSlots:_vm._u([{key:"popup",fn:function(ref){
  var direction = ref.direction;
return [(_vm.isDynamic)?[_c('div',{staticClass:"flex column nowrap w100 center",class:{ reverse: direction === 'top' }},[(_vm.isSearch)?_c('div',{staticClass:"flex pad5 w100 nogrow"},[_c('SearchField',{attrs:{"full":"","loading":_vm.loading},on:{"input":_vm.handleSearch},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1):_vm._e(),_c('InfiniScroll',{attrs:{"handler":_vm.data.handler,"handler-params":_vm.data.handlerParams,"type":"button","spinner-size":18,"page":_vm.page,"has-next-page":_vm.hasNextPage,"first-fetch":false},on:{"update:page":function($event){_vm.page=$event},"update:hasNextPage":function($event){_vm.hasNextPage=$event},"update:has-next-page":function($event){_vm.hasNextPage=$event},"newContent":_vm.newContent}},[_c('ul',{staticClass:"options-list flex",attrs:{"list":""}},_vm._l((_vm.dynamicOptions),function(option){return _c('li',{key:option.value,staticClass:"option flex",class:{ selected: option.value === _vm.value },attrs:{"title":option.text},on:{"click":function($event){$event.stopPropagation();return _vm.selectOption(option)}}},[(option.icon)?_c('div',{staticClass:"image bg",style:({
                    backgroundImage: ("url(" + (option.icon || require('@images/user.jpg')) + ")"),
                  })}):_vm._e(),_c('span',{staticClass:"value ellipsis"},[_vm._v(_vm._s(option.text))]),(option.value === _vm.value)?_c('div',{staticClass:"icon"},[_c('SvgIcon',{attrs:{"src":"icons/Forms/done.svg","color":"green"}})],1):_vm._e()])}),0)])],1)]:[_c('ul',{staticClass:"options-list flex",attrs:{"list":""}},_vm._l((_vm.data.options),function(option){return _c('li',{key:option.value,staticClass:"option flex",class:{ selected: option.value === _vm.value },attrs:{"title":option.text},on:{"click":function($event){$event.stopPropagation();return _vm.selectOption(option)}}},[_c('span',{staticClass:"value ellipsis"},[_vm._v(_vm._s(option.text))]),(option.value === _vm.value)?_c('div',{staticClass:"icon"},[_c('SvgIcon',{attrs:{"src":"icons/Forms/done.svg","color":"green"}})],1):_vm._e()])}),0)]]}},{key:"button",fn:function(){
                  var _obj;
return [_c('div',{staticClass:"input-container select",style:({ width: _vm.data.width || _vm.width, maxWidth: _vm.data.width || _vm.width }),attrs:{"id":_vm.formId,"disabled":_vm.data.disabled}},[_c('div',{staticClass:"display-text flex"},[(_vm.data.icon)?_c('div',{staticClass:"icon"},[_c('SvgIcon',{attrs:{"src":_vm.data.icon,"size":24,"color":( _obj = {}, _obj[_vm.css.green] = _vm.formValid, _obj[_vm.css.red1] = _vm.formError, _obj[_vm.css.blue] = !_vm.formValid && !_vm.formValid && _vm.isFocused, _obj[_vm.css.g90] = true, _obj )}})],1):_vm._e(),_c('div',{staticClass:"infos flex column",class:{ top: !!_vm.getDisplayValue }},[_c('span',{staticClass:"placeholder ellipsis"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.getDisplayValue)?_c('span',{staticClass:"value ellipsis"},[_vm._v(_vm._s(_vm.getDisplayValue))]):_vm._e()]),_c('div',{staticClass:"icon-collapse flex center"},[_c('SvgIcon',{staticClass:"arrow",attrs:{"src":"icons/Forms/unfold.svg","size":20}})],1)])])]},proxy:true}])}),(_vm.vl)?_c('FormError',{attrs:{"vl":_vm.vl,"data":_vm.data}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }