









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
  @Prop({ default: 'blue' })
  color: string;
  @Prop({ default: 30 }) size: number;
  @Prop() full: boolean;
}
