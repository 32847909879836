

















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { FormMixin } from '../../Mixins/FormMixin';
import { Forms } from '@constructors';

@Component({
  components: {},
})
export default class RadioTabs extends Vue {
  @Prop() value: string;
  @Prop() tabs: Forms.IRadioItem<any>[];
}
