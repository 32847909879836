










import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICustomer } from '@models';
import { AuthModule } from '../../../store';
import { routesNames } from '../../../router';
import { Location } from 'vue-router';

@Component({})
export default class Customer extends Vue {
  @Prop() customer: ICustomer;
  @Prop() type: 'child' | null;

  get avatar() {
    return {
      backgroundImage: `url(${
        this.customer.picture ? this.customer.picture.url : require('@images/user.jpg')
      })`,
    };
  }

  get profile(): Location {
    if (AuthModule.getters.isAdmin) {
      return { name: routesNames.admin.INSCRITS.DETAIL, params: { customerId: this.customer.id } };
    } else {
      return {
        name: routesNames.directeur.INSCRITS.DETAIL,
        params: { customerId: this.customer.id },
      };
    }
  }
}
