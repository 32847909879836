






































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  Forms,
  UserNotification,
  Alerts,
  ErrorNotification,
  SuccessNotification,
} from '@constructors';
import { RadioTabs, InfiniScrollMixin, Email } from '@components';
import { routesNames } from '@router';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { IMailType, ImailsConnectionArgs, IMailConnection, ILangType } from '@models';
import { AuthModule, MailingModule } from '@store';
import { constantToSelectFormater } from '@utils';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { minSize } from '@validators';
import { emailLangConstant, emailTypeConstant } from '@constants';

@Component({
  components: { RadioTabs, Email },
})
export default class Mailings extends InfiniScrollMixin<IMailConnection, ImailsConnectionArgs> {
  public module = MailingModule;
  public routesNames = routesNames;

  public listHandler = this.module.actions.getEmails;

  get emailList() {
    return this.module.state.emails;
  }

  public filterTypeValue: IMailType = 'REGISTRATION';

  public filterLangValue: ILangType = 'FR';

  get getFilterTypeValue() {
    return this.filterTypeValue;
  }
  set getFilterTypeValue(value: IMailType) {
    this.filterTypeValue = value;
    this.newSearch();
  }

  get getFilterLangValue() {
    return this.filterLangValue;
  }
  set getFilterLangValue(value: ILangType) {
    this.filterLangValue = value;
    this.newSearch();
  }

  get handlerParams(): ImailsConnectionArgs {
    return {
      where: {
        search: this.search,
        type: this.getFilterTypeValue,
        lang: this.getFilterLangValue,
      },
    };
  }

  public tabsType: Forms.IRadioItem<IMailType>[] = constantToSelectFormater(emailTypeConstant);
  public tabsLang: Forms.IRadioItem<ILangType>[] = constantToSelectFormater(emailLangConstant);

  get toCrecheList() {
    return { name: routesNames.admin.CRECHES };
  }

  destroyed() {
    this.module.updateState({
      emails: [],
    });
  }
}
