































































import { Component } from 'vue-mixin-decorator';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';
import Cleave from 'vue-cleave-component';

@Component({
  components: {
    FormError,
    Cleave,
  },
})
export default class FormText extends FormMixin {}
