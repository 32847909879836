








































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICommunityNurseryTemplateAvailability } from '@models';
import PlanningSlot from './PlanningSlot.vue';
import FormText from '../Forms/FormText.vue';

@Component({
  components: { PlanningSlot, FormText },
})
export default class PlanningTemplateItemCommunity extends Vue {
  @Prop() day: number;
  @Prop() communityNurseryTemplateAvailabilities: ICommunityNurseryTemplateAvailability[];
  isInCurrentMonth = true;
  get morningAvailabilities() {
    return this.communityNurseryTemplateAvailabilities?.filter((av) => av.type === 'MORNING') ?? [];
  }

  get afternoonAvailabilities() {
    return (
      this.communityNurseryTemplateAvailabilities?.filter((av) => av.type === 'AFTERNOON') ?? []
    );
  }

  get dayLabel() {
    switch (this.day) {
      case 0:
        return 'Dimanche';
      case 1:
        return 'Lundi';
      case 2:
        return 'Mardi';
      case 3:
        return 'Mercredi';
      case 4:
        return 'Jeudi';
      case 5:
        return 'Vendredi';
      case 6:
        return 'Samedi';
      default:
        break;
    }
  }

  addCommunityTemplateAvailability(data: ICommunityNurseryTemplateAvailability) {
    if (data.remainingPlaces < data.capacity) data.remainingPlaces++;
  }

  subtractCommunityTemplateAvailability(data: ICommunityNurseryTemplateAvailability) {
    if (data.remainingPlaces > 0) data.remainingPlaces--;
  }
  hundelRemainingPlaces(data: ICommunityNurseryTemplateAvailability) {
    let value = parseInt(data.remainingPlaces + '');
    if (value > data.capacity) value = data.capacity;
    if (value < 0) value = 0;
    data.remainingPlaces = value;
  }
}
