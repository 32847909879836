






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  props: ['disabled'],
})
export default class Observer extends Vue {
  @Prop({ default: false }) disabled: boolean;

  public observer: IntersectionObserver = null;

  $refs: {
    observer: HTMLElement;
  };

  @Watch('disabled')
  watchDisabled(newVal, oldVal) {
    if (newVal) {
      this.observer.unobserve(this.$refs.observer);
    } else {
      this.observer.observe(this.$refs.observer);
    }
  }

  updated() {
    this.observer.observe(this.$refs.observer);
  }

  mounted() {
    this.observer = new IntersectionObserver((entry) => {
      entry.forEach((e) => {
        if (e.isIntersecting) {
          this.$emit('visible');
        }
        if (e.intersectionRatio < 1) {
          this.$emit('hidden');
        }
      });
    });
    if (!this.disabled) {
      this.observer.observe(this.$refs.observer);
    }
    window.addEventListener('resize', this.updated);
  }
  destroyed() {
    this.observer.disconnect();
    window.removeEventListener('resize', this.updated);
  }
}
