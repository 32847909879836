import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import apolloLogger from 'apollo-link-logger';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { tokenID, AuthModule } from '@store';
import localForage from 'localforage';
import { apiProvider } from '@models';
import { ErrorNotification } from '@constructors';
import { analytics } from '@services';
export const API_URL = process.env.VUE_APP_API_URL;

const operationNameList = ['activateUser', 'login', 'resetUserPassword', 'updateUser'];

const errorLink = onError(({ graphQLErrors, networkError, forward, response }) => {
  const user = AuthModule.state.userInfos;
  analytics.exceptionTelemetry(networkError, user);
  const unauthorizedError =
    graphQLErrors &&
    graphQLErrors.find((err) => err.message === 'unauthorized' || err.message === 'error:auth');
  if (unauthorizedError) {
    // AuthModule.actions.disconnectRequest();
  }
  if (networkError && response === undefined) {
    console.log(`[Network error]: ${networkError}`);
    AuthModule.updateState({ networkError: true });
    // new ErrorNotification('Impossible de communiquer avec le serveur');
  } else {
    AuthModule.updateState({ networkError: false });
  }
});

const contextLink = setContext(async (graphQlRequest, { headers }) => {
  const user = AuthModule.state.userInfos;
  let props = null;
  if (operationNameList.filter((x) => x === graphQlRequest.operationName).length > 0) {
    props = {
      email: graphQlRequest.variables?.data?.email,
      id: graphQlRequest.variables?.user?.id ?? graphQlRequest.variables?.userId,
    };
  } else {
    props = graphQlRequest.variables;
  }
  analytics.traceTelemetry(graphQlRequest.operationName, {
    args: props,
    currentUser: user,
  });
  const token = await localForage.getItem(tokenID);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const uploadLink = createUploadLink({
  fetch,
  uri: API_URL,
});

const apolloLink =
  process.env.VUE_APP_STAGE === 'production'
    ? [contextLink, errorLink, uploadLink]
    : [contextLink, errorLink, apolloLogger, uploadLink];

export const apolloClient = new ApolloClient({
  link: ApolloLink.from(apolloLink),
  cache: new InMemoryCache(),
  connectToDevTools: false,
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export const sgts = apiProvider(apolloClient);
