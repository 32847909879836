var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"inputBox",staticClass:"input-box",class:{
    noMargin: _vm.data.noMargin,
    halfWidth: _vm.data.halfWidth,
    formError: _vm.formError,
    formValid: _vm.formValid,
    disabled: _vm.data.disabled,
    focus: _vm.isFocused,
  }},[(_vm.label)?_c('label',{staticClass:"input-label",attrs:{"for":_vm.formId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-container"},[(_vm.data.icon)?_c('div',{staticClass:"icon"},[_c('SvgIcon',{attrs:{"src":_vm.data.icon,"size":22,"color":( _obj = {}, _obj[_vm.css.green] = _vm.formValid, _obj[_vm.css.red1] = _vm.formError, _obj[_vm.css.blue] = !_vm.formValid && !_vm.formValid && _vm.isFocused, _obj[_vm.css.g90] = true, _obj )}})],1):_vm._e(),(_vm.editor)?_c('div',{ref:"input",staticClass:"input-form flex column richText w100",attrs:{"id":_vm.formId}},[_c('EditorMenuBar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var commands = ref.commands;
        var isActive = ref.isActive;
return [(!_vm.data.disabled)?_c('div',{staticClass:"menubar flex nowrap",staticStyle:{"height":"40px"}},[_c('div',{staticClass:"flex grow"},[_c('ButtonExtension',{attrs:{"icon":"bold","active":isActive.bold()},on:{"click":commands.bold}}),_c('ButtonExtension',{attrs:{"icon":"italic","active":isActive.italic()},on:{"click":commands.italic}}),_c('ButtonExtension',{attrs:{"icon":"underline","active":isActive.underline()},on:{"click":commands.underline}}),_c('ButtonExtension',{attrs:{"icon":"bullet","active":isActive.bullet_list()},on:{"click":commands.bullet_list}}),_c('Popup',{ref:"imagePopup",attrs:{"container":_vm.$refs.inputBox,"width":300},on:{"close":function($event){return _vm.imageImport.reset()}},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_c('div',{staticClass:"flex column w100 pad5"},[_c('FormUpload',{attrs:{"data":_vm.imageImport.data},model:{value:(_vm.imageImport.value),callback:function ($$v) {_vm.$set(_vm.imageImport, "value", $$v)},expression:"imageImport.value"}}),(_vm.imageImport.value)?_c('div',{staticClass:"flex center pad10"},[_c('VButton',{attrs:{"theme":"white","button-style":"shadow","handler":function () { return _vm.addImage(commands); }}},[_vm._v("Ajouter")])],1):_vm._e()],1)]},proxy:true},{key:"button",fn:function(ref){
        var active = ref.active;
return [_c('ButtonExtension',{attrs:{"icon":"image","active":active}})]}}],null,true)})],1),_c('div',{staticClass:"flex grow wend"},[(_vm.data.emailVariables)?_c('Popup',{attrs:{"container":_vm.$refs.inputBox},on:{"close":function($event){return _vm.imageImport.reset()}},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_c('ul',{staticClass:"listOptions tx-e",attrs:{"list":"","small":""}},_vm._l((_vm.variables),function(variable){return _c('li',{key:variable.id,on:{"click":function($event){return _vm.addVariable(variable)}}},[_vm._v(" "+_vm._s(variable.name)+" ")])}),0)]},proxy:true},{key:"button",fn:function(ref){
        var active = ref.active;
return [_c('ButtonExtension',{attrs:{"icon":"code","active":active}},[_vm._v("Variables")])]}}],null,true)}):_vm._e()],1)]):_vm._e()]}}],null,false,212657519)}),_c('EditorContent',{staticClass:"editor",attrs:{"editor":_vm.editor}})],1):_vm._e()]),(_vm.vl && _vm.data.error)?_c('FormError',{attrs:{"vl":_vm.vl,"data":_vm.data}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }