















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IDocument, IDocumentType, IDocumentStatus } from '@models';
import { documentNamesConstant, documentStatusNamesConstant } from '@constants';
import DocumentViewer from './DocumentViewer.vue';

@Component({
  components: { DocumentViewer },
})
export default class DocumentDisplay extends Vue {
  @Prop() document: IDocument;

  public showModal = false;

  get name() {
    return documentNamesConstant[this.document.type];
  }

  get statusColor() {
    if (this.document.status === 'OK') return 'green';
    else if (this.document.status === 'PENDING') return 'yellow';
    else if (this.document.status === 'DECLINED') return 'red';
    else return 'grey';
  }

  get status() {
    return documentStatusNamesConstant[this.document.status];
  }
}
