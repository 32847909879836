


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICafDocument } from '@models';
import DocumentViewer from './DocumentViewer.vue';
import { Alerts, ErrorNotification, SuccessNotification } from '@constructors';
import { deleteCafDocumentMutation } from '@graphql';
import { AuthModule, CustomersModule } from '@store';

@Component({
  components: { DocumentViewer },
})
export default class CafDocumentDisplay extends Vue {
  @Prop() cafDocument: ICafDocument;

  public showModal = false;

  get name() {
    return this.cafDocument.title;
  }

  get inscrit() {
    return CustomersModule.state.oneCustomer;
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  async deleteDocument() {
    var alert = new Alerts.ConfirmAlert({
      title: `Voulez-vous vraiment supprimer le document "${this.cafDocument.title}"?`,
      actions: [
        new Alerts.ConfirmAction({
          text: 'Supprimer',
          handler: async () => {
            try {
              await deleteCafDocumentMutation(this.cafDocument.id);
              await CustomersModule.actions.getOneCustomer(this.inscrit.id);
              new SuccessNotification('Document supprimé');
            } catch (e) {
              console.log(e);
              var error = new ErrorNotification('Erreur lors du suppression de document');
            }
          },
        }),
      ],
    });
  }
}
