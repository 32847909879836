









import { Component, Vue, Prop } from 'vue-property-decorator';
import Colors from '@colors';

@Component
export default class InfoMessage extends Vue {
  @Prop({ default: 'info' }) type: 'info' | 'warning' | 'error' | 'success';
  @Prop() title: string;

  public colors = {
    info: Colors.dark,
    warning: Colors.orange,
    error: Colors.red1,
    success: Colors.green,
  };

  $refs: {
    info: HTMLElement;
  };

  mounted() {
    this.$refs.info.style.setProperty('--Color', this.type);
  }
}
