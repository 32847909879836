



























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  IAvailability,
  IReservation,
  IRegistration,
  IPlanningEvent,
  IWelcomingExtended,
  IClosingDate,
  ISection,
} from '@models';
import { isSameDay, isSameMonth, getDate, format, isBefore, isAfter } from 'date-fns';
import PlanningSlot from './PlanningSlot.vue';
import { routesNames } from '../../../router';
import { AuthModule, SharedModule } from '../../../store';
import { Location } from 'vue-router';

@Component({
  components: { PlanningSlot },
})
export default class PlanningItem extends Vue {
  @Prop() date: Date;
  @Prop() currentMonth: Date;
  @Prop() reservations: IReservation[];
  @Prop() registrations: IRegistration[];
  @Prop() availabilities: IAvailability[];
  @Prop() welcomings: IWelcomingExtended[];
  @Prop({ default: 'fullscreen' }) size: 'fullscreen' | 'widget';
  @Prop() closingDates: IClosingDate[];

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get morningAvailabilities() {
    return this.availabilities.filter((av) => av.type === 'MORNING');
  }

  get afternoonAvailabilities() {
    return this.availabilities.filter((av) => av.type === 'AFTERNOON');
  }

  get hasMorningAvailibilitiesSections() {
    return this.morningAvailabilities.reduce((acc, value) => (acc += value.sections.length), 0) > 0;
  }
  get hasAfternoonAvailibilitiesSections() {
    return (
      this.afternoonAvailabilities.reduce((acc, value) => (acc += value.sections.length), 0) > 0
    );
  }

  get morningEvents(): IPlanningEvent {
    const reservations = this.reservations.filter((f) => f.type === 'MORNING');
    const registrations = this.registrations.filter((f) => f.reservation.type === 'MORNING');
    return {
      reservations,
      registrations,
    };
  }
  get afternoonEvents(): IPlanningEvent {
    const reservations = this.reservations.filter((f) => f.type === 'AFTERNOON');
    const registrations = this.registrations.filter((f) => f.reservation.type === 'AFTERNOON');
    return {
      reservations,
      registrations,
    };
  }

  get allDayEvents(): IPlanningEvent {
    const reservations = this.reservations.filter((f) => f.type === 'ALL_DAY');
    const registrations = this.registrations.filter((f) => f.reservation.type === 'ALL_DAY');
    return {
      reservations,
      registrations,
    };
  }

  get isClosed(): boolean {
    const publicHolidays = SharedModule.state.publicHolidays;
    return (
      this.closingDates.some(({ id, closingDateStart, closingDateEnd }) => {
        return (
          (isAfter(this.date, closingDateStart) || isSameDay(this.date, closingDateStart)) &&
          isBefore(this.date, closingDateEnd)
        );
      }) || publicHolidays.some((x) => isSameDay(this.date, x))
    );
  }

  get formatHour() {
    return (date: Date) => format(date, 'hh:mm');
  }

  get customerLink(): (id: string) => Location {
    return (id) => {
      const name = this.isAdmin
        ? routesNames.admin.INSCRITS.DETAIL
        : routesNames.directeur.INSCRITS.DETAIL;
      return {
        name,
        params: {
          customerId: id,
        },
      };
    };
  }

  get formattedDate() {
    return getDate(this.date);
  }

  get isToday() {
    return isSameDay(this.date, new Date());
  }
  get isInCurrentMonth() {
    return isSameMonth(this.date, this.currentMonth);
  }
}
