

















































import { FormCalendar, FormSelectMultiple, Radio } from '@components';
import { Forms } from '@constructors';
import { nurseriesConnectionQuery } from '@graphql';
import { ICapacityStatistics, IcapacityStatisticsArgs, INurseryEdgeWithRevenues } from '@models';
import { sgts } from '@services';
import { formatDateExport, nurseriesFormater } from '@utils';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { FormCalendar, FormSelectMultiple, Radio } })
export default class Disponibilities extends Vue {
  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };

  public listHandler = (args: IcapacityStatisticsArgs) =>
    sgts
      .capacityStatistics()
      .$fragment(
        `name
            totalCapacity
            mikadoSlot
            availableSlot
            reservedSlot
            remainingSlot`
      )
      .$args(args)
      .$fetch();

  get handlerParams(): IcapacityStatisticsArgs {
    return {
      where: {
        ...this.calendarStats.value,
        ...(this.selectNurseries.value.length && {
          nurseries: this.selectNurseries.value,
        }),
      },
      page: 1,
      limit: 20,
    };
  }

  async getNewDispos() {
    if (
      this.calendarStats.value.start &&
      this.calendarStats.value.end &&
      this.selectNurseries.value.length
    ) {
      this.nurseries = await this.listHandler(this.handlerParams);
    } else if (!this.selectNurseries.value.length) {
      this.nurseries = [];
    }
  }

  created() {
    this.getNewDispos();
  }

  public columns = [
    'Crèche',
    'Capacité totale',
    'Places régulières',
    'Disponibilités',
    'Réservations',
    'Restant',
  ];

  // public nurseryColumns = [
  //   'Statut de la réservation',
  //   'Date de réservation',
  //   'Code analytique crèche',
  //   'Nom structure',
  //   'Nom du parent',
  //   'Prénom du parent',
  // ];

  async exportData() {
    const csvContent = await sgts.exportCapacityStatistics().$args(this.handlerParams).$fetch();
    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Disponibilités_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  public nurseries: ICapacityStatistics[] = [];

  public calendarStats = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    width: '260px',
  }).extract();

  public selectNurseries = new Forms.SelectMultiple<string[], INurseryEdgeWithRevenues>({
    placeholder: 'Ajouter des crèches',
    handler: nurseriesConnectionQuery,
    handlerParams: {
      limit: 10,
    },
    formater: nurseriesFormater,
    width: '260px',
    search: (value) => ({ search: value }),
  }).extract();
}
