




































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Forms, SuccessNotification } from '@constructors';
import { routesNames } from '@router';
import {
  AuthModule,
  DirectorNurseryModule,
  NurseryModule,
  getNurseryReservations,
  getNurseryRegistrations,
  SharedModule,
} from '@store';
import { Planning, FormText } from '@components';
import {
  INursery,
  IAvailability,
  IPlanningBounds,
  IReservation,
  IRegistration,
  IWelcomingExtended,
  ICommunityNurseryAvailability,
  IUpdateDspNurseryZipCodeInput,
  IDspNurseryZipCode,
} from '@models';
import {
  getDspNurseryZipCodeQuery,
  nurseryPlanningQuery,
  updateDspNurseryZipCodesMutation,
} from '@graphql';
import DemandesTemplate from '../Shared/Demandes/DemandesTemplate.vue';
import { zipCode } from '@validators';

@Component({
  components: { Planning, DemandesTemplate, FormText },
  validations: {
    zipCodeForm: { fieldsValues: { zipCode: { zipCode } } },
  },
})
export default class HomeDirecteur extends Vue {
  dspNurseryZipCodes: IDspNurseryZipCode[] = [];
  zipCodeToAdd: string = null;
  public listHandlers = {
    reservations: getNurseryReservations,
    registrations: getNurseryRegistrations,
  };
  public redirect = routesNames.directeur.DEMANDES.DETAIL;

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }

  get isDspNursery() {
    return this.nursery?.dspNursery;
  }

  get title() {
    return `Crèche - ${this.nursery.name}`;
  }

  public loading = false;
  public slot: IPlanningBounds = { start: null, end: null, saveCommunityData: false };

  get name() {
    return AuthModule.state.userInfos.firstName;
  }

  get planningRoute() {
    return { name: routesNames.directeur.PLANNING };
  }

  get demandesRoute() {
    return { name: routesNames.directeur.DEMANDES.LIST };
  }

  public reservations: IReservation[] = [];
  public registrations: IRegistration[] = [];
  public availabilities: IAvailability[] = [];
  public welcomings: IWelcomingExtended[] = [];
  public communityNurseryAvailabilities: ICommunityNurseryAvailability[] = [];

  async getReservations(slot: IPlanningBounds) {
    try {
      this.slot = slot;
      this.loading = true;
      const {
        reservations,
        registrations,
        availabilities,
        welcomings,
        communityNurseryAvailabilities,
      } = await nurseryPlanningQuery({
        id: this.nursery.id,
        start: slot.start,
        end: slot.end,
      });
      this.reservations = reservations;
      this.registrations = registrations;
      this.availabilities = availabilities;
      this.welcomings = welcomings;
      this.communityNurseryAvailabilities = communityNurseryAvailabilities;
      if (this.isDspNursery) {
        await this.getDspNurseryZipCodes();
        await SharedModule.actions.fetchZipCodes();
      }
    } finally {
      this.loading = false;
    }
  }

  handleZipCode(value: string) {
    this.zipCodeForm.fieldsValues.zipCode = value;
    this.zipCodeToAdd = value;
  }

  removeItem(item: IDspNurseryZipCode) {
    const index = this.dspNurseryZipCodes.indexOf(item);
    if (index > -1) {
      this.dspNurseryZipCodes.splice(index, 1);
    }
  }

  addItem() {
    if (this.dspNurseryZipCodes.findIndex((x) => x.zipCode === this.zipCodeToAdd) < 0) {
      const newItem = {} as IDspNurseryZipCode;
      newItem.zipCode = this.zipCodeToAdd;
      newItem.nursery = {} as INursery;
      newItem.nursery.id = this.nursery.id;
      this.dspNurseryZipCodes.push(newItem);
    }
    this.zipCodeToAdd = null;
    this.zipCodeForm.fieldsValues.zipCode = null;
  }

  formatedZipCode(value: string) {
    if (this.zipCodeOptions.length > 0) {
      const item = this.zipCodeOptions.find((x) => x.zipCode.toUpperCase() === value.toUpperCase());
      if (item) return `${item.label} (${item.zipCode})`;
    }
    return value;
  }
  get zipCodeOptions() {
    return SharedModule.state.allZipCodes;
  }

  public zipCodeForm = new Forms.Form({
    fields: {
      zipCode: new Forms.TextForm<any>({
        value: this.zipCodeToAdd,
        editMode: !!this.zipCodeToAdd,
        valueType: 'string',
        placeholder: 'Ajouter un code postal',
      }),
    },
    validations: {},
  });

  get isDisabled() {
    return this.$v.zipCodeForm.$invalid && this.$v.zipCodeForm.$dirty;
  }

  async getDspNurseryZipCodes() {
    try {
      const nurseryId = this.nursery.id;
      this.dspNurseryZipCodes = await getDspNurseryZipCodeQuery({
        nurseryId: nurseryId,
      });
    } finally {
    }
  }

  async handleUpdateZipCodeList() {
    await updateDspNurseryZipCodesMutation({
      data: {
        data: this.dspNurseryZipCodes.map((x) => x as unknown as IUpdateDspNurseryZipCodeInput),
      },
    });
    const _ = new SuccessNotification('Liste de codes postaux sauvegardé');
    await this.getDspNurseryZipCodes();
  }
}
