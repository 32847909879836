import { defineModule } from '@/vuex-typed-modules';
import {
  IUser,
  ILoginInput,
  IActivateUserInput,
  IUpdateUserInput,
  IResetUserPasswordInput,
} from '@models';
import { Router, routesNames } from '@router';
import { ErrorNotification, SuccessNotification } from '@constructors';

import {
  login,
  updateUserMutation,
  requestResetUserPassword,
  resetUserPassword,
  activateUser,
  getMyInfosQuery,
  requestResetUserPasswordWithEmail,
} from '@graphql';
import { JWT } from './Storage.module';

export interface IAuthState {
  authChecked?: boolean;
  loggedIn: boolean;
  userInfos: Partial<IUser>;
  networkError?: boolean;
}

const initialState: IAuthState = {
  loggedIn: false,
  userInfos: null,
};

//State
const state: IAuthState = {
  ...initialState,
  authChecked: false,
  networkError: false,
};

const getters = {
  isAdmin(state: IAuthState) {
    return state.userInfos?.role === 'ADMIN';
  },
  isDirector(state: IAuthState) {
    return state.userInfos?.role === 'DIRECTOR';
  },
};

const mutations = {
  connectUser(state: IAuthState, infos: IUser) {
    state.userInfos = infos;
    state.loggedIn = true;
  },
};

const onUserLoggedIn = async (user: IUser, token: string, nextRoute: string): Promise<void> => {
  if (nextRoute) {
    Router.push(nextRoute);
  } else if (user.role === 'ADMIN') {
    Router.push({ name: routesNames.admin.HOME });
  } else {
    if (user.nurseries?.length) {
      Router.push({
        name: routesNames.directeur.HOME,
        params: { crecheId: user.nurseries[0].id },
      });
    } else {
      Router.push({
        name: routesNames.directeur.HOME,
      });
    }
  }
};

const actions = {
  async connexionRequest(
    context,
    { email, password, nextRoute }: ILoginInput & { nextRoute: string }
  ): Promise<void> {
    try {
      const { user, token } = await login({ email, password });
      AuthModule.updateState({ authChecked: true });
      AuthModule.mutations.connectUser(user);

      await JWT.set(token);

      onUserLoggedIn(user, token, nextRoute);

      new SuccessNotification(`Vous êtes connecté en tant que ${user.firstName}`);
    } catch (e) {
      console.log(e.message);
      if (e.message === 'Failed to fetch') {
        return Promise.reject('Connexion avec le serveur impossible');
      } else if (e.message === 'error:login') {
        return Promise.reject('Adresse mail ou mot de passe incorrect');
      } else {
        return Promise.reject('Adresse mail ou mot de passe incorrect');
      }
    }
  },

  async resetUserPassword(context, resetUserPasswordInput: IResetUserPasswordInput): Promise<void> {
    try {
      await resetUserPassword(resetUserPasswordInput);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async requestResetUserPassword(context, userId): Promise<void> {
    try {
      await requestResetUserPassword(userId);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async requestResetUserPasswordWithEmail(context, userEmail): Promise<void> {
    try {
      await requestResetUserPasswordWithEmail(userEmail);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateUser(context, user: IUpdateUserInput): Promise<void> {
    try {
      await updateUserMutation(user);
      new SuccessNotification('Ok');
    } catch (e) {
      var err = new ErrorNotification(e);
    }
  },

  async activateUser(
    context,
    { activateUserInput, nextRoute }: { activateUserInput: IActivateUserInput; nextRoute: string }
  ): Promise<void> {
    try {
      if (AuthModule.state.loggedIn) {
        new ErrorNotification('Vous êtes déjà connecté');
        return;
      }

      const { user, token } = await activateUser(activateUserInput);
      await JWT.set(token);
      await AuthModule.actions.checkUserSession();
      onUserLoggedIn(user, token, nextRoute);
    } catch (e) {
      if (e.message === 'GraphQL error: error:invalid-token') {
        var expiredLink = new ErrorNotification('Le lien a expiré');
      }

      return Promise.reject(e);
    }
  },

  async checkUserSession(context): Promise<void> {
    try {
      if (AuthModule.state.loggedIn) return;
      await AuthModule.actions.reloadUserInfos();
    } catch (e) {
      Router.push({ name: routesNames.CONNEXION });
      return Promise.reject(e);
    } finally {
      AuthModule.updateState({
        authChecked: true,
      });
    }
  },

  async reloadUserInfos(context): Promise<void> {
    try {
      const token = await JWT.fetch();
      if (token) {
        const user = await getMyInfosQuery.$fetch();
        AuthModule.updateState({ authChecked: true });
        AuthModule.mutations.connectUser(user);
      }
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async softDisconnect(context) {
    await JWT.clear();
    AuthModule.resetState();
    AuthModule.updateState({
      authChecked: true,
    });
  },
  async disconnectRequest(context): Promise<void> {
    await JWT.clear();
    window.location.replace('/connexion');
  },
};

export const AuthModule = defineModule('AuthModule', state, {
  mutations,
  actions,
  getters,
});
