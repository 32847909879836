




import { Component, Vue, Prop } from 'vue-property-decorator';
import PlanningTemplate from '../../../Shared/PlanningTemplate.vue';
import { NurseryModule } from '@store';

@Component({
  components: { PlanningTemplate },
})
export default class CrechesPlanning extends Vue {
  get nursery() {
    return NurseryModule.state.oneNursery;
  }
}
