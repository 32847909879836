import { sgts } from '@services';
import { ICreateUserInput, IUpdateUserInput } from '@models';
import { userFragment } from '@fragments';

export const createUserMutation = (user: ICreateUserInput) => {
  return sgts.createUser().$fragment(userFragment).$args({ user }).$post();
};

export const updateUserMutation = (user: IUpdateUserInput) => {
  return sgts.updateUser().$fragment(userFragment).$args({ user }).$post();
};
