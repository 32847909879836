









































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { EventBus } from '@services';

@Component({})
export default class UIModal extends Vue {
  @Prop()
  show: boolean;
  @Prop({ required: false, default: true })
  isPopup: boolean;
  @Prop({ required: false })
  height: number;
  @Prop({ required: false })
  width: number;
  @Prop({ required: false })
  fitWindow: boolean;
  @Prop({ required: false })
  onlyContent: boolean;
  @Prop({})
  footerShadow: boolean;

  $slots;

  closeModal() {
    this.$emit('update:show', false);
    this.$emit('close');
  }

  emitClosePopup() {
    EventBus.$emit('closePopups');
  }
}
