










































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';

import { EventBus, Events } from '@services';
import { calculatePopupRelativePosition, calculatePopupPosition } from '@methods';

@Component({})
export default class Popup extends Vue {
  @Prop({ default: 'auto', type: [String, Number] })
  width: number | string;
  @Prop({ default: 'click' })
  mode: 'click' | 'hover' | 'manual';
  @Prop()
  container: HTMLElement;
  @Prop({ default: false })
  selectMode: boolean;
  @Prop({ default: true })
  scroll: boolean;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: 'white' })
  theme: 'white' | 'dark';
  @Prop({ default: false })
  outside: boolean;
  @Prop({ default: true }) shadow: boolean;
  @Prop() noIndicator: boolean;
  @Prop() nested: boolean;
  @Prop() strict: boolean;
  @Prop() nestedNamespace: string;
  @Prop({ default: '-1' }) tabindex: string;

  public show = false;
  public PopupXYTypes = {
    XType: null,
    YType: null,
  };
  public trianglePosition = null;

  $refs: {
    button: HTMLElement;
    popup: HTMLElement;
    popupRoot: HTMLElement;
  };

  handleTabFocus(event: Event) {
    this.$emit('focus', event);
  }

  handleTabBlur(event: Event) {
    this.$emit('blur', event);
  }

  get getWidth() {
    if (!this.width) return 'auto';
    return { minWidth: this.width + 'px' };
  }

  async togglePopup(mode: 'click' | 'hover', event: Event) {
    if (mode === this.mode) {
      if (this.disabled) {
        return;
      }
      if (!this.show) {
        this.showPopup(event);
      } else {
        this.closePopup();
      }
    }
  }

  async showPopup(event?: Event): Promise<void> {
    if (!this.show) {
      this.show = true;
      window.requestAnimationFrame(async () => {
        await this.$nextTick();
        const origin = this.$refs.button.children[0] as HTMLElement;
        const target = this.$refs.popup;
        const Types = calculatePopupRelativePosition(
          origin,
          target,
          this.selectMode,
          this.container,
          this.noIndicator
        );
        if (this.outside) {
          const { left, top, bottom, triangleLeft } = calculatePopupPosition(
            origin,
            target,
            Types,
            this.selectMode,
            this.noIndicator
          );
          target.style.left = left as string;
          target.style.top = top as string;
          target.style.bottom = bottom as string;
          if (this.selectMode) {
            target.style.width = this.$refs.button.clientWidth + 'px';
          }

          if (Types.XType === 'center') {
            this.trianglePosition = {
              left: '50%',
            };
          } else {
            this.trianglePosition = {
              left: triangleLeft,
            };
          }
          this.PopupXYTypes = Types;
        } else {
          this.PopupXYTypes = Types;
          if (Types.XType === 'center') {
            this.trianglePosition = {
              left: '50%',
            };
          } else {
            this.trianglePosition = {
              [Types.XType]: origin.offsetWidth / 2 - 13 + 6 + 'px',
            };
          }
        }
        if (Types.XType === 'fill') {
          target.style.width =
            (this.container ? this.container.offsetWidth : window.innerWidth) - 20 + 'px';
        }

        if (Types.maxHeight) {
          target.style.maxHeight = Types.maxHeight + 'px';
        }
      });
      if (!this.nested) {
        EventBus.$emit(Events.CLOSE_POPUPS);
      } else if (this.nestedNamespace) {
        EventBus.$emit(`${Events.CLOSE_POPUPS}-${this.nestedNamespace}`);
      }
      window.addEventListener('resize', this.closePopup);
      window.addEventListener('scroll', this.closePopup);
      document.body.addEventListener('click', this.closePopup);
      document.body.addEventListener('touchstart', this.closePopup);
      this.$refs.popupRoot.addEventListener('touchstart', (e) => e.stopPropagation());
      if (this.getScrollParent(this.$refs.popupRoot)) {
        this.getScrollParent(this.$refs.popupRoot).addEventListener('scroll', this.closePopup);
      }
      if (!this.nested) {
        EventBus.$on(Events.CLOSE_POPUPS, this.closePopup);
      } else if (this.nestedNamespace) {
        EventBus.$on(`${Events.CLOSE_POPUPS}-${this.nestedNamespace}`, this.closePopup);
      }
      this.$emit('open');
    }
  }

  getScrollParent(node: HTMLElement): HTMLElement {
    if (node == null) {
      return null;
    }
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return this.getScrollParent(node.parentElement);
    }
  }

  closePopup(): void {
    // if (event) event.preventDefault()
    this.PopupXYTypes = {
      XType: null,
      YType: null,
    };
    this.show = false;
    this.$emit('close');
    EventBus.$off(Events.CLOSE_POPUPS);
    if (this.nestedNamespace) {
      EventBus.$off(`${Events.CLOSE_POPUPS}-${this.nestedNamespace}`);
    }
    window.removeEventListener('resize', this.closePopup);
    window.removeEventListener('scroll', this.closePopup);
    document.body.removeEventListener('click', this.closePopup);
    document.body.removeEventListener('touchstart', this.closePopup);

    if (this.getScrollParent(this.$refs.popupRoot))
      this.getScrollParent(this.$refs.popupRoot).removeEventListener('scroll', this.closePopup);
  }
}
