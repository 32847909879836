
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BackLink, InfiniScrollMixin } from '@components';
import { routesNames } from '@router';
import { range } from 'lodash';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Location } from 'vue-router';
import { AuthModule, DirectorNurseryModule, CustomersModule } from '@store';
import { INursery, IBanConnection, IbansConnectionArgs, IBan, IDocument } from '@models';
import { BanReasonConstant } from '@constants';
import { oc } from 'ts-optchain';

@Component({
  components: { BackLink },
})
export default class InscritsBannedTemplate extends InfiniScrollMixin<
  IBanConnection,
  IbansConnectionArgs
> {
  @Prop() nursery: INursery;
  @Prop() fullBan: boolean;
  public search = '';
  public routesNames = routesNames;
  public listHandler = CustomersModule.actions.getBannedCustomers;

  public columns = ['Parent', 'Date du bannissement', ''];

  get bannedList() {
    return CustomersModule.state.bannedCustomers.map((customer) => ({
      customer,
      date: format(
        customer.isBanned ? customer.updatedAt : customer.bans[0]?.createdAt,
        'DD MMM YYYY',
        { locale: frLocale }
      ),
      documents: [
        ...customer.documents,
        ...customer.children.reduce<IDocument[]>((acc, child) => {
          return acc.concat(child.documents);
        }, []),
      ],
    }));
  }

  get title() {
    if (AuthModule.getters.isAdmin) {
      return 'Bannis';
    } else {
      return `Bannis ${this.nursery.name}`;
    }
  }

  get backLink(): Location {
    if (AuthModule.getters.isAdmin) {
      return { name: routesNames.admin.INSCRITS.LIST };
    } else {
      return { name: routesNames.directeur.INSCRITS.LIST };
    }
  }

  get handlerParams(): IbansConnectionArgs {
    return {
      where: {
        search: this.search,
        ...(this.nursery && {
          nursery: {
            id: oc(this.nursery).id(),
          },
        }),
      },
    };
  }

  get reason() {
    return (reason) => BanReasonConstant[reason];
  }

  get getProfileLink() {
    return (id) => ({
      name: AuthModule.getters.isAdmin
        ? routesNames.admin.INSCRITS.DETAIL
        : routesNames.directeur.INSCRITS.DETAIL,
      params: { customerId: id },
    });
  }
}
