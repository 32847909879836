import { render, staticRenderFns } from "./OneInscritBillings.vue?vue&type=template&id=019c8c86&scoped=true&"
import script from "./OneInscritBillings.vue?vue&type=script&lang=ts&"
export * from "./OneInscritBillings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019c8c86",
  null
  
)

export default component.exports