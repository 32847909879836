import { Line, mixins } from 'vue-chartjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Colors from '@colors';
@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class Inscriptions extends Vue {
  @Prop() inscriptions: any[];
  @Prop() reservations: any[];
  renderChart;
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: ['Moyenne', 'M-1', 'M-2', 'M-3', 'M-4', 'M-5', 'M-6', 'Mois en cours'],
        datasets: [
          {
            label: 'Inscriptions',
            backgroundColor: 'transparent',
            borderColor: Colors.yellow,
            data: this.inscriptions,
          },
          {
            label: 'Réservations',
            backgroundColor: 'transparent',
            borderColor: Colors.blue2,
            data: this.reservations,
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
}
