






















import { Component, Prop, Watch } from 'vue-property-decorator';
import shortid from 'shortid';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';
import { Forms } from '../../../constructors';

@Component({
  components: {
    FormError,
  },
})
export default class RichCheckBoxList extends FormMixin {
  data: Forms.FormPayload<any> & Forms.IRichCheckBoxListPayload<any>;
  value: string[];

  get formatedValues() {
    return [...new Set(this.value)];
  }

  handleValue(value) {
    if (this.value instanceof Array) {
      if (this.value.includes(value)) {
        const newValue = this.value.filter((v) => v !== value);
        this.updateValue(newValue);
      } else {
        const newValue = this.value;
        newValue.push(value);
        this.updateValue([...newValue]);
      }
    }
  }
}
