































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RadioTabs, InfiniScrollMixin } from '@components';
import { range } from 'lodash';
import {
  INursery,
  IReservationConnection,
  IreservationsConnectionArgs,
  IReservation,
  ICustomer,
  IReservationStatus,
} from '@models';
import { differenceInMonths, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { registrationStatusConstant, reservationTypeConstant } from '@constants';
import { getNurseryReservations, AuthModule } from '@store';
import { routesNames } from '@router';

@Component({
  components: {
    RadioTabs,
  },
})
export default class OneInscritHistory extends InfiniScrollMixin<
  IReservationConnection,
  IreservationsConnectionArgs
> {
  @Prop() customer: ICustomer;
  @Prop() redirect: string;
  @Prop({ type: null }) status: IReservationStatus;

  public columns = ['Date', 'Prix'];

  public reservations: IReservation[] = [];

  public listHandler = getNurseryReservations;

  get handlerParams(): IreservationsConnectionArgs {
    return {
      where: {
        customer: {
          id: this.customer.id,
        },
        status: this.status,
        noRegistration: false,
      },
    };
  }

  get redirectRoute() {
    return (id: string) => ({
      name: this.redirect,
      params: {
        demandeId: id,
      },
      query: {
        type: 'reservation',
      },
    });
  }

  newContent(data: IReservation[]) {
    this.reservations = data;
  }

  get reservationsFiltered() {
    return this.reservations.map((m) => ({
      ...m,
      date: `${format(m.date, 'DD MMM YY', { locale: frLocale })} ${
        reservationTypeConstant[m.type]
      }`,
    }));
  }
}
