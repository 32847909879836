
import { Component, Vue, Prop } from 'vue-property-decorator';
import Observer from './Observer.vue';
import { VNode } from 'vue';
import Spinner from './Spinner.vue';

const tObserver: any = Observer;
const tSpinner: any = Spinner;

@Component({
  components: {
    Observer,
  },
})
export default class LiquidGrid extends Vue {
  @Prop() observer: boolean;
  public columns = 3;

  $refs: {
    liquidGrid: HTMLElement;
  };

  async checkChildWidth() {
    if (this.$slots.default && this.$refs.liquidGrid) {
      await this.$nextTick();
      const childWidth = this.$refs.liquidGrid?.firstElementChild?.clientWidth;
      if (childWidth && childWidth > 0) {
        const windowWidth = this.$refs.liquidGrid.clientWidth;
        this.columns = Math.floor(windowWidth / childWidth);
      }
    }
  }

  visible() {
    this.$emit('visible');
  }

  mounted() {
    this.checkChildWidth();
    window.addEventListener('resize', this.checkChildWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.checkChildWidth);
  }

  // render(h) {
  //   return (
  //     <div class="liquid-grid flex" ref="liquidGrid">
  //       {[...Array(this.columns).keys()].map(column => (
  //         <div class="column flex">
  //           {this.$slots.default &&
  //             this.$slots.default.filter((v, index) => index % this.columns === column)}
  //           {this.observer && (
  //             <tObserver key={column} onVisible={this.visible}>
  //               <tSpinner color="blue" />
  //             </tObserver>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }
}
