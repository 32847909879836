var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"planning-container flex column w100",class:[_vm.size]},[_c('div',{staticClass:"planning-header flex nowrap hcenter"},[_c('div',{staticClass:"previous buttons flex hcenter"},[(_vm.size === 'fullscreen')?_c('BackLink',{on:{"click":_vm.handleSubMonth}},[_vm._v(_vm._s(_vm.previousMonthDisplay))]):_c('button',{staticClass:"change-month flex center"},[_c('SvgIcon',{key:"left",attrs:{"pointer":"","src":"icons/Forms/arrow_left.svg","size":26},on:{"click":_vm.handleSubMonth}})],1)],1),_c('div',{staticClass:"month flex center grow"},[_vm._v(_vm._s(_vm.currentMonthDisplay)+" "+_vm._s(_vm.selectedYear))]),_c('div',{staticClass:"next buttons flex hcenter"},[(_vm.canGoNextMonth)?[(_vm.size === 'fullscreen')?_c('BackLink',{attrs:{"icon-right":""},on:{"click":_vm.handleAddMonth}},[_vm._v(_vm._s(_vm.nextMonthDisplay))]):_c('button',{staticClass:"change-month flex center"},[_c('SvgIcon',{key:"right",attrs:{"src":"icons/Forms/arrow_right.svg","pointer":"","size":26},on:{"click":_vm.handleAddMonth}})],1)]:_vm._e()],2)]),_c('div',{staticClass:"week-days grid"},_vm._l((_vm.weekDaysDisplay),function(day,index){return _c('div',{key:index,staticClass:"week-day flex center"},[_c('span',[_vm._v(_vm._s(day))])])}),0),(!_vm.isCommunityNursery || _vm.isAdmin)?_c('div',{staticClass:"planning-grid grid"},[(_vm.loading)?_c('div',{staticClass:"flex loading-overlay center"},[_c('Spinner',{attrs:{"size":40}})],1):_vm._e(),_vm._l((_vm.datesAndReservations),function(ref){
      var date = ref.date;
      var reservations = ref.reservations;
      var registrations = ref.registrations;
      var availabilities = ref.availabilities;
      var welcomings = ref.welcomings;
return _c('PlanningItem',{key:date.toString(),attrs:{"date":date,"size":_vm.size,"reservations":reservations,"registrations":registrations,"availabilities":availabilities,"welcomings":welcomings,"current-month":_vm.currentMonth,"closing-dates":_vm.closingDates}})})],2):_c('div',{staticClass:"planning-grid grid"},[(_vm.loading)?_c('div',{staticClass:"flex loading-overlay center"},[_c('Spinner',{attrs:{"size":40}})],1):_vm._e(),_vm._l((_vm.datesAndReservations),function(ref){
      var date = ref.date;
      var reservations = ref.reservations;
      var registrations = ref.registrations;
      var availabilities = ref.availabilities;
      var welcomings = ref.welcomings;
      var communityNurseryAvailabilities = ref.communityNurseryAvailabilities;
return _c('PlanningItemCommunity',{key:date.toString(),attrs:{"date":date,"size":_vm.size,"reservations":reservations,"registrations":registrations,"availabilities":availabilities,"welcomings":welcomings,"community-nursery-availabilities":communityNurseryAvailabilities,"current-month":_vm.currentMonth,"closing-dates":_vm.closingDates}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }