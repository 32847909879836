import { IeffortRatesArgs } from './../../models/__generated';
import gql from 'graphql-tag';
import { sgts } from '@services';

const effortRateFragment = gql`
  fragment effortRate on EffortRate {
    numberOfChildren
    rate1
    rate2
    rate3
  }
`;
export const getAllEffortRatesQuery = async (data: IeffortRatesArgs) => {
  return sgts.effortRates().$fragment(effortRateFragment).$args(data).$fetch();
};
