import { helpers } from 'vuelidate/lib/validators';
import { isAfter, isBefore } from 'date-fns';
export namespace Validators {
  const Regs = {
    link: /(https?|ftp):\/\/(-\.)?([^\s/?.#-]+\.?)+(\/[^\s]*)?$@iS/,
  };

  export const LinkValidator = (value, component) => {
    return Regs.link.test(value);
  };
}

export const latitude = (lat) => isFinite(lat) && Math.abs(lat) <= 90;
export const longitude = (long) => isFinite(long) && Math.abs(long) <= 180;

var zipCodeRegex = /^(\d{5})?$/;
export const zipCode = (zipCode: string) => zipCodeRegex.test(zipCode ?? '');

const hourRegex = /^([01]?[0-9]|2[0-3]){1}:([0-5][0-9]{1}){1}$/;

export interface IErrorValidators {
  latitude: string;
  longitude: string;
  zipCode: string;
  required: string;
  email: string;
  minLength: ((params: any) => string) | string;
  maxLength: ((params: any) => string) | string;
  minValue: ((params: any) => string) | string;
  maxValue: ((params: any) => string) | string;
  minSize: ((params: any) => string) | string;
  maxSize: ((params: any) => string) | string;
  phone: string;
  sameAs: string;
  isMailUnique: string;
  isNameUnique: string;
  numeric: string;
  isCguChecked: string;
  date: string;
  minTagSize: string;
  isDateAfter: string;
  isDateAfterToday: string;
  isDateBeforeToday: string;
  userExists: string;
  iban: string;
  minSessions: string;
  emailPnb: string;
  exactLength: ((params: any) => string) | string;
  positiveNumber: string;
  [x: string]: any;
}

const defaultMessagesList = (params: { [K in keyof IErrorValidators]: any }): IErrorValidators => {
  return {
    latitude: 'Mauvais format, voici un exemple complet : 47.98992 ; 1.215599 ',
    longitude: 'Mauvais format, voici un exemple complet : 47.98992 ; 1.215599 ',
    zipCode: 'Mauvais format, voici un exemple complet : 75015 ; 92064 ',
    required: 'Ce champs est requis',
    email: "L'adresse mail doit être valide",
    minLength: `${params.minLength ? params.minLength.min : ''} caractères minimum`,
    maxLength: `${params.maxLength ? params.maxLength.max : ''} caractères maximum`,
    minValue: `La valeur doit être supérieur à ${params.minValue ? params.minValue.min : ''}`,
    maxValue: `La valeur doit être inférieure à ${params.maxValue ? params.maxValue.max : ''}`,
    sameAs: 'Les mots de passe doivent être identiques',
    isMailUnique: 'Cet email est déjà utilisé',
    isNameUnique: 'Ce nom est déjà utilisé',
    phone: 'Le numéro de téléphone doit être valide',
    numeric: 'Ce champs doit être un nombre entier',
    decimal: 'Ce champs doit être un nombre décimal',
    price: 'Ce champs doit être un prix valide',
    minPrice: `Le prix doit être de minimum${params.minPrice?.value} €`,
    isCguChecked: 'Les CGU doivent être acceptées',
    date: 'La date doit être valide',
    minTagSize: 'Il faut au moins un tag',
    isDateAfter: 'La date de fin doit être supérieure à la date de début',
    isDateBefore: 'La date de fin doit être inférieure à la date de fin',
    isDateAfterToday: 'La date ne peut pas être dans le passé',
    isDateBeforeToday: 'La date ne peut pas être dans le futur',
    time: `L'heure doit être valide`,
    isTimeAfter: "L'heure de fin doit être supérieure à l'heure de début",
    userExists: "Ce client n'éxiste pas",
    iban: 'Veuillez préciser un iban valide',
    minSize: `${params.minValue?.min} éléments minimum`,
    maxSize: `${params.maxValue?.max} éléments maximum`,
    minSessions: 'Vous devez mettre au moins une session',
    positiveNumber: 'Le nombre doit être supérieur à 0',
    emailPnb: 'Veuillez saisir une adresse email @people-and-baby.com',
    exactLength: `Doit être composé d'exactement ${params.exactLength?.value} caractères`,
  };
};

export const errorMessagesFormater = (vl, messages) => {
  if (messages) {
    const customMessages = Object.keys(messages);
    const defaultMessages = defaultMessagesList(vl.$params);
    console.log(customMessages, defaultMessages);
    customMessages.forEach((m) => {
      if (Object.keys(defaultMessages).includes(m)) {
        if (typeof messages[m] == 'function') {
          defaultMessages[m] = messages[m](vl.$params);
        } else {
          defaultMessages[m] = messages[m];
        }
      } else {
        defaultMessages[m] = messages[m];
      }
    });
    return defaultMessages;
  } else {
    return defaultMessagesList(vl.$params);
  }
};

export const positiveNumber = (value: number) => {
  return !helpers.req(value) || !Number(value) || value > 0;
};

export const emailPnb = (value: string) => {
  if (helpers.req(value)) {
    const regex = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(people-and-baby.com)$/;
    return regex.test(value);
  }
  return true;
};

export const phoneValidator = (value: string) => {
  if (helpers.req(value)) {
    const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    return regex.test(value);
  }
  return true;
};

export const priceValidator = (value: string) => {
  if (helpers.req(value)) {
    const regex = /^(\d*([,](?=\d))?\d+)+((?!\2)[.,]\d\d)?$/g;
    return regex.test(value);
  }
  return true;
};

export const minPrice = (param: number) => {
  return helpers.withParams({ type: 'minPrice', value: param }, (value, parentVm) => {
    const price = Number(value.toString().replace(',', '.'));
    return !helpers.req(value) || (!isNaN(price) && price >= param);
  });
};

export const exactLength = (param: number) => {
  return helpers.withParams({ type: 'exactLength', value: param }, (value, parentVm) => {
    return !helpers.req(value) || value.toString().length === param;
  });
};

export const minSize = (param: number) => {
  return helpers.withParams({ type: 'minSize', value: param }, (value, parentVm) => {
    return !helpers.req(value) || (value instanceof Array && value.length >= param);
  });
};

export const maxSize = (param: number) => {
  return helpers.withParams({ type: 'maxSize', value: param }, (value, parentVm) => {
    return !helpers.req(value) || (value instanceof Array && value.length <= param);
  });
};

export const isDateAfter = (param: string | ((values?) => any)) => {
  return helpers.withParams({ type: 'isDateAfter' }, (value, parentVm) => {
    if (typeof param === 'string') {
      return !helpers.req(value) || isAfter(value, helpers.ref(param, this, parentVm));
    } else {
      return (
        !helpers.req(value) ||
        isAfter(
          value,
          helpers.ref(
            (vm, parentVm: any) => {
              return param(vm);
            },
            this,
            parentVm
          )
        )
      );
    }
  });
};

export const isDateBefore = (param: string) => {
  return helpers.withParams({ type: 'isDateBefore' }, (value, parentVm) => {
    return !helpers.req(value) || isBefore(value, helpers.ref(param, this, parentVm));
  });
};
export const isTimeAfter = (param) => {
  return helpers.withParams({ type: 'isTimeAfter' }, (value, parentVm) => {
    return (
      !helpers.req(value) ||
      isAfter(
        new Date(`2018-01-01T${value}:00`),
        new Date(`2018-01-01T${helpers.ref(param, this, parentVm)}:00`)
      )
    );
  });
};

export const isDateAfterToday = (param) => {
  return isAfter(param, new Date());
};

export const isDateBeforeToday = (param) => {
  return isBefore(param, new Date());
};

export const dateValidator = (value) => {
  if (helpers.req(value)) {
    return !!Date.parse(value);
  }
  return true;
};

export const timeValidator = (value) => {
  if (helpers.req(value)) {
    return hourRegex.test(value);
  }
  return true;
};

export const ibanValidator = (value) => {
  const ibanXP = /[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?[\d]{0,2}/gm;
  return ibanXP.test(value);
};
