




























import { Forms } from '@constructors';
import { IAnyObject, ValidationRoot } from '@models';
import shortid from 'shortid';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FormItem from './FormItem.vue';

@Component({
  components: { FormItem },
  validations() {
    const _this = this as FormContainer;
    const validations = _this.validations || _this.form.getValidations;
    if (validations) {
      return {
        form: {
          fieldsValues: validations,
        },
      } as any;
    }
    return {};
  },
})
export default class FormContainer extends Vue {
  @Prop({ required: true })
  form: Forms.Form<any>;
  @Prop({ default: 'column' }) flow: 'column' | 'row';
  @Prop({ default: shortid.generate() }) name: string;
  @Prop({ default: true }) fill: boolean;
  @Prop({ required: false }) validations?: Forms.ValidationTree<any>;

  get isSection() {
    return (item) => item instanceof Forms.SectionConstructor;
  }

  get isSectionUnwrap() {
    return (item) => this.isSection(item) && item.unwrap;
  }

  get fieldsData() {
    return this.form.fieldsData;
  }

  get fieldsValues() {
    return this.form.fieldsValues;
  }

  @Watch('fieldsValues', { deep: true })
  valuesChanged() {
    this.generateForm();
  }

  @Watch('$v', { deep: true, immediate: true })
  validatorUpdate(value: ValidationRoot<any>) {
    const newForm = this.form;
    newForm.$v = this.$v;
    this.$emit('update:form', newForm);
  }

  public cloneFormValues = {};

  generateForm() {
    const recursiveFormSetter = (
      fields: Forms.FormStructure<any>,
      formValues: IAnyObject,
      outObject: IAnyObject
    ) => {
      Object.keys(fields).forEach((fieldKey) => {
        if (fields[fieldKey] instanceof Forms.DefaultFormElement) {
          Object.defineProperty(outObject, fieldKey, {
            get: () => {
              return formValues[fieldKey];
            },
            set: (value) => {
              this.$set(formValues, fieldKey, value);
              this.$emit('update:form', this.form);
            },
          });
        } else if (fields[fieldKey] instanceof Forms.SectionConstructor) {
          if (fields[fieldKey].unwrap) {
            recursiveFormSetter(fields[fieldKey], formValues, outObject);
          } else {
            if (typeof fields[fieldKey] === 'object') {
              outObject[fieldKey] = {};
              recursiveFormSetter(fields[fieldKey], formValues[fieldKey], outObject[fieldKey]);
            }
          }
        } else if (typeof fields[fieldKey] === 'object') {
          outObject[fieldKey] = {};
          recursiveFormSetter(fields[fieldKey], formValues[fieldKey], outObject[fieldKey]);
        }
      });
    };
    this.cloneFormValues = {};
    recursiveFormSetter(this.form.fields, this.form.fieldsValues, this.cloneFormValues);
  }

  created() {
    this.generateForm();
  }
}
