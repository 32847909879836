












































import { InfiniScrollMixin, RadioTabs } from '@components';
import {
  IRegistration,
  IRegistrationsConnection,
  IregistrationsConnectionArgs,
  IreservationsConnectionArgs,
} from '@models';
import { routesNames } from '@router';
import { getNurseryRegistrations, NurseryModule } from '@store';
import { differenceInMonths, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  components: {
    RadioTabs,
  },
})
export default class CrecheInscrits extends InfiniScrollMixin<
  IRegistrationsConnection,
  IregistrationsConnectionArgs
> {
  public columns = [
    'Parent',
    'Enfant',
    'Dernière réservation',
    "Date d'inscription",
    'Nombre de réservations',
    '',
  ];
  public routesNames = routesNames;
  public registrations: IRegistration[] = [];

  public listHandler = getNurseryRegistrations;

  get nursery() {
    return NurseryModule.state.oneNursery;
  }

  get handlerParams(): IregistrationsConnectionArgs {
    return {
      where: {
        nursery: {
          id: this.nursery.id,
        },
        status: 'DONE',
      },
    };
  }

  newContent(data: IRegistration[]) {
    this.registrations = data;
  }

  @Watch('params')
  paramsChanged(value: IreservationsConnectionArgs) {
    this.newSearch();
  }

  get registrationsFiltered() {
    return this.registrations.map((m) => ({
      ...m,
      parent: m.child.parent,
      documents: [...m.child.parent.documents, ...m.child.documents],
      child: `${m.child.firstName}, ${differenceInMonths(new Date(), m.child.birthDate)} mois`,
      reservationCount: m.child.parent.reservations.filter((f) => f.status === 'DONE').length,
      ...(m.child.parent.reservations.length && {
        last: format(
          m.child.parent.reservations[m.child.parent.reservations.length - 1].date,
          'DD MMM YYYY',
          { locale: frLocale }
        ),
      }),
      signup: format(m.registrationDate, 'DD MMM YYYY', { locale: frLocale }),
    }));
  }
}
