import { defineModule } from '@/vuex-typed-modules';
import { INotification, ILoaderNotification } from '@models';

import { timeout } from '@methods';
import shortid from 'shortid';

const TIMEOUT: number = 4000;
export interface INotificationState {
  notificationCount: number;
  notificationList: INotification[];
}
//State
const state: INotificationState = {
  notificationCount: 0,
  notificationList: [],
};

// Mutations
const mutations = {
  addNotification(state: INotificationState, alert: INotification) {
    state.notificationList.push(alert);
    state.notificationCount++;
  },
  deleteNotification(state: INotificationState, alert: INotification) {
    var index = state.notificationList.findIndex((element) => element.id === alert.id);
    if (index !== -1) {
      state.notificationList.splice(index, 1);
    }
  },
};

// Actions
const actions = {
  async addNotification(context, { action, ...notif }: INotification) {
    const id = shortid.generate();
    let _notif: INotification = {
      ...notif,
      id,
      isNotif: notif.isNotif || false,
      link: notif.link,
    };
    if (notif.type === 'action') {
      _notif.action = {
        async trigger() {
          await action.trigger();
          NotificationsModule.mutations.deleteNotification(_notif);
        },
        actionMessage: action.actionMessage,
      };
    }
    NotificationsModule.mutations.addNotification(_notif);
    if (notif.type !== 'action') {
      await timeout(TIMEOUT);
      NotificationsModule.mutations.deleteNotification(_notif);
    }
  },
  async addLoader(
    context,
    { errorMessage, successMessage, message, handler }: ILoaderNotification
  ) {
    const id = shortid.generate();
    const _alert: INotification = {
      ...alert,
      id,
      message,
      type: 'loader',
      loading: false,
    };
    NotificationsModule.mutations.addNotification(_alert);
    if (handler) {
      try {
        NotificationsModule.updateListItem('notificationList', id, {
          loading: true,
        });
        const result = await handler();
        NotificationsModule.updateListItem('notificationList', id, {
          type: 'success',
          message: successMessage,
        });
        return result;
      } catch (e) {
        NotificationsModule.updateListItem('notificationList', id, {
          type: 'error',
          message: errorMessage,
        });
      } finally {
        NotificationsModule.updateListItem('notificationList', id, {
          loading: false,
        });
        await timeout(TIMEOUT);
        NotificationsModule.mutations.deleteNotification(_alert);
      }
    }
  },
};

// Module declaration
export const NotificationsModule = defineModule('NotificationsModule', state, {
  actions,
  mutations,
});
