

















import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';

@Component({
  mixins: [FormMixin],
  components: { FormError },
})
export default class CheckBox extends FormMixin {}
