import Vue from 'vue';
import VueRouter, { RawLocation } from 'vue-router';
import { routesList, routesNames } from './routes';
import { MyRoute, routeTitle, MyRouteRecord } from './routes.models';
import { EventBus, Events, analytics } from '@services';
import { AuthModule, ProgressBarModule, AlertsModule } from '../store';
import { WarningNotification, ErrorNotification } from '@constructors';
import { routerLogger } from './router.logger';
import { merge } from 'lodash';

Vue.use(VueRouter);
const SITE_NAME = process.env.VUE_APP_NAME;

let cachedPageTitle = '';

const Router = new VueRouter({
  mode: 'history',
  fallback: false,
  async scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: routesList,
});

Router.beforeEach(async (to: MyRoute, from: MyRoute, next) => {
  try {
    analytics.startTrackPage(to.fullPath);
    if (!AuthModule.state.authChecked) {
      await AuthModule.actions.checkUserSession();
    }
    if (!from.matched.length && AuthModule.state.loggedIn) {
      ProgressBarModule.mutations.start('loader');
    } else {
      ProgressBarModule.mutations.start('progress');
    }

    routerLogger(to, from);
    // Check requiresAuth
    if (to.matched.some((m) => m.meta.requiresAuth)) {
      if (!AuthModule.state.loggedIn) {
        next({
          name: routesNames.CONNEXION,
          query: to.name ? { nextRoute: to.fullPath } : undefined,
        });
        return;
      }

      await getRouteData(to, from, next);
      if (to.matched.some((m) => m.meta.isAuthorized)) {
        if (
          to.matched
            .filter((match) => match.meta.isAuthorized)
            .every((match) => match.meta.isAuthorized())
        ) {
          next();
        } else {
          new WarningNotification(`Vous n'êtes pas autorisé à accéder à cette page`);
          if (from.fullPath === '/') {
            if (AuthModule.getters.isAdmin) {
              next({ name: routesNames.admin.HOME });
            } else if (AuthModule.getters.isDirector) {
              next({
                name: routesNames.directeur.HOME,
                params: { crecheId: AuthModule.state.userInfos?.nurseries?.[0]?.id },
              });
            } else {
              next({ name: routesNames.CONNEXION });
            }
          } else {
            next(false);
          }
          ProgressBarModule.mutations.fail();
          ProgressBarModule.mutations.hide();
          return;
        }
      }
      if (to.fullPath === '/') {
        if (AuthModule.getters.isAdmin) {
          next({ name: routesNames.admin.HOME });
        } else if (AuthModule.getters.isDirector) {
          next({
            name: routesNames.directeur.HOME,
            params: { crecheId: AuthModule.state.userInfos.nurseries[0].id },
          });
        } else {
          next({
            name: routesNames.CONNEXION,
            query: to.name ? { nextRoute: to.fullPath } : undefined,
          });
        }
      }
    } else if (to.matched.some((m) => m.meta.noAuth)) {
      AuthModule.actions.softDisconnect();
    } else {
      await getRouteData(to, from, next);
      next();
    }
    next();
  } catch (err) {
    ProgressBarModule.mutations.fail();
    ProgressBarModule.mutations.hide();
    console.log({ err });
    if (err.redirect) {
      next(err.redirect);
    }
    if (err.networkError) {
      console.log(err);
      var dataError = new ErrorNotification(
        'Erreur lors de la récupération des données de la page'
      );
      if (to.name !== routesNames.CONNEXION) {
        next({ name: routesNames.CONNEXION });
      } else {
        next();
      }
    } else if (err.message === 'Failed to fetch') {
      var serverError = new ErrorNotification('Impossible de communiquer avec le serveur');
    }
  }
});

Router.afterEach(async (to: MyRoute, from: MyRoute) => {
  analytics.stopTrackPage(to.fullPath);
  if (AlertsModule.state.showAlert) AlertsModule.actions.deleteAlert();
  EventBus.$emit(Events.CLOSE_POPUPS);
  ProgressBarModule.mutations.finish();
  ProgressBarModule.mutations.hide();
});

const getRouteData = async (to: MyRoute, from: MyRoute, next) => {
  let routesWithData: MyRouteRecord[];
  if (
    Object.keys(to.params).every((param) => {
      return to.params[param] === from.params[param];
    })
  ) {
    routesWithData = to.matched
      .filter((f) => !!f.meta.asyncData)
      .filter((f) => !from.matched.find((p) => p.path === f.path));
  } else {
    routesWithData = to.matched.filter((f) => !!f.meta.asyncData);
  }
  const results = await Promise.all(
    routesWithData.map((m) => {
      return m.meta.asyncData(to, from);
    })
  );
  routesWithData.forEach((f, index) => {
    if (typeof f.props['default'] === 'object' && results[index] instanceof Object) {
      Object.keys(results[index]).forEach((key) => (f.props['default'][key] = results[index][key]));
    }
  });
  if (!to.meta.title || !to.meta.subpage) {
    let pageTitle,
      subPage = {};
    if (!to.meta.title) {
      pageTitle = merge(
        {},
        ...[
          ...to.matched
            .filter((f) => {
              return !!f.meta && f.meta.title;
            })
            .map((m) => ({ page: m.meta.title })),
        ],
        ...[
          ...results.filter((f) => {
            return !!f && f.page;
          }),
        ]
      );
    } else {
      pageTitle = { page: to.meta.title };
    }
    if (!to.meta.subpage) {
      subPage = merge(
        {},
        ...to.matched.filter((f) => f.meta.subpage).map((m) => ({ subpage: m.meta.subpage })),
        ...[
          ...results.filter((f) => {
            return !!f && f.subpage;
          }),
        ]
      );
    } else {
      subPage = { subpage: to.meta.subpage };
    }

    makePageTitle({ ...pageTitle, ...subPage });
  } else {
    makePageTitle(to.meta.title);
  }
};

const makePageTitle = (data: string | routeTitle) => {
  if (typeof data === 'string') {
    document.title = `${data} | ${SITE_NAME} ${
      AuthModule.getters.isAdmin ? '(Admin)' : '(Directeur)'
    }`;
  } else if (typeof data === 'object') {
    const titleToDisplay = data.page || cachedPageTitle;
    if (data.page) cachedPageTitle = data.page;
    document.title = `${titleToDisplay} ${
      data.subpage != null ? `- ${data.subpage}` : ''
    } | ${SITE_NAME} ${AuthModule.getters.isAdmin ? '(Admin)' : '(Directeur)'}`;
  } else {
    document.title = SITE_NAME;
  }
};

export default Router;
