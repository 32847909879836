import gql from 'graphql-tag';
import { nurseryFragment } from './nursery.fragment';
import { userFragment } from './user.fragment';

export const loginUserFragment = gql`
  fragment loginUserFragment on User {
    id
    email
    firstName
    role
    nurseries {
      ...nurseryFragment
    }
  }
  ${nurseryFragment}
`;

export const authPayloadFragment = gql`
  fragment authpayload on AuthPayload {
    token
    user {
      ...loginUserFragment
    }
  }
  ${loginUserFragment}
`;
