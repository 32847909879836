














import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavLink, INursery } from '@models';
import { Tabs, BackLink } from '@components';
import { routesNames } from '@router';
import { Location } from 'vue-router';
import { NurseryModule } from '@store';

@Component({
  components: { Tabs, BackLink },
})
export default class OneCrecheRoot extends Vue {
  get nursery() {
    return NurseryModule.state.oneNursery;
  }

  private tabs: NavLink[] = [
    { label: 'Détails', link: { name: routesNames.admin.CRECHEDETAIL.DETAIL }, exact: true },
    { label: 'Planning', link: { name: routesNames.admin.CRECHEDETAIL.PLANNING } },
    { label: 'Demandes', link: { name: routesNames.admin.CRECHEDETAIL.DEMANDES.LIST } },
    { label: 'Inscrits', link: { name: routesNames.admin.CRECHEDETAIL.INSCRITS } },
  ];
  get crecheName() {
    return this.nursery.name;
  }
}
