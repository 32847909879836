





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { DirectorNurseryModule } from '@store';
import { getCommunityNurseryTemplateQuery } from '@graphql';
import {
  ICommunityNurseryTemplateAvailability,
  IUpdateCommunityNurseryTemplateAvailabilityInput,
} from '@models';
import { PlanningTemplateItemCommunity } from '@components';
import { SuccessNotification } from '@constructors';
import { updateCommunityNurseryTemplateAvailabilitiesMutation } from '@graphql';

@Component({
  components: { PlanningTemplateItemCommunity },
})
export default class TemplatePlanningDirecteur extends Vue {
  public loading = false;
  loadingSave: boolean = false;

  communityNurseryTemplates: ICommunityNurseryTemplateAvailability[] = [];

  days = [1, 2, 3, 4, 5, 6, 0];

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }

  async mounted() {
    await this.getCommunityNurseryTemplate();
  }

  async getCommunityNurseryTemplate() {
    try {
      const nurseryId = this.nursery.id;
      this.communityNurseryTemplates = await getCommunityNurseryTemplateQuery({
        nurseryId: nurseryId,
      });
    } finally {
      this.loading = false;
    }
  }

  async saveCommunityTemplateDispo() {
    this.loadingSave = true;
    await updateCommunityNurseryTemplateAvailabilitiesMutation({
      data: {
        data: this.communityNurseryTemplates.map(
          (x) => x as unknown as IUpdateCommunityNurseryTemplateAvailabilityInput
        ),
      },
    });
    this.loadingSave = false;
    const _ = new SuccessNotification('Disponibilités template sauvegardé');
  }
}
