

























































































































import { Forms } from '@constructors';
import { Component } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { FormMixin } from '../../Mixins/FormMixin';
import InfiniScroll from '../Display/InfiniScroll.vue';
import SearchField from '../Display/SearchField.vue';
import FormError from './FormError.vue';

@Component({
  components: {
    FormError,
    InfiniScroll,
    SearchField,
  },
})
export default class FormSelect extends FormMixin {
  data: Forms.FormPayload<any> & Forms.ISelectPayload;
  @Prop() width: number;

  public dynamicOptions: Forms.IOptions[] = this.data.allOption
    ? [
        {
          value: undefined,
          text: 'Tous',
        },
      ]
    : [];
  public page = 1;
  public hasNextPage = true;
  public loading = false;
  public filterSearch = '';
  public cachedDisplayValue = '';

  get isDynamic() {
    return !!this.data.handler;
  }
  get isSearch() {
    return !!this.data.search;
  }

  get getDisplayValue() {
    if (this.cachedDisplayValue) {
      return this.cachedDisplayValue;
    } else if (this.data.options) {
      return this.data.options.find((f) => f.value === this.value)?.text;
    } else {
      return this.dynamicOptions.find((f) => f.value === this.value)?.text;
    }
  }

  async handleSearch(value: string) {
    if (this.data.search) {
      try {
        this.loading = true;
        this.page = 2;
        this.dynamicOptions = [];
        const { edges, pageInfo } = await this.data.handler({
          page: 1,
          where: this.data.search(value),
          ...this.data.handlerParams,
        });
        this.hasNextPage = pageInfo.hasNextPage;
        this.newContent(edges.map((e) => e.node));
      } finally {
        this.loading = false;
      }
    }
  }

  async initList() {
    if (this.data.handler) {
      const { edges, pageInfo } = await this.data.handler({
        page: 1,
        ...this.data.handlerParams,
      });
      this.hasNextPage = pageInfo.hasNextPage;
      this.newContent(edges.map((e) => e.node));
    }
  }

  async beforeMount() {
    this.initList();
    if (this.data.displayValue) {
      this.cachedDisplayValue = this.data.displayValue;
    }
  }

  newContent(edges: any[]) {
    const formatedEdges = edges.map(this.data.formater);
    this.dynamicOptions = this.dynamicOptions.concat(formatedEdges);
  }

  @Watch('value') valueChanged() {
    if (this.value == null) {
      this.cachedDisplayValue = null;
    }
  }

  selectOption({ value, text }: Forms.IOptions) {
    if (value !== this.value) {
      if (this.dynamicOptions) {
        this.cachedDisplayValue = this.dynamicOptions.find((f) => f.value === value)?.text;
      }
      this.updateValue(value);
      this.$emit('update', { value, text });
      this.$refs.popup.closePopup();
    }
  }
}
