


















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import shortid from 'shortid';

@Component({})
export default class CheckBoxList extends Vue {
  @Prop({ required: true })
  items!: any;
  @Prop() value!: Array<any>;
  @Prop() maxSelected: number;
  @Prop() small!: boolean;

  get selectedItems() {
    return this.value;
  }

  set selectedItems(value) {
    this.$emit('input', value);
  }

  get isSelected() {
    return (item) => this.selectedItems.find((m) => m.id === item.id);
  }

  get isDisabled() {
    return (item) => !this.isSelected(item) && this.selectedItems.length >= this.maxSelected;
  }

  get formatedItems() {
    return this.items.map((m) => {
      m.formId = shortid.generate();
      return m;
    });
  }
}
