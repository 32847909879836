




import { Component, Vue, Prop } from 'vue-property-decorator';
import DemandesTemplate from '../../../../Shared/Demandes/DemandesTemplate.vue';
import { Forms } from '@constructors';
import { routesNames } from '@router';
import { NurseryModule, getNurseryReservations, getNurseryRegistrations } from '@store';

@Component({
  components: {
    DemandesTemplate,
  },
})
export default class CrechesDemandes extends Vue {
  public listHandlers = {
    reservations: getNurseryReservations,
    registrations: getNurseryRegistrations,
  };
  public redirect = routesNames.admin.CRECHEDETAIL.DEMANDES.DETAIL;
  get nursery() {
    return NurseryModule.state.oneNursery;
  }
}
