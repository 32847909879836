





































import { Vue, Component } from 'vue-property-decorator';
import { ProgressBarModule } from '@store';
import Spinner from './Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class ProgressBar extends Vue {
  get progressState() {
    return ProgressBarModule.state;
  }

  get type() {
    return ProgressBarModule.state.type;
  }
}
