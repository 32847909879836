
















































import { FormCalendar, FormSelectMultiple, InfiniScrollMixin, Radio } from '@components';
import { Forms } from '@constructors';
import { nurseryFragment } from '@fragments';
import { InewNurseriesArgs, INursery, INurseryConnection } from '@models';
import { sgts } from '@services';
import { formatDateExport } from '@utils';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Component } from 'vue-property-decorator';
import Disponibilities from './Disponibilities.vue';
import Reservations from './Reservations.vue';

@Component({
  components: { FormCalendar, FormSelectMultiple, Radio, Disponibilities, Reservations },
})
export default class ReportingActivity extends InfiniScrollMixin<
  INurseryConnection,
  InewNurseriesArgs
> {
  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };

  public listHandler = (args: InewNurseriesArgs) =>
    sgts.newNurseries().$fragment(nurseryFragment).$args(args).$fetch();

  get handlerParams(): InewNurseriesArgs {
    return {
      where: this.calendarNew.value,
      page: null,
      limit: 10,
    };
  }

  async getNewNurseries() {
    if (this.calendarNew.value.start && this.calendarNew.value.end) {
      this.nurseries = await this.newSearch();
    }
  }

  newContent(data) {
    this.nurseries = data;
  }

  async exportData() {
    const csvContent = await sgts.exportNewNurseries().$args(this.handlerParams).$fetch();

    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Nouvelles_crèches_eligibles_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  public columns = ['Code analytic', 'Nom crèche'];

  public nurseries: INursery[] = [];
  public loadingDispo = false;

  public calendarNew = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '260px',
  }).extract();
}
