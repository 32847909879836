




















































import { FormSelect, InfiniScrollMixin } from '@components';
import { InurseriesConnectionArgs, INurseryConnectionWithRevenues } from '@models';
import { routesNames } from '@router';
import { NurseryModule } from '@store';
import { Component } from 'vue-mixin-decorator';
import { Location } from 'vue-router';

@Component({
  components: { FormSelect },
})
export default class CrechesInactives extends InfiniScrollMixin<
  INurseryConnectionWithRevenues,
  InurseriesConnectionArgs
> {
  public module = NurseryModule;
  public columns = [
    'Nom de la crèche',
    'Demandes en attente',
    'Réservations en attente',
    'Demandes traitées',
    "Chiffre d'affaire",
    '',
  ];

  get nurseryLink(): (id) => Location {
    return (id) => ({
      name: routesNames.admin.CRECHEDETAIL.DETAIL,
      params: { id },
    });
  }

  public listHandler = this.module.actions.getNurseries;

  get handlerParams(): InurseriesConnectionArgs {
    return {
      where: {
        search: this.search,
        active: false,
      },
    };
  }

  get nurseriesList() {
    return NurseryModule.state.nurseries.map((m) => ({
      ...m,
      revenues: ~~m.revenues,
    }));
  }

  destroyed() {
    NurseryModule.updateState({
      nurseries: [],
    });
  }
}
