import { defineModule } from '@/vuex-typed-modules';
import { IMailUpdateInput, IMailWhereUniqueInput, ImailsConnectionArgs, IMail } from '@models';
import { mailsConnectionQuery, updateMailMutation } from '@graphql';
import { Alerts, ErrorNotification, SuccessNotification } from '@constructors';

interface IMailingState {
  emails: IMail[];
}

//State
const state: IMailingState = {
  emails: [],
};

const actions = {
  async getEmails(context, payload: ImailsConnectionArgs) {
    const { edges, pageInfo, aggregate } = await mailsConnectionQuery(payload);
    MailingModule.updateState({
      emails: edges.map((e) => e.node) || [],
    });
    return { edges, pageInfo, aggregate };
  },
  async updateEmail(
    context,
    { form, params }: Alerts.FormPayload<IMailUpdateInput, IMailWhereUniqueInput>
  ) {
    try {
      const email = await updateMailMutation({ data: form, where: params });
      MailingModule.updateListItem('emails', params.id, email);
      new SuccessNotification("Template d'email modifié!");
    } catch (e) {
      var doNotExist = new ErrorNotification("Erreur lors de la modification de l'email");
    }
  },
};

export const MailingModule = defineModule('MailingModule', state, {
  actions,
});
