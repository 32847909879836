













import { Component, Vue, Prop } from 'vue-property-decorator';
import { IMail } from '@models';
import { FormContainer } from '../Forms';
import { Forms, Alerts } from '@constructors';
import { required, maxLength } from 'vuelidate/lib/validators';
import { MailingModule } from '@store';

@Component({
  components: { FormContainer },
})
export default class Email extends Vue {
  @Prop() email: IMail;

  public editing = false;

  get title() {
    return this.email.name;
  }

  get object() {
    return this.email.object;
  }

  get template() {
    return this.email.content;
  }

  editTemplate() {
    var edit = new Alerts.FormAlert({
      title: "Éditer un template d'email",
      width: '500px',
      formElement: {
        form: new Forms.Form({
          fields: {
            name: new Forms.TextForm({
              label: 'Titre du mail',
              value: this.title,
            }),
            object: new Forms.TextForm({
              label: 'Objet du mail',
              value: this.object,
            }),
            content: new Forms.RichText({
              label: 'Template du mail',
              value: this.template,
              emailVariables: this.email.variables,
            }),
          },
          validations: {
            name: { required, maxLength: maxLength(140) },
            object: { required, maxLength: maxLength(140) },
            content: { required },
          },
          editMode: true,
        }),
        submit: {
          params: {
            id: this.email.id,
          },
          handler: MailingModule.actions.updateEmail,
        },
      },
    });
  }
}
