


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Planning } from '@components';
import {
  INursery,
  IAvailability,
  IPlanningBounds,
  IReservation,
  IRegistration,
  IWelcoming,
  IWelcomingExtended,
  ICommunityNurseryAvailability,
  IUpdateCommunityNurseryAvailabilityInput,
} from '@models';
import { AuthModule, DirectorNurseryModule, NurseryModule } from '@store';
import { nurseryPlanningQuery } from '@graphql';
import { sgts } from '@services';
import { SuccessNotification } from '@constructors';
import { updateCommunityNurseryAvailabilitiesMutation } from '@graphql';

@Component({
  components: { Planning },
})
export default class PlanningTemplate extends Vue {
  @Prop() nursery: INursery;

  public reservations: IReservation[] = [];
  public registrations: IRegistration[] = [];
  public availabilities: IAvailability[] = [];
  public welcomings: IWelcomingExtended[] = [];
  public communityNurseryAvailabilities: ICommunityNurseryAvailability[] = [];
  public loading = false;
  public slot: IPlanningBounds = { start: null, end: null, saveCommunityData: false };

  loadingSave: boolean = false;

  get isCommunityNursery() {
    if (AuthModule.getters.isAdmin) return false;
    else return DirectorNurseryModule?.state?.nursery?.communityNursery === true;
  }

  async getPlanningSlot(slot: IPlanningBounds) {
    if (slot.saveCommunityData) {
      await this.saveCommunityDispo();
    }
    try {
      this.slot = slot;
      this.loading = true;
      const {
        reservations,
        availabilities,
        registrations,
        welcomings,
        communityNurseryAvailabilities,
      } = await nurseryPlanningQuery({
        id: this.nursery.id,
        start: slot.start,
        end: slot.end,
      });
      this.reservations = reservations;
      this.registrations = registrations;
      this.availabilities = availabilities;
      this.welcomings = welcomings;
      this.communityNurseryAvailabilities = communityNurseryAvailabilities;
    } finally {
      this.loading = false;
    }
  }

  async saveCommunityDispo() {
    if (this.communityNurseryAvailabilities && this.communityNurseryAvailabilities.length > 0) {
      this.loadingSave = true;
      await updateCommunityNurseryAvailabilitiesMutation({
        data: {
          data: this.communityNurseryAvailabilities.map(
            (x) => x as unknown as IUpdateCommunityNurseryAvailabilityInput
          ),
        },
      });
      this.loadingSave = false;
      const _ = new SuccessNotification('Disponibilités sauvegardé');
    }
  }
}
