var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-item flex center",class:[
    _vm.type,
    {
      isInCurrentMonth: _vm.isInCurrentMonth,
      isToday: _vm.isToday,
      isSelected: _vm.isSelected,
      isBetween: _vm.isBetween,
      isStart: _vm.isStart,
      isEnd: _vm.isEnd,
      isPeriodCompleted: _vm.isPeriodCompleted,
      isStartOfWeek: _vm.isStartOfWeek,
      isEndOfWeek: _vm.isEndOfWeek,
    } ],on:{"click":_vm.handleSelectEvent,"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_c('div',{staticClass:"date-wrapper flex center"},[_c('span',[_vm._v(_vm._s(_vm.formattedDate))])])])}
var staticRenderFns = []

export { render, staticRenderFns }