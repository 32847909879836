









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ButtonExtension extends Vue {
  @Prop() active: boolean;
  @Prop() icon: string;

  get srcIcon() {
    return `icons/richtext/${this.icon}.svg`;
  }
}
