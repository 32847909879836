import { MyRouteConfig, MyRoute } from './routes.models';
import {
  AuthModule,
  NurseryModule,
  DirectorNurseryModule,
  CustomersModule,
  DirectorCustomersModule,
} from '@store';
import { Router } from '@router';
import { WarningNotification } from '@constructors';
import { getReservationQuery, getRegistrationQuery } from '@graphql';
import { sgts } from '@services';
import { childValidationFragment } from '@fragments';

// Liste d'identifiants des routes
export const routesNames = {
  admin: {
    HOME: 'admin/home',
    CRECHES: 'admin/creches',
    CRECHES_INACTIVES: 'admin/crechesinactives',
    CRECHEDETAIL: {
      DETAIL: 'admin/creches/detail',
      PLANNING: 'admin/creches/planning',
      DEMANDES: {
        LIST: 'admin/creches/demandes/list',
        DETAIL: 'admin/creches/demandes/detail',
      },
      INSCRITS: 'admin/creches/inscrits',
    },
    INSCRITS: {
      LIST: 'admin/inscrits',
      PENDING: 'admin/inscrits/pending',
      VALIDATION: 'admin/inscrits/validation',
      BANNED: 'admin/inscrits/banned',
      DETAIL: 'admin/inscrits/detail',
    },
    DEMANDES: 'admin/demande/detail',
    GERANTS: 'admin/gerants',
    MAILINGS: 'admin/mailings',
    REPORTING: {
      HOME: 'admin/reporting',
      ACTIVITY: 'admin/reporting/activity',
      ANALYTICS: 'admin/reporting/analytics',
    },
  },
  directeur: {
    HOME: 'directeur/home',
    PLANNING: 'directeur/planning',
    CRECHEDETAIL: 'directeur/detail',
    DASHBORD: 'directeur/dashbord',
    TEMPLATE_PLANNING: 'directeur/template-planning',
    DEMANDES: {
      LIST: 'directeur/demandes/list',
      DETAIL: 'directeur/demandes/detail',
    },
    INSCRITS: {
      LIST: 'directeur/inscrits/list',
      DETAIL: 'directeur/inscrits/detail',
      BANNED: 'directeur/inscrits/banned',
    },
    PAIEMENTS: 'directeur/paiments',
  },
  CONNEXION: 'connexion',
  FORGOT_PASSWORD: 'forgot-password',
  ACTIVATE_USER: 'activate-user',
  RESET_PASSWORD: 'reset-password',
  CRON: 'execute-cron',
};

function loadView(view: string) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@views/${view}.vue`);
}

// Liste des routes
export const routesList: MyRouteConfig[] = [
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    component: loadView('BaseApp'),
    children: [
      {
        path: 'admin',
        component: loadView('Pages/Admin/Admin'),
        meta: {
          isAuthorized(to) {
            return AuthModule.getters.isAdmin;
          },
        },
        children: [
          {
            path: '',
            name: routesNames.admin.HOME,
            meta: {
              title: 'Accueil',
            },
            component: loadView('Pages/Admin/HomeAdmin'),
          },
          {
            path: 'creches',
            name: routesNames.admin.CRECHES,
            meta: {
              title: 'Crèches',
            },
            component: loadView('Pages/Admin/Creches/CrechesList'),
          },
          {
            path: 'creches-inactives',
            name: routesNames.admin.CRECHES_INACTIVES,
            meta: {
              title: 'Crèches inactives',
            },
            component: loadView('Pages/Admin/Creches/CrechesInactives'),
          },
          {
            path: 'creches/:id',
            component: loadView('Pages/Admin/Creches/OneCreche/OneCrecheRoot'),
            meta: {
              async asyncData(to: MyRoute) {
                try {
                  const nursery = await NurseryModule.actions.getOneNursery(to.params.id);
                  return { page: nursery.name };
                } catch (e) {
                  Router.push({ name: routesNames.admin.CRECHES });
                  var doNotExist = new WarningNotification("Cette crèche n'éxiste pas");
                }
              },
            },
            children: [
              {
                path: '',
                name: routesNames.admin.CRECHEDETAIL.DETAIL,
                component: loadView('Pages/Admin/Creches/OneCreche/CrecheDetail/CrecheDetailRoot'),
              },
              {
                path: 'planning',
                name: routesNames.admin.CRECHEDETAIL.PLANNING,
                component: loadView('Pages/Admin/Creches/OneCreche/CrechePlanning'),
                meta: {
                  subpage: 'Planning',
                },
              },
              {
                path: 'demandes',
                name: routesNames.admin.CRECHEDETAIL.DEMANDES.LIST,
                component: loadView(
                  'Pages/Admin/Creches/OneCreche/CrecheDemandes/CrecheDemandesList'
                ),
                meta: {
                  subpage: 'Demandes',
                },
              },
              {
                path: 'demandes/:demandeId',
                name: routesNames.admin.CRECHEDETAIL.DEMANDES.DETAIL,
                component: loadView(
                  'Pages/Admin/Creches/OneCreche/CrecheDemandes/CrecheDemandeDetail'
                ),
                meta: {
                  async asyncData(to: MyRoute, from: MyRoute) {
                    try {
                      const { demandeId } = to.params;
                      console.log(to.query);
                      if (to.query.type === 'reservation') {
                        const reservation = await getReservationQuery(demandeId);
                        return { demande: reservation };
                      } else if (to.query.type === 'registration') {
                        const registration = await getRegistrationQuery({ id: demandeId });
                        return {
                          demande: registration.reservation,
                          registration,
                        };
                      } else {
                        return;
                      }
                    } catch (e) {
                      var doNotExist = new WarningNotification("Cette demande n'éxiste pas");
                      if (from.name === routesNames.admin.CRECHEDETAIL.DEMANDES.LIST) {
                        return Promise.reject({ redirect: false });
                      } else {
                        return Promise.reject({
                          redirect: { name: routesNames.admin.CRECHEDETAIL.DEMANDES.LIST },
                        });
                      }
                    }
                  },
                  subpage: 'Detail demande',
                },
                props: {
                  demande: true,
                  registration: true,
                },
              },
              {
                path: 'inscrits',
                name: routesNames.admin.CRECHEDETAIL.INSCRITS,
                component: loadView('Pages/Admin/Creches/OneCreche/CrecheInscrits'),
                meta: {
                  subpage: 'Inscrits',
                },
              },
            ],
          },
          {
            path: 'inscrits',
            component: loadView('Pages/Admin/Inscrits/InscritsRoot'),
            children: [
              {
                path: '',
                meta: {
                  title: 'Inscrits',
                },
                name: routesNames.admin.INSCRITS.LIST,
                component: loadView('Pages/Admin/Inscrits/InscritsList'),
              },
              {
                path: 'pending',
                meta: {
                  title: 'Dossiers en attente',
                },
                name: routesNames.admin.INSCRITS.PENDING,
                component: loadView('Pages/Admin/Inscrits/InscritsPending'),
              },
            ],
          },
          {
            path: 'inscrits/validation/:childId',
            name: routesNames.admin.INSCRITS.VALIDATION,
            meta: {
              title: "Validation d'inscrit",
              async asyncData(to: MyRoute, from: MyRoute) {
                try {
                  const { childId } = to.params;
                  const child = await sgts
                    .child()
                    .$fragment(childValidationFragment)
                    .$args({ where: { id: childId } })
                    .$fetch();

                  return { child };
                } catch (e) {
                  var doNotExist = new WarningNotification("Cet enfant n'éxiste pas");
                  return Promise.reject({
                    redirect: routesNames.admin.INSCRITS.PENDING,
                  });
                }
              },
            },
            props: {
              child: true,
            },
            component: loadView('Pages/Admin/Inscrits/InscritsValidation'),
          },
          {
            path: 'demandes/:demandeId',
            name: routesNames.admin.DEMANDES,
            component: loadView('Pages/Admin/OneDemandeAdmin'),
            meta: {
              async asyncData(to: MyRoute, from: MyRoute) {
                try {
                  const { demandeId } = to.params;
                  if (to.query.type === 'reservation') {
                    const reservation = await getReservationQuery(demandeId);
                    return { demande: reservation };
                  } else if (to.query.type === 'registration') {
                    const registration = await getRegistrationQuery({ id: demandeId });
                    return {
                      demande: registration.reservation,
                      registration,
                    };
                  } else {
                    return;
                  }
                } catch (e) {
                  var doNotExist = new WarningNotification("Cette demande n'éxiste pas");
                  if (from.name === routesNames.admin.INSCRITS.DETAIL) {
                    return Promise.reject({ redirect: false });
                  } else {
                    return Promise.reject({
                      redirect: { name: routesNames.admin.HOME },
                    });
                  }
                }
              },
              subpage: 'Detail demande',
            },
            props: {
              demande: true,
              registration: true,
            },
          },
          {
            path: 'inscrits/banned',
            name: routesNames.admin.INSCRITS.BANNED,
            meta: {
              title: 'Inscrits bannis',
            },
            component: loadView('Pages/Admin/Inscrits/InscritsBanned'),
          },
          {
            path: 'inscrits/profile/:customerId',
            name: routesNames.admin.INSCRITS.DETAIL,
            meta: {
              subpage: 'Inscrit',
              async asyncData(to: MyRoute, from: MyRoute) {
                try {
                  const customer = await CustomersModule.actions.getOneCustomer(
                    to.params.customerId
                  );
                  return { page: `${customer.firstName} ${customer.lastName}` };
                } catch (e) {
                  var doNotExist = new WarningNotification("Cet inscrit n'existe pas");
                  if (from.name === routesNames.admin.INSCRITS.LIST) {
                    return Promise.reject({ redirect: false });
                  } else {
                    return Promise.reject({ redirect: { name: routesNames.admin.INSCRITS.LIST } });
                  }
                }
              },
            },
            component: loadView('Pages/Admin/Inscrits/OneInscrit'),
          },
          {
            path: 'gerants',
            name: routesNames.admin.GERANTS,
            meta: {
              title: 'Gérants',
            },
            component: loadView('Pages/Admin/Gerants/GerantsRoot'),
          },
          {
            path: 'mailings',
            name: routesNames.admin.MAILINGS,
            meta: {
              title: 'Mailings',
            },
            component: loadView('Pages/Admin/Mailings'),
          },
          {
            path: 'reporting',
            meta: {
              title: 'Reporting',
            },

            component: loadView('Pages/Admin/Reporting/ReportingRoot'),
            children: [
              {
                path: '',
                name: routesNames.admin.REPORTING.HOME,
                meta: {
                  subpage: 'Chiffres Clés',
                },
                component: loadView('Pages/Admin/Reporting/ReportingHome/ReportingHome'),
              },
              {
                path: 'activity',
                name: routesNames.admin.REPORTING.ACTIVITY,
                meta: {
                  subpage: 'Activité Crèches',
                },
                component: loadView('Pages/Admin/Reporting/ReportingActivity/ReportingActivity'),
              },
              {
                path: 'analytics',
                name: routesNames.admin.REPORTING.ANALYTICS,
                meta: {
                  subpage: 'Analytics',
                },
                component: loadView('Pages/Admin/Reporting/ReportingAnalytics'),
              },
            ],
          },
        ],
      },

      // ---------------------------------------------------------------
      // DIRECTEUR
      // ---------------------------------------------------------------
      // ---------------------------------------------------------------
      {
        path: 'directeur/:crecheId',
        component: loadView('Pages/Directeur/DirecteurRoot'),
        meta: {
          isAuthorized(to) {
            return AuthModule.getters.isDirector;
          },
          async asyncData(to: MyRoute) {
            if (to.params.crecheId) {
              const nursery = await DirectorNurseryModule.actions.getOneNursery(to.params.crecheId);
              return {
                page: nursery.name,
              };
            }
          },
        },
        children: [
          {
            path: '',
            name: routesNames.directeur.HOME,
            component: loadView('Pages/Directeur/HomeDirecteur'),
            meta: {
              subpage: 'Accueil',
              async asyncData() {
                console.log('fetch dashboard infos');
              },
            },
          },
          {
            path: 'planning',
            name: routesNames.directeur.PLANNING,
            component: loadView('Pages/Directeur/PlanningDirecteur'),
            meta: {
              subpage: 'Planning',
            },
          },
          {
            path: 'dashbord',
            name: routesNames.directeur.DASHBORD,
            component: loadView('Pages/Directeur/DashbordDirecteur'),
            meta: {
              subpage: 'Tableau de bord',
            },
          },
          {
            path: 'detail',
            name: routesNames.directeur.CRECHEDETAIL,
            component: loadView('Pages/Directeur/CommunityCrecheDescription'),
            meta: {
              subpage: 'Descriptif',
            },
          },
          {
            path: 'demandes',
            name: routesNames.directeur.DEMANDES.LIST,
            component: loadView('Pages/Directeur/Demandes/DemandesDirecteurList'),
            meta: {
              subpage: 'Demandes',
            },
          },
          {
            path: 'demande/:demandeId',
            name: routesNames.directeur.DEMANDES.DETAIL,
            component: loadView('Pages/Directeur/Demandes/OneDemandeDirecteur'),
            meta: {
              relatedContent: true,
              async asyncData(to: MyRoute, from: MyRoute) {
                try {
                  const { demandeId } = to.params;
                  if (to.query.type === 'reservation') {
                    const reservation = await getReservationQuery(demandeId);
                    return { demande: reservation };
                  } else if (to.query.type === 'registration') {
                    const registration = await getRegistrationQuery({ id: demandeId });
                    return {
                      demande: registration.reservation,
                      registration,
                    };
                  } else {
                    return;
                  }
                } catch (e) {
                  var doNotExist = new WarningNotification("Cette demande n'éxiste pas");
                  if (from.name === routesNames.directeur.DEMANDES.LIST) {
                    return Promise.reject({ redirect: false });
                  } else {
                    return Promise.reject({
                      redirect: { name: routesNames.directeur.DEMANDES.LIST },
                    });
                  }
                }
              },
              subpage: 'Detail demande',
            },
            props: {
              demande: true,
              registration: true,
            },
          },
          {
            path: 'inscrits',
            name: routesNames.directeur.INSCRITS.LIST,
            component: loadView('Pages/Directeur/Inscrits/InscritsListDirecteur'),
            meta: {
              subpage: 'Inscrits',
            },
          },
          {
            path: 'inscrits/:customerId',
            name: routesNames.directeur.INSCRITS.DETAIL,
            component: loadView('Pages/Directeur/Inscrits/OneInscritDirecteur'),
            meta: {
              relatedContent: true,
              async asyncData(to: MyRoute, from: MyRoute) {
                try {
                  const customer = await DirectorCustomersModule.actions.getOneCustomer(
                    to.params.customerId
                  );
                  return { subpage: `${customer.firstName} ${customer.lastName}` };
                } catch (e) {
                  var doNotExist = new WarningNotification("Cet inscrit n'existe pas");
                  if (from.name === routesNames.directeur.INSCRITS.LIST) {
                    return Promise.reject({ redirect: false });
                  } else {
                    return Promise.reject({
                      redirect: { name: routesNames.directeur.INSCRITS.LIST },
                    });
                  }
                }
              },
            },
          },
          {
            path: 'inscrits/banned',
            name: routesNames.directeur.INSCRITS.BANNED,
            component: loadView('Pages/Directeur/Inscrits/InscritsBannedDirecteur'),
            meta: {
              subpage: 'Bannis',
            },
          },
          {
            path: 'paiements',
            name: routesNames.directeur.PAIEMENTS,
            component: loadView('Pages/Directeur/PaimentsDirecteur'),
            meta: {
              subpage: 'Paiements',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/connexion',
    name: routesNames.CONNEXION,
    meta: {
      noAuth: true,
      title: 'Connexion',
    },
    component: loadView('Connexion'),
  },
  {
    path: '/forgot-password',
    name: routesNames.FORGOT_PASSWORD,
    meta: {
      noAuth: true,
      title: 'Forgot password',
    },
    component: loadView('ForgotPassword'),
  },
  {
    path: '/activate-user',
    name: routesNames.ACTIVATE_USER,
    meta: {
      title: 'Définir le mot de passe',
      noAuth: true,
    },
    props: true,
    component: loadView('SetPassword'),
  },
  {
    path: '/reset-password',
    name: routesNames.RESET_PASSWORD,
    meta: {
      title: 'Réinitialiser le mot de passe',
      noAuth: true,
    },
    props: true,
    component: loadView('SetPassword'),
  },
  {
    path: '/execute-cron',
    name: routesNames.CRON,
    meta: {
      title: '[CRON]',
      requiresAuth: true,
      isAuthorized(to) {
        return AuthModule.getters.isAdmin;
      },
    },
    component: loadView('ExecuteCron'),
  },
  {
    path: '/*',
    meta: {
      title: 'Page non trouvée',
    },
    component: loadView('error404'),
  },
];
