






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Modal, Child, DateDisplay, Radio, FormContainer } from '@components';
import { IReservation, IReservationStatus } from '@models';
import { Forms, SuccessNotification, ErrorNotification } from '@constructors';
import { required, maxLength } from 'vuelidate/lib/validators';
import { constantToSelectFormater } from '@utils';
import { rejectReasonConstant } from '@constants';
import { updateReservationMutation } from '@graphql';
import { AuthModule } from '@store';
import { routesNames, Router } from '@router';

@Component({ components: { Modal, Child, DateDisplay, Radio, FormContainer } })
export default class AcceptReservation extends Vue {
  @Prop() show: boolean;
  @Prop() reservation: IReservation;

  public choice = new Forms.Radio<IReservationStatus>({
    radios: [
      { value: 'DONE', text: 'Accepter' },
      { value: 'REFUSED', text: 'Refuser' },
    ],
    style: 'column',
  }).extract();

  get child() {
    return this.reservation.child;
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get canConfirm() {
    if (this.choice.value === 'DONE') {
      return true;
    } else if (this.choice.value === 'REFUSED') {
      return this.resaForm.isFormValid;
    }
    return false;
  }

  async handleValidate() {
    try {
      if (this.choice.value === 'DONE') {
        await updateReservationMutation({
          where: { id: this.reservation.id },
          data: { status: 'DONE' },
        });
        new SuccessNotification('Demande de réservation associée validée!');
      } else {
        await updateReservationMutation({
          where: { id: this.reservation.id },
          data: this.resaForm.getValues(),
        });
        new SuccessNotification('Demande de réservation associée refusée!');
        const name = this.isAdmin
          ? routesNames.admin.CRECHEDETAIL.DEMANDES.LIST
          : routesNames.directeur.DEMANDES.LIST;
        Router.replace({ name });
      }
      this.$emit('update:show', false);
    } catch (e) {
      var error = new ErrorNotification("Erreur lors de l'opération sur la réservation");
    }
  }

  public resaForm = new Forms.Form({
    fields: {
      statusReason: new Forms.Select({
        label: 'Raison',
        options: constantToSelectFormater(rejectReasonConstant),
      }),
      reasonComment: new Forms.FieldForm({
        label: 'Description',
      }),
    },
    validations: {
      statusReason: { required },
      reasonComment: { maxLength: maxLength(140) },
    },
  });
}
