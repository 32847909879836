



















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { routesNames } from '@router';
import { AuthModule, NurseryModule, NotificationsModule, updateDocument } from '@store';
import {
  BackLink,
  DateDisplay,
  Customer,
  DocumentWithAction,
  FormContainer,
  MissingDocument,
} from '@components';
import { Forms, Alerts, ErrorNotification } from '@constructors';
import {
  IDocument,
  IWhereUniqueInput,
  IDocumentStatusUpdateDataInput,
  IDocumentStatus,
  IChild,
  IDocumentType,
  ICustomer,
  IEffortRate,
} from '@models';
import { differenceInMonths } from 'date-fns';
import { calculatePsuPrice, constantToSelectFormater } from '@utils';
import { documentRejectedReasonConstant } from '@constants';
import { updateCustomerMutation, getAllEffortRatesQuery } from '@graphql';
import { positiveNumber } from '@validators';
import {
  required,
  decimal,
  maxLength,
  maxValue,
  minValue,
  numeric,
} from 'vuelidate/lib/validators';

@Component({
  components: {
    BackLink,
    DateDisplay,
    Customer,
    DocumentWithAction,
    FormContainer,
    MissingDocument,
  },
})
export default class InscritsValidation extends Vue {
  @Prop() child!: IChild;

  cachedChild = this.child;
  priceForm = new Forms.Form({
    fields: {
      price: new Forms.TextForm<string>({
        label: 'Prix en €',
        valueType: 'price',
        width: '200px',
      }),
    },
    validations: {
      price: { required, decimal, positiveNumber },
    },
  });

  @Watch('priceForm.fieldsValues.price')
  valueChanged(price, oldVal) {
    let value: number = 0;
    if (typeof price == 'string') value = +price.replace(',', '.');
    else value = price;
    if (value) {
      this.pricePerHourForm.fieldsValues.price = Number((+value / 6).toFixed(2)).toFixed(2);
    }
  }

  pricePerHourForm = new Forms.Form({
    fields: {
      price: new Forms.TextForm<string>({
        label: 'Prix par heure en €',
        valueType: 'price',
        width: '200px',
        disabled: true,
      }),
    },
    validations: {
      price: { required, decimal, positiveNumber },
    },
  });

  public listDocument: IDocumentType[] = [
    'PARENT_ID_RECTO',
    'PARENT_ID_VERSO',
    'CAF_IMMATRICULATION',
    'CHILD_HEALTH_RECORD',
    'BIRTH_CERTIFICATE',
    'INSURANCE_CERTIFICATE',
    'MEDICAL_CERTIFICATE',
    'PROOF_OF_ADDRESS',
    'JOB_SEEKER_CERTIFICATE',
  ];

  get isCommunityNursery() {
    return NurseryModule.state.oneNursery?.communityNursery === true;
  }

  get isDspNursery() {
    return NurseryModule.state.oneNursery?.dspNursery === true;
  }

  public effortRates: IEffortRate[] = [];

  get isJobSeeker() {
    return (
      this.customer.isJobSeeker &&
      this.documents.some((x) => x.type === 'JOB_SEEKER_CERTIFICATE' && x.status === 'OK')
    );
  }

  get missingDocuments() {
    return this.listDocument.filter(
      (f) =>
        !this.documents
          .filter(
            (x) =>
              x.required ||
              (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
              (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
          )
          .find((doc) => doc.type === f)
    );
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get isDocValid() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .every((m) => m.status === 'OK');
  }
  get isDocToValid() {
    return this.documents
      .filter(
        (x) =>
          x.required ||
          (x.type === 'PROOF_OF_ADDRESS' && (this.isCommunityNursery || this.isDspNursery)) ||
          (x.type === 'JOB_SEEKER_CERTIFICATE' && this.isJobSeeker)
      )
      .some((m) => ['PENDING', 'DECLINED', 'EXPIRED'].includes(m.status));
  }

  get customer(): ICustomer {
    return this.cachedChild.parent;
  }

  get notResident() {
    return !this.customer.isResident;
  }
  get documents(): IDocument[] {
    return [...this.customer.documents, ...this.cachedChild.documents];
  }

  get childAge() {
    return differenceInMonths(new Date(), this.child.birthDate);
  }

  get backLink() {
    return { name: routesNames.admin.INSCRITS.PENDING };
  }
  public psuCalculateForm = new Forms.Form({
    fields: {
      children: new Forms.TextForm<number>({
        type: 'number',
        valueType: 'number',
        value: 1,
        label: "Nombre d'enfants à charge",
        halfWidth: true,
      }),
      salary: new Forms.TextForm<number>({
        label: 'Ressources annuelles (CAF)',
        valueType: 'number',
        type: 'number',
        placeholder: 'en €',
        halfWidth: true,
      }),
    },
    validations: {
      children: { required, maxValue: maxValue(10), minValue: minValue(1), numeric },
      salary: { required, maxValue: maxValue(1000000), minValue: minValue(0), numeric },
    },
  });

  get isNotResident() {
    return !this.customer.isResident;
  }

  calculatePrice() {
    this.priceForm.fieldsValues.price = calculatePsuPrice(
      this.psuCalculateForm.getValues().salary,
      this.psuCalculateForm.getValues().children,
      this.isNotResident,
      this.effortRates
    );
  }

  updatePrice() {
    NotificationsModule.actions.addLoader({
      message: 'Modification du prix',
      successMessage: 'Prix modifié!',
      errorMessage: 'Erreur lors de la modification du prix',
      handler: async () => {
        const price = Number(this.priceForm.getValues().price);
        try {
          const customer = await updateCustomerMutation({
            where: {
              id: this.customer.id,
            },
            data: {
              price,
            },
          });
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        }
      },
    });
  }

  async handleUpdateDocument({
    form,
    params,
  }: Alerts.FormPayload<IDocumentStatusUpdateDataInput, IWhereUniqueInput>) {
    try {
      const document = await updateDocument({
        where: { id: params.id },
        data: {
          ...form,
        },
      });
      if (document != null) {
        let document2 =
          this.cachedChild.parent.documents.find((f) => f.id === params.id) ||
          this.cachedChild.documents.find((f) => f.id === params.id);
        document2.status = document.status;
        document2.updatedAt = document.updatedAt;
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleValidate(id: string) {
    var alert = new Alerts.ConfirmAlert({
      title: 'Valider le document?',
      actions: [
        new Alerts.ConfirmAction({
          text: 'Valider',
          handler: () =>
            this.handleUpdateDocument({
              form: {
                status: 'OK' as IDocumentStatus,
              },
              params: { id },
            }),
        }),
      ],
    });
  }

  handleDecline(id: string) {
    var alert = new Alerts.FormAlert({
      title: `Décliner le document`,
      formElement: {
        form: new Forms.Form({
          fields: {
            reason: new Forms.Select({
              label: 'Raison',
              options: constantToSelectFormater(documentRejectedReasonConstant),
            }),
            comment: new Forms.FieldForm({
              label: 'Description de la raison',
            }),
            status: 'DECLINED' as IDocumentStatus,
          },
          validations: {
            reason: { required },
            comment: { maxLength: maxLength(140) },
          },
        }),
        submit: {
          params: { id },
          handler: this.handleUpdateDocument,
        },
      },
    });
  }

  async acceptDemande() {
    var alert = new Alerts.ConfirmAlert({
      title: `Valider l'inscription?`,
      actions: [
        new Alerts.ConfirmAction({
          text: 'Valider',
          handler: async () => {
            try {
            } catch (e) {
              console.log(e);
              var validationError = new ErrorNotification(
                "Erreur lors de la validation de l'inscription"
              );
            }
          },
        }),
      ],
    });
  }

  async mounted() {
    this.effortRates = await getAllEffortRatesQuery({ where: { id: '' } });
  }
}
