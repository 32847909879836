






























import { Component, Vue, Prop } from 'vue-property-decorator';
import FormText from './FormText.vue';
import FormCheckBox from './FormCheckBox.vue';
import FormField from './FormField.vue';
import FormUpload from './FormUpload.vue';
import FormSelect from './FormSelect.vue';
import RichText from './RichText/RichText.vue';
import Radio from './Radio.vue';
import RichRadio from './RichRadio.vue';
import RichCheckBoxList from './RichCheckBoxList.vue';
import CheckBox from './CheckBox.vue';
import CheckBoxList from './CheckBoxList.vue';
import FormCalendar from './FormCalendar/FormCalendar.vue';
import FormSelectMultiple from './FormSelectMultiple.vue';
import { pickBy } from 'lodash';
import { Forms } from '@constructors';

@Component({
  name: 'FormItem',
  components: {
    FormText,
    FormCheckBox,
    FormField,
    FormUpload,
    FormSelect,
    FormCalendar,
    FormSelectMultiple,
    RichText,
    Radio,
    RichRadio,
    RichCheckBoxList,
    CheckBox,
    CheckBoxList,
  },
})
export default class FormItem extends Vue {
  @Prop({ type: null }) item;
  @Prop() data: any;
  @Prop() vl: any;
  @Prop({ type: null }) value;
  @Prop() unwrap: boolean;

  get hasChilds() {
    return this.item instanceof Object;
  }

  get isForm() {
    return this.item instanceof Forms.DefaultFormElement;
  }

  get isItemForm() {
    return (item) => item instanceof Forms.DefaultFormElement;
  }

  get isLabel() {
    return (item) => item instanceof Forms.Label;
  }

  get isSection() {
    return this.item instanceof Forms.SectionConstructor;
  }

  get isSectionUnwrap() {
    return this.isSection && this.item.unwrap;
  }
  get isProperty() {
    return !this.isForm && !this.isSection;
  }
}
