























































































import { Component, Vue } from 'vue-property-decorator';
import { Forms, SuccessNotification } from '@constructors';
import { FormText } from '@components';
import { DirectorNurseryModule, SharedModule } from '@store';
import { zipCode } from '@validators';
import CommunityCrecheInfos from './CommunityCrecheInfos.vue';
import { getCommunityNurseryZipCodeQuery, updateCommunityNurseryZipCodesMutation } from '@graphql';
import { ICommunityNurseryZipCode, INursery, IUpdateCommunityNurseryZipCodeInput } from '@models';

@Component({
  components: {
    FormText,
    CommunityCrecheInfos,
  },
  validations: {
    zipCodeForm: { fieldsValues: { zipCode: { zipCode } } },
  },
})
export default class CommunityCrecheDescription extends Vue {
  public loadingPage = false;

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }

  get zipCodeOptions() {
    return SharedModule.state.allZipCodes;
  }

  zipCodeToAdd: string = null;
  communityNurseryZipCodes: ICommunityNurseryZipCode[] = [];

  public zipCodeForm = new Forms.Form({
    fields: {
      zipCode: new Forms.TextForm<any>({
        value: this.zipCodeToAdd,
        editMode: !!this.zipCodeToAdd,
        valueType: 'string',
        placeholder: 'Ajouter un code postal',
      }),
    },
    validations: {},
  });

  async getCommunityNurseryZipCodes() {
    try {
      const nurseryId = this.nursery.id;
      this.communityNurseryZipCodes = await getCommunityNurseryZipCodeQuery({
        nurseryId: nurseryId,
      });
    } finally {
    }
  }

  get isDisabled() {
    return this.$v.zipCodeForm.$invalid && this.$v.zipCodeForm.$dirty;
  }

  async handleUpdateZipCodeList() {
    await updateCommunityNurseryZipCodesMutation({
      data: {
        data: this.communityNurseryZipCodes.map(
          (x) => x as unknown as IUpdateCommunityNurseryZipCodeInput
        ),
      },
    });
    const _ = new SuccessNotification('Liste de codes postaux sauvegardé');
    await this.getCommunityNurseryZipCodes();
  }

  handleZipCode(value: string) {
    this.zipCodeForm.fieldsValues.zipCode = value;
    this.zipCodeToAdd = value;
  }

  removeItem(item: ICommunityNurseryZipCode) {
    const index = this.communityNurseryZipCodes.indexOf(item);
    if (index > -1) {
      this.communityNurseryZipCodes.splice(index, 1);
    }
  }

  addItem() {
    if (this.communityNurseryZipCodes.findIndex((x) => x.zipCode === this.zipCodeToAdd) < 0) {
      const newItem = {} as ICommunityNurseryZipCode;
      newItem.zipCode = this.zipCodeToAdd;
      newItem.nursery = {} as INursery;
      newItem.nursery.id = this.nursery.id;
      this.communityNurseryZipCodes.push(newItem);
    }
    this.zipCodeToAdd = null;
    this.zipCodeForm.fieldsValues.zipCode = null;
  }

  formatedZipCode(value: string) {
    if (this.zipCodeOptions.length > 0) {
      const item = this.zipCodeOptions.find((x) => x.zipCode.toUpperCase() === value.toUpperCase());
      if (item) return `${item.label} (${item.zipCode})`;
    }
    return value;
  }

  async mounted() {
    this.loadingPage = true;
    await this.getCommunityNurseryZipCodes();
    await SharedModule.actions.fetchZipCodes();
    this.loadingPage = false;
  }
}
