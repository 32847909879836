




import { Component, Vue, Prop } from 'vue-property-decorator';
import InscritsBannedTemplate from '../../Shared/InscritsBannedTemplate.vue';
import { NurseryModule } from '@store';

@Component({
  components: { InscritsBannedTemplate },
})
export default class InscritsBanned extends Vue {}
