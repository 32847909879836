































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormContainer, InfoMessage } from '@components';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import { AuthModule, ProgressBarModule } from '@store';
import { Forms, SuccessNotification } from '@constructors';
import { routesNames, Router } from '../router';
import { Dictionary } from 'vue-router/types/router';

@Component({
  components: { FormContainer, InfoMessage },
})
export default class SetPassword extends Vue {
  public submitting = false;
  public formError = false;
  public formErrorMessage = null;
  public query: { token: string; id: string } = null;

  public connexionForm = new Forms.Form({
    fields: {
      password: new Forms.TextForm({
        type: 'password',
        placeholder: 'Mot de passe',
        icon: 'icons/Forms/password.svg',
      }),
      confirmPassword: new Forms.TextForm({
        type: 'password',
        placeholder: 'Confirmer le mot de passe',
        icon: 'icons/Forms/password.svg',
        errorMessages: {
          sameAs: 'Les mots de passes doivent être identiques',
        },
      }),
    },
    validations: {
      password: { required },
      confirmPassword: { required, sameAs: sameAs('password') },
    },
  });

  get appName() {
    return process.env.VUE_APP_NAME;
  }

  async submitForm() {
    const form = this.connexionForm.getValues();
    this.formError = false;
    this.formErrorMessage = null;

    const { query, path, name } = this.$route;

    try {
      if (query) {
        const { id, token } = query as Dictionary<string>;

        this.submitting = true;

        if (name === routesNames.RESET_PASSWORD) {
          AuthModule.actions.resetUserPassword({ ...form, token, userId: id });
          new SuccessNotification('Mot de passe modifié!');
          Router.push({
            name: routesNames.CONNEXION,
          });
        } else {
          AuthModule.actions.activateUser({
            activateUserInput: { userId: id, token, password: form.password },
            nextRoute: '/',
          });
        }
      } else {
        const { id, token } = query;

        this.submitting = true;

        await AuthModule.actions.updateUser({ ...form });
      }
    } catch (e) {
      console.log(e);
      this.formError = true;
      this.formErrorMessage = e;
    } finally {
      this.submitting = false;
    }
  }
}
