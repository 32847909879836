




























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Forms } from '@constructors';
import { InfiniScrollMixin } from '@components';
import { required, decimal } from 'vuelidate/lib/validators';
import { routesNames } from '@router';
import { format, differenceInMonths } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import {
  ICustomer,
  IregistrationsConnectionArgs,
  IRegistration,
  IRegistrationsConnection,
} from '@models';
import { CustomersModule, DirectorNurseryModule, getNurseryRegistrations } from '@store';
import { oc } from 'ts-optchain';

@Component({
  components: {},
})
export default class InscritsListDirecteur extends InfiniScrollMixin<
  IRegistrationsConnection,
  IregistrationsConnectionArgs
> {
  public routesNames = routesNames;
  public columns = [
    'Parent',
    'Enfant',
    'Dernière réservation',
    "Date d'inscription",
    'Nombre de réservations',
    '',
  ];
  public listHandler = getNurseryRegistrations;

  public registrations: IRegistration[] = [];

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }

  get title() {
    return `Inscrits de ${this.nursery.name}`;
  }

  get registrationsFiltered() {
    return this.registrations.map((m) => ({
      ...m,
      parent: m.child.parent,
      documents: [...m.child.parent.documents, ...m.child.documents],
      child: `${m.child.firstName}, ${differenceInMonths(new Date(), m.child.birthDate)} mois`,
      reservationCount: m.child.parent.reservations.filter((f) => f.status === 'DONE').length,
      last:
        m.child.parent.reservations.length > 0
          ? format(
              m.child.parent.reservations[m.child.parent.reservations.length - 1].date,
              'DD MMM YYYY',
              { locale: frLocale }
            )
          : null,
      signup: format(m.registrationDate, 'DD MMM YYYY', { locale: frLocale }),
    }));
  }

  get handlerParams(): IregistrationsConnectionArgs {
    return {
      where: {
        nursery: {
          id: this.nursery.id,
        },
      },
    };
  }

  newContent(value: IRegistration[]) {
    this.registrations = value;
  }

  get bannedLink() {
    return { name: routesNames.directeur.INSCRITS.BANNED };
  }
}
