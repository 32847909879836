import { Line, mixins } from 'vue-chartjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Colors from '@colors';
@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class ChiffreAffaire extends Vue {
  @Prop() data: any[];
  renderChart;
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: ['Moyenne', 'M-1', 'M-2', 'M-3', 'M-4', 'M-5', 'M-6', 'Mois en cours'],
        datasets: [
          {
            label: "Chiffre d'affaire",
            backgroundColor: 'transparent',
            borderColor: Colors.blue,
            data: this.data,
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
}
