





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RadioTabs, InfiniScrollMixin, BillingViewer } from '@components';
import { range } from 'lodash';
import { IbillingsConnectionArgs, IBillingsConnection, ICustomer, IBilling } from '@models';
import { differenceInMonths, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { getNurseryReservations, AuthModule } from '@store';
import { routesNames } from '@router';
import { sgts } from '../../../services';
import { billingFragment } from '../../../fragments';
import { Alerts, ErrorNotification, SuccessNotification } from '../../../constructors';

const handler = (args) => sgts.billingsConnection().$fragment(billingFragment).$args(args).$fetch();

@Component({
  components: {
    RadioTabs,
    BillingViewer,
  },
})
export default class OneInscritBillings extends InfiniScrollMixin<
  IBillingsConnection,
  IbillingsConnectionArgs
> {
  @Prop() customer: ICustomer;

  public columns = ['Nom', 'Date', ''];
  public selectedBill: IBilling = null;
  public showViewer = false;
  public billings: IBilling[] = [];

  public listHandler = handler;

  get handlerParams(): IbillingsConnectionArgs {
    return {
      where: {
        customer: {
          id: this.customer.id,
        },
      },
      limit: 5,
    };
  }

  showBill(bill: IBilling) {
    this.selectedBill = bill;
    this.showViewer = true;
  }

  newContent(data: IBilling[]) {
    this.billings = data;
  }

  deleteBill(bill: IBilling) {
    var alert = new Alerts.ConfirmAlert({
      title: 'Supprimer la facture?',
      description: 'Cette action est irréversible',
      actions: [
        new Alerts.ConfirmAction({
          text: 'Confirmer',
          handler: async () => {
            try {
              await sgts
                .deleteBilling()
                .$args({ where: { id: bill.id } })
                .$post();
              new SuccessNotification('Facture supprimée!');
              this.billings = this.billings.filter((f) => f.id !== bill.id);
            } catch (e) {
              var error = new ErrorNotification('Erreur lors de la suppression de la facture ');
            }
          },
        }),
      ],
    });
  }

  get billingsFiltered() {
    return this.billings.map((m) => ({
      ...m,
      date: format(m.date, 'DD MMM YYYY', { locale: frLocale }),
    }));
  }
}
