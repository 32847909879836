














import { Component, Vue, Prop } from 'vue-property-decorator';
import { IDocument, IDocumentType, IDocumentStatus } from '@models';
import { documentNamesConstant, documentStatusNamesConstant } from '@constants';

@Component
export default class MissingDocument extends Vue {
  @Prop(String) type: IDocumentType;

  get name() {
    return documentNamesConstant[this.type];
  }
}
