





































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { NurseryModule } from '@store';

import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

@Component({})
export default class CrecheInfos extends Vue {
  get nursery() {
    let res = NurseryModule.state.oneNursery;
    res.closingDates?.sort((a, b) => {
      let val = (new Date(b.closingDateStart) as any) - (new Date(a.closingDateStart) as any);
      return -val;
    });
    return res;
  }
  formatDate(date) {
    return format(date, 'DD MMM YYYY', { locale: frLocale });
  }

  get totalCapacity() {
    return this.nursery.sections.reduce((acc, section) => {
      return (acc += section.capacity);
    }, 0);
  }
  get isCommunityNursery() {
    return this.nursery.communityNursery === true;
  }

  get isDspNursery() {
    return this.nursery.dspNursery === true;
  }

  get communityNurseryZipCodes() {
    return (
      this.nursery.communityNurseryZipCodes?.map((x) => x.zipCode).join(', ') ??
      'Aucun code postal rajouté'
    );
  }

  get dspNurseryZipCodes() {
    return (
      this.nursery.dspNurseryZipCodes?.map((x) => x.zipCode).join(', ') ??
      'Aucun code postal rajouté'
    );
  }

  get emailToHref() {
    return 'mailto:' + this.nursery?.responsibleEmail;
  }
}
