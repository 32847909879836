



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { DirectorNurseryModule, AuthModule } from '@store';
import { HeaderComponent } from '@components';
import { oc } from 'ts-optchain';

@Component({
  components: { HeaderComponent },
})
export default class DirecteurRoot extends Vue {
  get hasNurseries() {
    return oc(AuthModule.state.userInfos.nurseries).length();
  }

  disconnect() {
    AuthModule.actions.disconnectRequest();
  }
}
