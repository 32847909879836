















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getMonth,
  getYear,
  setYear,
  setMonth,
  subMonths,
  addMonths,
  getDaysInMonth,
  startOfMonth,
  getDay,
  setDate,
  endOfMonth,
  format,
  isBefore,
  startOfYear,
  endOfYear,
  subYears,
  isValid,
  setHours,
} from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { range, capitalize } from 'lodash';
import FormSelect from '../FormSelect.vue';
import Popup from '../../Display/Popup.vue';
import { EventBus, Events } from '@services';
import DateItem from './DateItem.vue';
import { Forms } from '@constructors';
import { formatHoursAndMinutes } from '@utils';
import Radio from '../Radio.vue';

@Component({
  components: { DateItem, Radio, FormSelect, Popup },
})
export default class Calendar extends Vue {
  @Prop({ type: null }) value: Forms.ICalendarValue;
  @Prop(String) calendarType: Forms.ICalendarType;
  @Prop() sideList: boolean;
  @Prop({ default: 'Personnalisé', type: null }) selectedSideItem: Forms.ISideListLabel;

  $refs: {
    calendar: HTMLElement;
    popup: Popup;
  };

  calendarRef: HTMLElement = null;

  public weekDays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
  public listMonths = [
    'Janv.',
    'Févr.',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juil.',
    'Aout',
    'Sept.',
    'Octo.',
    'Novem.',
    'Décem.',
  ];

  public selectedMonth: number = null;
  public selectedYear: number = null;
  public selectedHour = 10;
  public selectedMinute = 0;
  public hoveredDate: Date = null;
  public sideListItems: Forms.ISideListItem[] = [
    { label: 'Personnalisé', padding: true, actionValue: { start: null, end: null } },
    {
      label: 'Ce mois ci',
      actionValue: { start: startOfMonth(new Date()), end: endOfMonth(new Date()) },
    },
    {
      label: 'Ces 6 derniers mois',
      actionValue: { start: startOfMonth(subMonths(new Date(), 6)), end: endOfMonth(new Date()) },
    },
    {
      label: "L'année en cours",
      actionValue: { start: startOfYear(new Date()), end: endOfYear(new Date()) },
    },
    {
      label: "L'année dernière",
      actionValue: {
        start: startOfYear(subYears(new Date(), 1)),
        end: endOfYear(subYears(new Date(), 1)),
      },
    },
  ];

  public yearPickerBase: number = null;

  get listYears() {
    const year = this.yearPickerBase;
    let list = [year];
    for (let i = 1; i < 5; i++) {
      list.push(year - i);
      list.push(year + i);
    }
    return list.sort();
  }

  get canGoNextYear() {
    return this.yearPickerBase + 9 < 2100;
  }

  get canGoPreviousYear() {
    return this.yearPickerBase - 9 > 1900;
  }

  nextListYear() {
    if (this.yearPickerBase + 9 >= 2100) {
      this.yearPickerBase = 2095;
    } else {
      this.yearPickerBase += 9;
    }
  }

  previousListYear() {
    if (this.yearPickerBase - 9 <= 1900) {
      this.yearPickerBase = 1905;
    } else {
      this.yearPickerBase -= 9;
    }
  }

  get currentMonthDisplay(): string {
    return capitalize(format(this.currentMonth, 'MMMM', { locale: frLocale }));
  }
  get currentYearDisplay(): string {
    return format(this.currentMonth, 'YYYY');
  }
  set currentYearDisplay(value: string) {
    const date = new Date(value);
    if (isValid(date) && Number(value) < 2100 && Number(value) > 1900) {
      this.selectedYear = Number(value);
      if (this.isCalendarNormal(this.value) && this.value) {
        this.handleDateSelect(setYear(this.value, Number(value)), false);
      }
      this.closePopups();
    }
  }

  get allDisplayDates(): Date[] {
    return [...this.previousMonthDates, ...this.currentMonthDates, ...this.nextMonthDates];
  }

  get previousMonthDates(): Date[] {
    const previousMonthNumberOfDays = getDaysInMonth(this.previousMonth);
    const currentStartOfMonth = getDay(startOfMonth(this.currentMonth));
    return range(
      previousMonthNumberOfDays - (currentStartOfMonth === 0 ? 6 : currentStartOfMonth - 1),
      previousMonthNumberOfDays
    ).map((day) => {
      return formatHoursAndMinutes(setDate(this.previousMonth, day + 1));
    });
  }
  get currentMonthDates(): Date[] {
    const currentNumberOfDays = getDaysInMonth(this.currentMonth);
    return range(0, currentNumberOfDays).map((day) => {
      return formatHoursAndMinutes(setDate(this.currentMonth, day + 1));
    });
  }

  get nextMonthDates(): Date[] {
    const nextMonthLimit = 7 - getDay(endOfMonth(this.currentMonth));
    return range(0, nextMonthLimit === 7 ? 0 : nextMonthLimit).map((day) => {
      return formatHoursAndMinutes(setDate(this.nextMonth, day + 1));
    });
  }

  get currentMonth(): Date {
    const today = new Date();
    const withYear = setYear(today, this.selectedYear);
    const withMonth = setMonth(withYear, this.selectedMonth);
    return setHours(withMonth, this.selectedHour);
  }

  get previousMonth(): Date {
    return subMonths(this.currentMonth, 1);
  }
  get nextMonth(): Date {
    return addMonths(this.currentMonth, 1);
  }

  handleSideItemSelect(item: Forms.ISideListItem) {
    this.$emit('sideSelect', item);
  }

  handleSubMonth() {
    const prevMonth = subMonths(this.currentMonth, 1);
    this.selectedMonth = getMonth(prevMonth);
    this.selectedYear = getYear(prevMonth);
  }
  handleAddMonth() {
    const nextMonth = addMonths(this.currentMonth, 1);
    this.selectedMonth = getMonth(nextMonth);
    this.selectedYear = getYear(nextMonth);
  }
  setMonth(month: number) {
    this.selectedMonth = month;
    if (this.isCalendarNormal(this.value) && this.value) {
      this.handleDateSelect(setMonth(this.value, month), false);
    }
    this.closePopups();
  }

  emitNewPeriod(value: any) {
    if (this.selectedSideItem !== 'Personnalisé') {
      this.handleSideItemSelect({
        ...this.sideListItems[0],
        actionValue: value,
      });
    } else {
      this.$emit('input', value);
    }
  }

  handleDateSelect(value: Date, close = true) {
    if (this.isCalendarNormal(this.value)) {
      this.$emit('input', value);
      if (close) {
        this.$emit('close');
      }
    } else if (!this.value?.start && !this.value?.end) {
      this.emitNewPeriod({ start: value });
    } else if (this.value?.start && isBefore(value, this.value?.start)) {
      const newValue = {
        start: value,
        end: this.value?.start,
      };
      this.emitNewPeriod(newValue);
    } else {
      this.emitNewPeriod({
        start: this.value?.start,
        end: value,
      });
    }
  }

  handleMouseEnter(value: Date) {
    if (!this.isCalendarNormal(this.value)) {
      this.hoveredDate = value;
    }
  }
  handleMouseLeave(value: Date) {
    if (!this.isCalendarNormal(this.value)) {
      this.hoveredDate = null;
    }
  }

  handleUnselectStart() {
    if (this.isCalendarPeriod(this.value)) {
      let newValue: Forms.ICalendarPeriodType | null = null;
      if (this.value?.end) {
        newValue = {
          start: this.value?.end,
          end: null,
        };
      }
      this.$emit('input', newValue);
      this.hoveredDate = null;
    }
  }
  handleUnselectEnd() {
    if (this.isCalendarPeriod(this.value)) {
      const newValue = this.value;
      newValue.end = null;
      this.$emit('input', newValue);
      this.hoveredDate = null;
    }
  }

  isCalendarNormal(value: Forms.ICalendarValue): value is Date {
    return this.calendarType === 'normal';
  }
  isCalendarPeriod(value: Forms.ICalendarValue): value is { start: Date; end: Date } {
    return this.calendarType === 'period';
  }

  closePopups() {
    EventBus.$emit(`${Events.CLOSE_POPUPS}-calendar`);
  }

  created() {
    if (!this.value) {
      this.selectedMonth = getMonth(new Date());
      this.selectedYear = getYear(new Date());
    } else if (this.isCalendarNormal(this.value)) {
      this.selectedMonth = getMonth(this.value as Date);
      this.selectedYear = getYear(this.value as Date);
    } else if (this.value && this.value?.end) {
      this.selectedMonth = getMonth(this.value?.end as Date);
      this.selectedYear = getYear(this.value?.end as Date);
    } else if (this.value && this.value?.start) {
      this.selectedMonth = getMonth(this.value?.start as Date);
      this.selectedYear = getYear(this.value?.start as Date);
    } else {
      this.selectedMonth = getMonth(new Date());
      this.selectedYear = getYear(new Date());
    }
  }

  mounted() {
    this.calendarRef = this.$refs.calendar;
  }
}
