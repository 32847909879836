var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'progress')?_c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.progressState.show)?_c('div',{staticClass:"progress",style:({
        width: _vm.progressState.percent + '%',
        height: _vm.progressState.height,
        backgroundColor: _vm.progressState.canSuccess
          ? _vm.progressState.color
          : _vm.progressState.failedColor,
      })}):_vm._e()])],1):_c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.progressState.show)?_c('div',{staticClass:"loader-wrapper flex"},[_c('Spinner',{staticClass:"spinner",attrs:{"size":70,"color":"blue"}}),_c('span',[_vm._v("Recupération des données")]),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"loader",style:({
            width: _vm.progressState.percent + '%',
            backgroundColor: _vm.progressState.canSuccess
              ? _vm.progressState.loaderColor
              : _vm.progressState.failedColor,
          })})])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }