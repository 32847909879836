

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { IAnyObject } from '../../../models';
import { isEmpty } from 'lodash';

@Component({})
export default class TableGrid extends Vue {
  @Prop() columns: string[];
  @Prop([Array, Object]) gridData: any[] | IAnyObject;
  @Prop() gap: number;
  @Prop({ default: 'Aucune données' }) message: string;

  public isEmpty = isEmpty;

  get isArray() {
    return Array.isArray(this.gridData);
  }

  get isObject() {
    return this.gridData instanceof Object;
  }
}
