import gql from 'graphql-tag';

export const userFragment = gql`
  fragment userFragment on User {
    id
    email
    firstName
    lastName
    role
    createdAt
    nurseries {
      id
      name
      analyticCode
    }
  }
`;
