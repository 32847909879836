




import { Component, Vue, Prop } from 'vue-property-decorator';
import OneInscritTemplate from '../../Shared/OneInscritTemplate.vue';
import { CustomersModule } from '@store';

@Component({
  components: { OneInscritTemplate },
})
export default class OneInscrit extends Vue {
  get inscrit() {
    return CustomersModule.state.oneCustomer;
  }
}
