import { defineModule } from '@/vuex-typed-modules';
import {
  INursery,
  IregistrationsConnectionArgs,
  IScheduleInput,
  IupdateNurseryArgs,
  IUpdateNurseryInput,
} from '@models';
import { getNurseryQuery, registrationsConnectionQuery, updateNurseryMutation } from '@graphql';
import { ErrorNotification, Alerts, SuccessNotification } from '@constructors';
import Vue from 'vue';

interface IDirectorNurseryState {
  nursery: INursery;
}

//State
const state: IDirectorNurseryState = {
  nursery: null,
};

const mutations = {
  updateNursery(state: IDirectorNurseryState, data: Partial<INursery>) {
    Object.keys(data).forEach((key) => {
      Vue.set(state.nursery, key, data[key]);
    });
  },
};

const actions = {
  async getOneNursery(context, nurseryId: string) {
    const nursery = await getNurseryQuery(nurseryId);
    DirectorNurseryModule.updateState({
      nursery,
    });
    return nursery;
  },
  async updateNursery(
    context,
    { form, params }: Alerts.FormPayload<IUpdateNurseryInput, { id: string }>
  ) {
    try {
      const nursery = await updateNurseryMutation({ data: form, id: params.id });
      DirectorNurseryModule.updateState({
        nursery,
      });
      new SuccessNotification('Informations de la crèche modifiées');
    } catch (e) {
      var errorModifying = new ErrorNotification(
        'Erreur lors de la modification des informations de la crèche'
      );
    }
  },
};

export const DirectorNurseryModule = defineModule('DirectorNurseryModule', state, {
  mutations,
  actions,
});
