import { Alerts } from '@constructors';
import { defineModule } from '@/vuex-typed-modules';
import Vue from 'vue';

export interface IAlertsState {
  content: Alerts.Alert;
  showAlert: boolean;
}

// State
const state: IAlertsState = {
  content: null,
  showAlert: false,
};

// Mutations
const mutations = {};

//  as AlertActions
const actions = {
  async createAlert(context, alert: Alerts.Alert) {
    AlertsModule.updateState({
      content: alert,
      showAlert: true,
    });
  },
  async deleteAlert() {
    AlertsModule.updateState({
      content: null,
      showAlert: false,
    });
  },
};

// Module
export const AlertsModule = defineModule('AlertsModule', state, {
  actions,
  mutations,
});
