import { setHours, setMinutes, setSeconds, format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';

export function formatHoursAndMinutes(date: Date): Date {
  return setSeconds(setMinutes(setHours(date, 0), 0), 0);
}

export function formatDateExport(): string {
  return format(new Date(), 'YYYY-MM-DD', { locale: frenchLocale }) ?? '';
}
