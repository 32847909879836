import { defineModule } from '@/vuex-typed-modules';
import { IzipCodeType } from '@models';
import { sgts } from '@services';
import axios from 'axios';

export interface ISharedState {
  publicHolidays: Date[];
  allZipCodes: IzipCodeType[];
}

const initialState: ISharedState = {
  publicHolidays: [],
  allZipCodes: [],
};

//State
const state: ISharedState = {
  ...initialState,
  publicHolidays: [],
  allZipCodes: [],
};

const getters = {};
const mutations = {};

const actions = {
  async fetchPublicHolidays() {
    const res = await sgts.getPublicHolidays().$fetch();
    if (res && res.length > 0) {
      SharedModule.updateState({ publicHolidays: res.map((x) => new Date(x)) });
    }
  },
  async fetchZipCodes() {
    if (SharedModule.state.allZipCodes.length === 0) {
      const res = await sgts.getZipCodes().$fetch();
      if (res) {
        const formatedResult = JSON.parse(res);
        SharedModule.updateState({ allZipCodes: formatedResult });
      }
    }
  },
};

export const SharedModule = defineModule('SharedModule', state, {
  mutations,
  actions,
  getters,
});
