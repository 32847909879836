var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-box",class:{
    noMargin: _vm.data.noMargin,
    halfWidth: _vm.data.halfWidth,
    formError: _vm.formError,
    formValid: _vm.formValid,
    disabled: _vm.data.disabled,
    focus: _vm.isFocused,
  },style:({ width: _vm.data.width, maxWidth: _vm.data.width })},[(_vm.label)?_c('label',{staticClass:"input-label",attrs:{"for":_vm.formId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-container"},[(_vm.data.icon)?_c('div',{staticClass:"icon"},[_c('SvgIcon',{attrs:{"src":_vm.data.icon,"size":22,"color":( _obj = {}, _obj[_vm.css.green] = _vm.formValid, _obj[_vm.css.red1] = _vm.formError, _obj[_vm.css.blue] = !_vm.formValid && !_vm.formValid && _vm.isFocused, _obj[_vm.css.g90] = true, _obj )}})],1):_vm._e(),_c('textarea',{ref:"input",staticClass:"input-form",attrs:{"id":_vm.formId,"type":_vm.data.type,"readonly":_vm.data.noEdit,"placeholder":_vm.placeholder,"disabled":_vm.data.disabled},domProps:{"value":_vm.formatedValue},on:{"focus":function($event){return _vm.handleFocus()},"blur":function($event){return _vm.handleBlur()},"input":function($event){return _vm.updateValue($event.target.value)}}})]),(_vm.vl && _vm.data.error)?_c('FormError',{attrs:{"vl":_vm.vl,"data":_vm.data}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }