
































import { Component, Vue } from 'vue-property-decorator';
import { FormContainer, InfoMessage } from '@components';
import { required, email } from 'vuelidate/lib/validators';
import { ErrorNotification, Forms, SuccessNotification } from '@constructors';
import { routesNames } from '@router';
import { AuthModule } from '@store';

@Component({
  components: { FormContainer, InfoMessage },
})
export default class ForgotPassword extends Vue {
  public submitting = false;
  public formError = false;
  public formErrorMessage = null;
  public connexionForm = null;

  get appName() {
    return process.env.NUXT_ENV_APP_NAME;
  }

  redirect() {
    this.$router.push({ path: routesNames.CONNEXION });
  }
  async submitForm() {
    const form = this.connexionForm.getValues();
    this.formError = false;
    this.formErrorMessage = null;
    try {
      this.submitting = true;
      await AuthModule.actions.requestResetUserPasswordWithEmail(form.email);
      new SuccessNotification('Un e-mail a été envoyé');
    } catch (e) {
      var alert = new ErrorNotification(e);
    } finally {
      this.submitting = false;
    }
  }

  created() {
    this.connexionForm = new Forms.Form({
      editMode: true,
      fields: {
        email: new Forms.TextForm({
          placeholder: 'Email',
          error: false,
          icon: 'icons/Forms/email.svg',
          value: this.$route.query.email as string,
        }),
      },
      validations: {
        email: { required, email },
      },
    });
  }
}
// admin : jawad@totem.paris Testtest1
// directeur :
