import gql from 'graphql-tag';

export const documentFragment = gql`
  fragment documentFragment on Document {
    id
    type
    status
    format
    expirationDate
    required
    comment
    reason
    comment
    updatedAt
  }
`;

export const cafDocumentFragment = gql`
  fragment cafDocumentFragment on CafDocument {
    id
    format
    title
    updatedAt
  }
`;

export const partialCustomerFragment = gql`
  fragment partialCustomerFragment on Customer {
    id
    firstName
    lastName
    isJobSeeker
    isResident
    createdAt
    reservations {
      id
      nursery {
        id
      }
    }
    picture {
      id
      url
    }
  }
`;

export const customerExtendedFragment = gql`
  fragment customerExtendedFragment on CustomerExtended {
    id
    firstName
    lastName
    documents {
      ...documentFragment
    }
    createdAt
    nurseryNumber
    reservations {
      id
    }
    bans {
      id
      description
      createdAt
      reason
      nursery {
        id
        name
      }
    }
    isBanned
    children {
      id
      documents {
        ...documentFragment
      }
    }
    picture {
      id
      url
    }
    updatedAt
    isJobSeeker
    isResident
  }
  ${documentFragment}
`;

export const registeredFragment = gql`
  fragment registeredFragment on Registered {
    id
    email
    firstName
    lastName
    address
    zipCode
    phone
    password
    price
    isBanned
    isJobSeeker
    isResident
    pending
    validated
    documentStatus
    createdAt
    updatedAt
  }
`;

export const childWithoutParentFragment = gql`
  fragment childWithoutParentFragment on Child {
    id
    firstName
    lastName
    picture {
      id
      url
    }
    notes
    birthDate
    allergies
    documents {
      ...documentFragment
    }
  }
  ${documentFragment}
`;

export const childFragment = gql`
  fragment childFragment on Child {
    id
    firstName
    lastName
    picture {
      id
      url
    }
    parent {
      id
      firstName
      lastName
      isJobSeeker
      isResident
      price
      email
      phone
      address
      documents {
        ...documentFragment
      }
      reservations {
        id
        date
      }
      picture {
        id
        url
      }
    }
    documents {
      ...documentFragment
    }
    notes
    birthDate
    allergies
  }
  ${documentFragment}
`;

export const childValidationFragment = gql`
  fragment childValidationFragment on Child {
    id
    firstName
    lastName
    picture {
      id
      url
    }
    parent {
      id
      firstName
      lastName
      isJobSeeker
      isResident
      price
      email
      phone
      address
      zipCode
      city
      documents {
        ...documentFragment
      }
      picture {
        id
        url
      }
    }
    documents {
      ...documentFragment
    }
    registrations {
      id
      status
      nursery {
        name
      }
    }
    notes
    birthDate
    allergies
    createdAt
  }
  ${documentFragment}
`;

export const fullCustomerFragment = gql`
  fragment fullCustomerFragment on Customer {
    id
    firstName
    email
    lastName
    createdAt
    address
    zipCode
    city
    phone
    price
    isBanned
    isJobSeeker
    isResident
    banReason
    banComment
    bans {
      id
      reason
      description
      nursery {
        id
        name
      }
    }
    picture {
      id
      url
    }
    documents {
      ...documentFragment
    }
    children {
      ...childWithoutParentFragment
    }
  }
  ${childWithoutParentFragment}
  ${documentFragment}
`;

export const customerWithRegistrationFragment = gql`
  fragment customerWithRegistrationFragment on CustomerWithRegistration {
    id
    firstName
    email
    lastName
    createdAt
    address
    zipCode
    city
    phone
    isBanned
    isJobSeeker
    isResident
    companyFirst
    companySecond
    companyCode
    price
    banComment
    banReason
    bans {
      id
      reason
      description
      nursery {
        id
        name
      }
    }
    registrations {
      id
      createdAt
      status
      registrationDate
      nursery {
        id
        name
      }
    }
    picture {
      id
      url
    }
    documents {
      ...documentFragment
    }
    cafDocuments {
      ...cafDocumentFragment
    }
    children {
      ...childWithoutParentFragment
    }
  }
  ${childWithoutParentFragment}
  ${documentFragment}
  ${cafDocumentFragment}
`;

export const banFragment = gql`
  fragment banFragment on Ban {
    id
    nursery {
      id
      name
    }
    customer {
      id
      email
      firstName
      lastName
      isJobSeeker
      isResident
      documents {
        ...documentFragment
      }
      children {
        documents {
          ...documentFragment
        }
      }
    }
    reason
    description
    createdAt
  }
  ${documentFragment}
`;

export const bannedCustomerFragment = gql`
  fragment bannedCustomerFragment on CustomerExtended {
    id
    email
    firstName
    lastName
    isJobSeeker
    isResident
    documents {
      ...documentFragment
    }
    children {
      documents {
        ...documentFragment
      }
    }
    bans {
      ...banFragment
    }
  }
  ${documentFragment}
  ${banFragment}
`;

export const billingFragment = gql`
  fragment billingFragment on Billing {
    id
    updatedAt
    date
    extension
    name
    uuid
    customer {
      id
    }
  }
`;

export const childAdaptationFormFragment = gql`
  fragment childAdaptationForm on ChildAdaptationForm {
    id
    child {
      id
    }
    foodRestriction
    foodRestrictionDescription
    breastfed
    hygiene
    childAdaptationFormMotorSkills {
      id
      motorSkills
    }
    morningSleepTime
    afternoonSleepTime
    allergy
    allergyDescription
    comment
    createdAt
    updatedAt
  }
`;
