



























































































import { Vue, Prop } from 'vue-property-decorator';
import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';
import SearchField from '../Display/SearchField.vue';
import Popup from '../Display/Popup.vue';
import { Forms } from '@constructors';
import InfiniScroll from '../Display/InfiniScroll.vue';
import { debounce } from 'lodash';

@Component({
  components: {
    FormError,
    InfiniScroll,
    SearchField,
  },
})
export default class FormSelectMultiple extends FormMixin {
  @Prop() value: any[];
  data: Forms.FormPayload<any> & Forms.ISelectMultiplePayload<any>;

  public resultSelected = 0;
  public searchValue = '';
  public searchResults: Forms.IOptions[] = [];
  public page = 1;
  public hasNextPage = true;
  public loading = false;
  public filterSearch = '';
  public cachedDisplayValues: Forms.IOptions[] = [];

  public handleInputUpdate: (value: string) => void = null;

  get isDynamic() {
    return !!this.data.handler;
  }

  get formatedDisplayedValues() {
    return this.cachedDisplayValues.filter((cached) =>
      this.value.find((val) => cached.value === val)
    );
  }

  handleFocus() {
    if (this.searchResults.length || this.searchValue) {
      this.$refs.popup.showPopup();
    }
    this.isFocused = true;
  }

  handleBlur() {
    this.$refs.popup.closePopup();
    this.isFocused = false;
  }

  get searchResultsFiltered() {
    return this.searchResults
      .filter((result) => {
        return !this.value.find((item) => item === result.value);
      })
      .slice(0, 4);
  }

  async handleSearch(value: string) {
    try {
      this.loading = true;
      this.page = 2;
      this.resultSelected = 0;
      const { edges, pageInfo } = await this.data.handler({
        page: 1,
        where: this.data.search(value),
        limit: 6,
        ...this.data.handlerParams,
      });
      this.hasNextPage = pageInfo.hasNextPage;
      this.newContent(edges);
    } finally {
      this.loading = false;
    }
  }

  newContent(edges: any[]) {
    this.$refs.popup.showPopup();
    this.searchResults = edges.map(this.data.formater);
  }

  addSelectedResult() {
    this.selectOption(this.searchResultsFiltered[this.resultSelected]);
  }

  removeItem(value: string) {
    const newValue = this.value.filter((f) => f !== value);
    this.cachedDisplayValues = this.cachedDisplayValues.filter((f) => f.value !== value);
    this.updateValue(newValue);
  }

  updateSelectedResult(event: KeyboardEvent) {
    let touche = event.which - 39;
    if (
      this.resultSelected + touche >= 0 &&
      this.resultSelected + touche <= this.searchResultsFiltered.length - 1
    ) {
      this.resultSelected += touche;
    }
  }

  created() {
    this.handleInputUpdate = debounce((value) => {
      if (value.length > 0) {
        this.handleSearch(value);
      } else if (value.length === 0) {
        this.$refs.popup.closePopup();
        this.searchResults = [];
      }
    }, this.data.debounce);
  }

  async beforeMount() {
    if (this.data.displayedValues) {
      this.cachedDisplayValues = this.data.displayedValues;
    }
  }

  selectOption({ value, text, icon }: Forms.IOptions) {
    if (!this.value.includes(value)) {
      const newValue = this.value;
      newValue.push(value);
      this.updateValue(newValue);
      this.cachedDisplayValues.push({ value, text, icon });
      this.searchValue = '';
      this.searchResults = [];
      this.resultSelected = 0;
      this.$refs.popup.closePopup();
      this.$refs.input.focus();
    }
  }
}
