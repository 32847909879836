









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import shortid from 'shortid';

@Component({})
export default class UISwitch extends Vue {
  @Prop()
  value: boolean;
  @Prop()
  loading: boolean;

  changeValue() {
    this.$emit('input', !this.value);
    this.$emit('switch', !this.value);
  }
}
