import {
  registrationsConnectionQuery,
  reservationsConnectionQuery,
  updateDocumentMutation,
} from '@graphql';
import {
  IregistrationsConnectionArgs,
  IreservationsConnectionArgs,
  IupdateDocumentArgs,
} from '@models';
import { ErrorNotification, SuccessNotification } from '@constructors';

export const getNurseryRegistrations = async (payload: IregistrationsConnectionArgs) => {
  try {
    return await registrationsConnectionQuery(payload);
  } catch (e) {
    var doNotExist = new ErrorNotification('Erreur lors de la récupération des inscrits');
  }
};

export const getNurseryReservations = async (payload: IreservationsConnectionArgs) => {
  try {
    return await reservationsConnectionQuery(payload);
  } catch (e) {
    var doNotExist = new ErrorNotification('Erreur lors de la récupération des reservations');
  }
};

export const updateDocument = async (payload: IupdateDocumentArgs) => {
  try {
    const document = await updateDocumentMutation(payload);
    new SuccessNotification('Document modifié');
    return document;
  } catch (e) {
    var doNotExist = new ErrorNotification('Erreur lors de la modification du document');
  }
};
