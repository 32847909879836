





































































































import { Forms } from '@constructors';
import { uploadPictureMutation } from '@graphql';
import { IVariable } from '@models';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Bold,
  BulletList,
  Code,
  HardBreak,
  Image,
  Italic,
  ListItem,
  Mention,
  OrderedList,
  Underline,
} from 'tiptap-extensions';
import { Component } from 'vue-mixin-decorator';
import { FormMixin } from '../../../Mixins/FormMixin';
import FormError from '../FormError.vue';
import FormUpload from '../FormUpload.vue';
import ButtonExtension from './ButtonExtension.vue';

@Component({
  components: {
    FormError,
    EditorContent,
    EditorMenuBar,
    ButtonExtension,
    FormUpload,
  },
})
export default class RichText extends FormMixin {
  value: string;
  data: Forms.IRichTextPayload<any>;

  public editor: any = null;
  public htmlContent: string = '';
  public imageImport = new Forms.UploadForm<File>({
    placeholder: 'Importer une image',
    noMargin: true,
    noBorder: true,
  }).extract();

  public query = null;
  public suggestionRange = null;
  public filteredUsers = [];
  public navigatedUserIndex = 0;
  public insertMention = () => {};
  public observer = null;

  async addImage(commands) {
    const picture = await uploadPictureMutation(this.imageImport.value);
    commands.image({ src: picture.url });
    this.imageImport.reset();
    this.$refs.imagePopup.closePopup();
  }

  get variables() {
    return this.data.emailVariables;
  }

  emitChange(value: string) {
    this.$emit('input', value);
  }

  public setValue(value: string) {
    this.editor.setContent(value);
  }

  public resetValue() {
    this.editor.setContent(this.value);
  }

  // @Watch('value')
  // valueChanged(value) {
  //   this.editor.(value);
  // }

  created() {
    try {
      this.htmlContent = this.value as string;
      this.editor = new Editor({
        extensions: [
          new BulletList(),
          new OrderedList(),
          new Bold(),
          new Image(),
          new HardBreak(),
          new ListItem(),
          new Code(),
          new Italic(),
          new Underline(),
          ...(this.data.emailVariables
            ? [
                new Mention({
                  items: () => this.data.emailVariables,
                  matcher: {
                    char: '#',
                  },
                }),
              ]
            : []),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          this.emitChange(getHTML());
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
  addVariable(variable: IVariable) {
    this.editor.commands.mention({
      id: variable.id,
      label: `{${variable.name}}`,
    });
  }

  destroyed() {
    this.editor.destroy();
  }
}
