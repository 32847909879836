









import { Component, Vue, Prop } from 'vue-property-decorator';
import { HeaderComponent } from '@components';

@Component({
  components: { HeaderComponent },
})
export default class Admin extends Vue {}
