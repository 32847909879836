
















































import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';

@Component({
  components: {
    FormError,
  },
})
export default class FormField extends FormMixin {}
