









































































import { Component } from 'vue-mixin-decorator';
import { Forms, Alerts } from '@constructors';
import { FormSelect } from '@components';
import { required, decimal, minValue } from 'vuelidate/lib/validators';
import { routesNames } from '@router';
import { NurseryModule } from '@store';
import { InfiniScrollMixin } from '@components';
import {
  INursery,
  INurseryConnectionWhereInput,
  INurseryType,
  INurseryWithRevenues,
  INurseryConnectionFilterEnum,
  InurseriesConnectionArgs,
  INurseryConnectionWithRevenues,
} from '@models';
import { Location } from 'vue-router';
import { nurseryConfigQuery } from '@graphql';
import { priceValidator, minPrice } from '@validators';

@Component({
  components: { FormSelect },
})
export default class CrechesList extends InfiniScrollMixin<
  INurseryConnectionWithRevenues,
  InurseriesConnectionArgs
> {
  public module = NurseryModule;
  public nurseryConfig: number = null;
  public columns = [
    'Nom de la crèche',
    'Demandes en attente',
    'Réservations en attente',
    'Demandes traitées',
    "Chiffre d'affaires",
    '',
  ];
  public filterby = new Forms.Select<INurseryConnectionFilterEnum>({
    placeholder: 'Trier par',
    width: '250px',
    noMargin: true,
    value: 'NAME',
    options: [
      { value: 'NAME', text: 'Nom de crèche' },
      { value: 'REGISTRATION_PENDING', text: "Demandes d'inscription en attente" },
      { value: 'RESERVATION_PENDING', text: 'Demandes de réservations en attente' },
      { value: 'REQUEST_HANDLED', text: 'Demandes traitées' },
      { value: 'REVENUES', text: "Chiffre d'affaire" },
    ],
  }).extract();

  get nurseryLink(): (id) => Location {
    return (id) => ({
      name: routesNames.admin.CRECHEDETAIL.DETAIL,
      params: { id },
    });
  }

  get crechesInactivesLink() {
    return { name: routesNames.admin.CRECHES_INACTIVES };
  }

  public listHandler = this.module.actions.getNurseries;
  get handlerParams(): InurseriesConnectionArgs {
    return {
      where: {
        search: this.search,
        orderBy: this.filterby.value || undefined,
        active: true,
      },
    };
  }

  get nurseriesList() {
    return NurseryModule.state.nurseries.map((m) => ({
      ...m,
      revenues: ~~m.revenues,
    }));
  }

  modifyPricing() {
    var modify = new Alerts.FormAlert({
      title: 'Modifier la tarification conventionnelle',
      description: 'Séléctionner le nouveau tarif',
      formElement: {
        form: new Forms.Form({
          fields: {
            price: new Forms.TextForm<any>({
              editMode: true,
              value: this.nurseryConfig.toFixed(2),
              label: 'par demi-journée (en €)',
              valueType: 'price',
            }),
          },
          validations: {
            price: { required, decimal, minPrice: minPrice(1) },
          },
        }),
        submit: {
          handler: async (data) => {
            await NurseryModule.actions.updateNurseryConfig(data);
            this.nurseryConfig = data.form.price;
          },
          text: 'Mettre à jour',
        },
      },
    });
  }

  async created() {
    const { price } = await nurseryConfigQuery();
    this.nurseryConfig = price;
  }
  destroyed() {
    NurseryModule.updateState({
      nurseries: [],
    });
  }
}
