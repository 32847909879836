
































import { Component, Vue } from 'vue-property-decorator';
import { FormContainer, InfoMessage, FormSelect } from '@components';
import { helpers, required } from 'vuelidate/lib/validators';
import { CronModule } from '@store';
import { Forms, SuccessNotification } from '@constructors';
import { ICronEnum } from '@models';

@Component({
  components: { FormContainer, InfoMessage, FormSelect },
})
export default class ExecuteCron extends Vue {
  public submitting = false;
  public formError = false;
  public formErrorMessage = null;
  public query: { token: string; id: string } = null;

  isAdminPassWord = (value) => !helpers.req(value) || value === 'YDMeFz3bStJrL3P';

  public cronForm = new Forms.Form({
    fields: {
      password: new Forms.TextForm({
        type: 'password',
        placeholder: 'Mot de passe',
        error: false,
        icon: 'icons/Forms/password.svg',
      }),
    },
    validations: {
      password: { required, validPassword: this.isAdminPassWord },
    },
  });

  public cronList = new Forms.Select<ICronEnum>({
    placeholder: 'Selectionner un CRON',
    width: '100%',
    options: [
      { value: 'CRON_DISPONIBILITIES', text: 'Disponibilité' },
      { value: 'CRON_CLOSING_DATES', text: 'Dates de fermeture' },
      { value: 'CRON_NEW_NURESERY', text: 'Nouvelles créches' },
      { value: 'CRON_UPDATE_NURESERY', text: 'Mise à jour de créches' },
      { value: 'CRON_UPDATE_COMMUNITY_NURESERY', text: 'Mise à jour de créches Collectivité' },
      { value: 'CRON_UPDATE_EFFORT_RATE', text: 'Mise à jour des tariffs PSU' },
      {
        value: 'CRON_REMOVE_DOCUMENT_CAF_IMMATRICULATION',
        text: 'Supprimer les documents de la caf',
      },
      { value: 'CRON_SET_SUMMER_CAMP', text: 'Appliquer dates Summer camp' },
    ],
  }).extract();

  get appName() {
    return process.env.VUE_APP_NAME;
  }

  async submitForm() {
    const form = this.cronForm.getValues();
    this.formError = false;
    this.formErrorMessage = null;

    try {
      this.submitting = true;
      await CronModule.actions.executeCron({ name: this.cronList.value, password: form.password });
      new SuccessNotification('CRON exécuter!');
    } catch (e) {
      console.log(e);
      this.formError = true;
      this.formErrorMessage = e;
    } finally {
      this.submitting = false;
    }
  }
}
