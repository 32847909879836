























import { Store } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus, Events } from '@services';
import { AlertsContainer, ProgressBar, Notifications } from '@components';
import { AlertsModule, AuthModule, NotificationsModule } from '@store';

@Component({
  components: {
    AlertsContainer,
    ProgressBar,
    Notifications,
  },
})
export default class App extends Vue {
  public refreshing = false;
  public registration = null;

  get authChecked() {
    return AuthModule.state.authChecked;
  }

  get alertState() {
    return AlertsModule.state.showAlert;
  }

  refreshApp() {
    if (!this.registration || !this.registration.waiting) {
      return;
    }
    this.registration.waiting.postMessage('skipWaiting');
  }

  created() {
    document.addEventListener('touchstart', (e) => {}, { passive: true });
    document.addEventListener('touchmove', (e) => {}, { passive: true });
    document.addEventListener('swUpdated', this.refreshApp, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      NotificationsModule.actions.addNotification({
        type: 'action',
        message: "Une nouvelle version de l'application est disponible",
        action: {
          trigger: () => window.location.reload(),
          actionMessage: 'Recharger la page',
        },
      });
    });
  }
}
