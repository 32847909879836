





























































































import { Vue, Prop } from 'vue-property-decorator';
import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import { FormMixin } from '../../../Mixins/FormMixin';
import FormError from '../FormError.vue';
import SearchField from '../Display/SearchField.vue';
import Popup from '../../Display/Popup.vue';
import { Forms } from '@constructors';
import Calendar from './Calendar.vue';
import { format } from 'date-fns';

@Component({
  components: {
    FormError,
    Calendar,
  },
})
export default class FormCalendar extends FormMixin {
  value: Forms.ICalendarValue;
  data: Forms.FormPayload<any> & Forms.ICalendarPayload;

  public cachedDisplayValue: Forms.ISideListLabel = 'Personnalisé';

  get getDisplayValue(): string | { start?: string; end?: string } {
    if (this.value) {
      if (this.isCalendarNormal(this.value)) {
        return format(this.value, 'DD/MM/YYYY');
        // if (this.data.type === 'date') {

        // } else if (this.data.type === 'datetime-local') {
        //   return format(this.value, 'DD/MM/YYY HH:mm');
        // } else {
        //   return format(this.value, 'HH:mm');
        // }
      } else {
        if (!this.value.start && !this.value.end) {
          return null;
        } else {
          if (this.cachedDisplayValue && this.cachedDisplayValue !== 'Personnalisé') {
            return this.cachedDisplayValue;
          }
          return {
            start: this.value.start ? format(this.value.start, 'DD/MM/YYYY') : null,
            end: this.value.end ? format(this.value.end, 'DD/MM/YYYY') : null,
          };
        }
      }
    } else {
      return null;
    }
  }

  handleCalendarSelect(item: Forms.ICalendarValue) {
    this.updateValue(item);
    if (this.isCalendarNormal(this.value)) {
      this.$refs.popup.closePopup();
    }
  }
  handleCalendarSideSelect(item: Forms.ISideListItem) {
    this.updateValue(item.actionValue);
    this.cachedDisplayValue = item.label;
  }

  isCalendarNormal(value: Forms.ICalendarValue): value is Date {
    return this.data.calendarType === 'normal';
  }
  isCalendarPeriod(value: Forms.ICalendarValue): value is Forms.ICalendarPeriodType {
    return this.data.calendarType === 'period';
  }

  created() {
    this.cachedDisplayValue = this.data.selectedSideListItem;
  }
}
