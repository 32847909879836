


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICustomer, INursery } from '@models';
import { Forms, ExtractedFormElement, SuccessNotification, ErrorNotification } from '@constructors';
import { sgts } from '@services';
import FormText from '../Forms/FormText.vue';
import { AuthModule } from '../../../store';
import { required, numeric } from 'vuelidate/lib/validators';
import { exactLength } from '../../../utils/validators';

@Component({
  components: {
    FormText,
  },
  validations() {
    return {
      form: {
        value: { required, numeric, exactLength: exactLength(5) },
      },
    };
  },
})
export default class CodeFamille extends Vue {
  @Prop() customer: string;
  @Prop() nursery: string;

  public form: ExtractedFormElement<string> = null;
  public nurseryName: string = null;
  public code;

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  async getFamilyCode() {
    const result = await sgts
      .familyCode()
      .$fragment(`id codeFamily`)
      .$args({
        where: {
          customer: {
            id: this.customer,
          },
          nursery: {
            id: this.nursery,
          },
        },
      })
      .$fetch();
    this.fillForm(result?.codeFamily);
  }

  fillForm(value) {
    this.form = new Forms.TextForm({
      editMode: !!value,
      value,
      placeholder: 'Code Famille',
    }).extract();
  }

  async saveCode() {
    try {
      const result = await sgts
        .upsertFamilyCode()
        .$fragment('codeFamily')
        .$args({
          data: {
            codeFamily: this.form.value,
            customer: {
              id: this.customer,
            },
            nursery: {
              id: this.nursery,
            },
          },
        })
        .$post();
      new SuccessNotification('Code famille sauvegardé');
      this.fillForm(result?.codeFamily);
      this.$v.$reset();
    } catch (e) {
      console.log(e);
      var familyCode = new ErrorNotification('Code famille déjà utilisé');
    }
  }

  async getNursery() {
    const result = await sgts
      .nursery()
      .$fragment('id name')
      .$args({ where: { id: this.nursery } })
      .$fetch();
    this.nurseryName = result?.name;
  }

  created() {
    this.getFamilyCode();
    this.getNursery();
  }
}
