import gql from 'graphql-tag';
export const communityNurseryTemplateAvailabilitiesFragment = gql`
  fragment communityNurseryTemplateAvailabilities on CommunityNurseryTemplateAvailability {
    id
    analyticCode
    nursery {
      id
    }
    type
    code
    capacity
    remainingPlaces
    day
    createdAt
    updatedAt
  }
`;

export const communityNurseryZipCodesFragment = gql`
  fragment communityNurseryZipCodesFragment on CommunityNurseryZipCode {
    id
    nursery {
      id
    }
    zipCode
    createdAt
    updatedAt
  }
`;
