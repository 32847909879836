






































































import { CheckBox, FormCalendar, InfiniScrollMixin, RadioTabs } from '@components';
import { registrationStatusConstant, reservationTypeConstant } from '@constants';
import { Alerts, ErrorNotification, Forms, SuccessNotification } from '@constructors';
import { reservationFragment, receipFragment } from '@fragments';
import {
  IReservation,
  IReservationConnection,
  IReservationStatus,
  IreservationsConnectionArgs,
} from '@models';
import { DirectorNurseryModule } from '@store';
import { formatDateExport } from '@utils';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Component, Watch } from 'vue-property-decorator';
import { decimal, minLength, required } from 'vuelidate/lib/validators';
import { sgts } from '../../../services';
import { exactLength } from '../../../utils/validators';

@Component({
  components: {
    RadioTabs,
    FormCalendar,
    CheckBox,
  },
})
export default class Paiements extends InfiniScrollMixin<
  IReservationConnection,
  IreservationsConnectionArgs
> {
  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };
  public columns = [
    'Enfant',
    'Date de naissance',
    'Date de réservation',
    'Type',
    'Référence',
    "Date d'encaissement",
    'Prix total',
    'Frais de garde',
    'Frais de Gestion',
    'Chéque E-CESU',
    'Montant E-CESU',
    'Numéro de demande/remise',
    'Modifier/Supprimer le chéque E-CESU',
  ];

  public reservations: IReservation[] = [];
  declare oldPage: number;
  public listHandler = (args) =>
    sgts.reservationsConnection().$fragment(reservationFragment).$args(args).$fetch();

  get handlerParams(): IreservationsConnectionArgs {
    return {
      where: {
        startDate: this.calendar.value.start,
        endDate: new Date(
          this.calendar.value.end?.getFullYear(),
          this.calendar.value.end?.getMonth(),
          this.calendar.value.end?.getDate() + 1
        ),
        nursery: { id: DirectorNurseryModule.state.nursery.id },
        noRegistration: true,
        status: 'DONE',
      },
      limit: 20,
    };
  }

  public calendar = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '260px',
  }).extract();

  getNew() {
    if (this.calendar.value.start && this.calendar.value.end) {
      this.newSearch();
    }
  }

  get statusConstant() {
    return (status: IReservationStatus) => registrationStatusConstant[status];
  }

  // get redirectRoute() {
  //   return (id: string) => ({
  //     name: this.redirect,
  //     params: {
  //       demandeId: id,
  //     },
  //     query: {
  //       type: 'reservation',
  //     },
  //   });
  // }

  newContent(data: IReservation[]) {
    this.reservations = data;
  }

  get reservationsFiltered() {
    return this.reservations.map((m) => {
      const receipt = m.receipts.find((f) => f.status === 'SUCCESS');
      let paymentDate = receipt.date.split(':')[0].split('/');
      return {
        ...m,
        child: `${m.child.firstName} ${m.child.lastName}`,
        birthdate: format(m.child.birthDate, 'DD/MM/YYYY'),
        ...(receipt && {
          paiementDate: format(
            new Date(+paymentDate[2], +paymentDate[1] - 1, +paymentDate[0]),
            'DD/MM/YYYY'
          ),
          validDate: new Date(+paymentDate[2], +paymentDate[1] - 1, +paymentDate[0]).setHours(
            0,
            0,
            0,
            0
          ),
          reference: receipt.reference,
          isCesuActive: receipt.isCesuActive,
          eCesuAmount: receipt.eCesuAmount || null,
          eCesuCode: receipt.eCesuCode,
        }),
        reservation: `${format(m.date, 'DD MMM YYYY', { locale: frLocale })}`,
        type: reservationTypeConstant[m.type],
      };
    });
  }

  get listFiltredByPayment() {
    return this.reservationsFiltered.filter(
      (x) =>
        new Date(x.validDate) >= this.calendar.value.start &&
        new Date(x.validDate) <= this.calendar.value.end
    );
  }

  async exportPayments() {
    const csvContent = await sgts
      .exportPayments()
      .$args({
        where: {
          id: DirectorNurseryModule.state.nursery.id,
        },
        start: this.calendar.value.start,
        end: this.calendar.value.end,
      })
      .$fetch();
    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Paiements_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  editCegid() {
    const nursery = DirectorNurseryModule.state.nursery;
    var alert = new Alerts.FormAlert({
      title: 'Éditer le code SAGE X3 de la crèche',
      formElement: {
        form: new Forms.Form({
          fields: {
            cegidCode: new Forms.TextForm({
              value: nursery.cegidCode,
              editMode: !!nursery.cegidCode,
              label: 'Code SAGE X3 de la crèche',
            }),
          },
          validations: {
            cegidCode: { required, exactLength: exactLength(3) },
          },
        }),
        submit: {
          handler: async (data) => {
            try {
              const { cegidCode } = await sgts
                .updateNursery()
                .$fragment('cegidCode')
                .$args({
                  id: nursery.id,
                  data: {
                    cegidCode: data.form.cegidCode,
                  },
                })
                .$post();
              DirectorNurseryModule.mutations.updateNursery({
                cegidCode,
              });
              new SuccessNotification('Code SAGE modifié');
            } catch (e) {
              var modificationError = new ErrorNotification(
                'Erreur lors de la modification du code SAGE X3'
              );
            }
          },
        },
      },
    });
  }

  resetEcesu = async (data: {
    reference: any;
    isCesuActive: any;
    eCesuAmount: any;
    eCesuCode: any;
  }) => {
    var alert = new Alerts.FormAlert({
      type: 'error',
      title: 'Désactiver le code chéque E-CESU',
      description: 'Étes vous sur de vouloir supprimer ce chèque E-CESU',
      formElement: {
        form: new Forms.Form({
          fields: {
            schedule: {},
          },
        }),
        submit: {
          handler: async () => {
            try {
              await sgts
                .updateEcesu()
                .$fragment(receipFragment)
                .$args({
                  data: {
                    reference: data.reference,
                    isCesuActive: false,
                  },
                })
                .$post();
              this.oldPage = this.page;
              this.getNew();
              new SuccessNotification('Chéque E-CESU désactivé');
            } catch (e) {
              var modificationError = new ErrorNotification(
                'Erreur lors de la modification du Chéque E-CESU'
              );
            }
          },
        },
      },
    });
  };

  editEcesu(data: { reference: any; isCesuActive: any; eCesuAmount: any; eCesuCode: any }) {
    var alert = new Alerts.FormAlert({
      title: 'Éditer le code chéque E-CESU',
      formElement: {
        form: new Forms.Form({
          fields: {
            schedule: {
              reference: new Forms.Label(`Référence du paiment: ${data.reference}`),
              isCesuActive: new Forms.Label(`Activer le chéque E-CESU`),
              eCesuAmount: new Forms.TextForm({
                value: data.eCesuAmount,
                editMode: true,
                label: 'Montant E-CESU',
              }),
              eCesuCode: new Forms.TextForm({
                value: data.eCesuCode,
                editMode: true,
                label: 'Numéro de demande/remise',
              }),
            },
          },
          validations: {
            schedule: {
              eCesuAmount: { required, minLength: minLength(1), decimal },
              eCesuCode: { required, minLength: minLength(3) },
            },
          },
        }),
        submit: {
          handler: async (element) => {
            try {
              await sgts
                .updateEcesu()
                .$fragment(receipFragment)
                .$args({
                  data: {
                    reference: data.reference,
                    ...element.form.schedule,
                    isCesuActive: true,
                    eCesuAmount: +element.form.schedule.eCesuAmount,
                  },
                })
                .$post();
              this.oldPage = this.page;
              this.getNew();
              new SuccessNotification('Chéque E-CESU modifié');
            } catch (e) {
              var modificationError = new ErrorNotification(
                'Erreur lors de la modification du Chéque E-CESU'
              );
            }
          },
        },
      },
    });
  }
}
