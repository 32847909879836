



























import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getDate,
  isSameMonth,
  isSameDay,
  isAfter,
  isBefore,
  isMonday,
  isSunday,
  isWithinRange,
} from 'date-fns';
import { Forms } from '@constructors';

@Component({})
export default class DateItem extends Vue {
  @Prop({ required: true }) date: Date;
  @Prop() currentMonth: Date;
  @Prop({ type: [Object, Date] }) selectedDate: Forms.ICalendarValue;
  @Prop(String) type: Forms.ICalendarType;
  @Prop() hoveredDate: Date;
  @Prop() min: Date;
  @Prop() max: Date;

  get formattedDate() {
    return getDate(this.date);
  }

  get isSelected() {
    if (this.isCalendarNormal(this.selectedDate)) {
      return isSameDay(this.date, this.selectedDate);
    } else {
      return (
        isSameDay(this.date, this.selectedDate?.start) ||
        isSameDay(this.date, this.selectedDate?.end)
      );
    }
  }

  get isStart() {
    if (this.isCalendarNormal(this.selectedDate)) {
      return false;
    } else {
      if (this.hoveredDate && this.isSelecting) {
        if (!this.isSelected) {
          return (
            isBefore(this.hoveredDate, this.selectedDate?.start) &&
            isSameDay(this.hoveredDate, this.date)
          );
        } else {
          if (isSameDay(this.hoveredDate, this.date)) {
            return isSameDay(this.date, this.selectedDate?.start);
          } else {
            return (
              isBefore(this.selectedDate?.start || this.selectedDate?.end, this.hoveredDate) &&
              isSameDay(this.selectedDate?.start || this.selectedDate?.end, this.date)
            );
          }
        }
      } else {
        return isSameDay(this.date, this.selectedDate?.start);
      }
    }
  }

  get isEnd() {
    if (this.isCalendarNormal(this.selectedDate)) {
      return false;
    } else {
      if (this.hoveredDate && this.isSelecting) {
        if (!this.isSelected) {
          return (
            isAfter(this.hoveredDate, this.selectedDate?.start) &&
            isSameDay(this.hoveredDate, this.date)
          );
        } else {
          return (
            isAfter(this.selectedDate?.start || this.selectedDate?.end, this.hoveredDate) &&
            isSameDay(this.selectedDate?.start || this.selectedDate?.end, this.date)
          );
        }
      } else {
        return isSameDay(this.date, this.selectedDate?.end);
      }
    }
  }

  get isStartOfWeek() {
    return isMonday(this.date);
  }

  get isEndOfWeek() {
    return isSunday(this.date);
  }

  get isBetween(): boolean {
    if (this.isCalendarNormal(this.selectedDate)) {
      return false;
    } else {
      if (
        this.hoveredDate &&
        this.isSelecting &&
        !isSameDay(this.date, this.hoveredDate) &&
        !this.isEnd &&
        !this.isStart
      ) {
        if (this.selectedDate?.start) {
          if (isAfter(this.selectedDate?.start, this.hoveredDate)) {
            return isWithinRange(this.date, this.hoveredDate, this.selectedDate?.start);
          } else {
            return isWithinRange(this.date, this.selectedDate?.start, this.hoveredDate);
          }
        } else {
          if (isAfter(this.selectedDate?.end, this.hoveredDate)) {
            return isWithinRange(this.date, this.hoveredDate, this.selectedDate?.end);
          } else {
            return isWithinRange(this.date, this.selectedDate?.end, this.hoveredDate);
          }
        }
      } else if (this.selectedDate?.end) {
        return (
          !this.isEnd &&
          !this.isStart &&
          isWithinRange(this.date, this.selectedDate?.start, this.selectedDate?.end)
        );
      } else {
        return false;
      }
    }
  }

  get isSelecting() {
    if (this.isCalendarNormal(this.selectedDate)) {
      return false;
    } else {
      return (
        (this.selectedDate?.start && !this.selectedDate?.end) ||
        (!this.selectedDate?.start && this.selectedDate?.end)
      );
    }
  }

  get isPeriodCompleted() {
    if (this.isCalendarNormal(this.selectedDate)) {
      return false;
    } else {
      if (this.hoveredDate && this.isSelecting) {
        return (
          (!!this.selectedDate?.start || !!this.selectedDate?.end) &&
          !(isSameDay(this.hoveredDate, this.date) && this.isSelected)
        );
      } else {
        return !!this.selectedDate?.start && !!this.selectedDate?.end;
      }
    }
  }

  get isToday() {
    return isSameDay(this.date, new Date());
  }

  get isInCurrentMonth() {
    return isSameMonth(this.date, this.currentMonth);
  }

  handleSelectEvent() {
    if (this.isCalendarNormal(this.selectedDate)) {
      this.$emit('select', this.date);
    } else {
      if (this.isSelected) {
        this.$emit(`unselect:${this.isStart ? 'start' : 'end'}`);
      } else {
        this.$emit('select', this.date);
      }
    }
  }

  handleMouseEnter() {
    if (this.isSelecting) {
      this.$emit('mouseenter', this.date);
    }
  }
  handleMouseLeave() {
    if (this.isSelecting) {
      this.$emit('mouseleave', this.date);
    }
  }

  isCalendarNormal(value: Forms.ICalendarValue): value is Date {
    return this.type === 'normal';
  }
  isCalendarPeriod(value: Forms.ICalendarValue): value is { start: Date; end: Date } {
    return this.type === 'period';
  }
}
