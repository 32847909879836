














import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavLink } from '@models';

@Component({})
export default class Tabs extends Vue {
  @Prop() tabs: NavLink[];
}
