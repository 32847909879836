var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"flex w100 h100 pad50"},[_c('Spinner',{attrs:{"size":50}},[_vm._v("Récupération des informations de reporting")])],1):(_vm.reportingData)?_c('div',{staticClass:"reporting flex column w100"},[_c('div',{staticClass:"flex column padh10"},[_c('VButton',{attrs:{"size":"medium","handler":_vm.exportData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [(!loading)?_c('span',[_vm._v("Exporter les données")]):_c('span',[_vm._v("Téléchargement...")])]}}])})],1),_c('div',{staticClass:"table flex column padt30"},[_c('h2',{staticClass:"margb20",attrs:{"section":""}},[_vm._v("Activités")]),_c('TableGrid',{attrs:{"columns":_vm.columns,"grid-data":_vm.reportingActivity,"message":"Aucune données"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('tr',{key:row},[_c('td',{attrs:{"bold":""}},[_vm._v(_vm._s(_vm.keyConstant[row]))]),_c('td',[_vm._v(_vm._s(value.average.toFixed(2)))]),_c('td',[_vm._v(_vm._s(value.m1))]),_c('td',[_vm._v(_vm._s(value.m2))]),_c('td',[_vm._v(_vm._s(value.m3))]),_c('td',[_vm._v(_vm._s(value.m4))]),_c('td',[_vm._v(_vm._s(value.m5))]),_c('td',[_vm._v(_vm._s(value.m6))]),_c('td',[_vm._v(_vm._s(value.current))])])]}}])}),_c('h2',{staticClass:"margh20",attrs:{"section":""}},[_vm._v("Chiffre d'affaire (en €)")]),_c('TableGrid',{attrs:{"columns":_vm.columns,"grid-data":_vm.reportingCA,"message":"Aucune données"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var value = ref.value;
return [_c('tr',{key:row},[_c('td',{attrs:{"bold":""}},[_vm._v(_vm._s(_vm.keyConstant[row])+" €")]),_c('td',[_vm._v(_vm._s(value.average.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.m1.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.m2.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.m3.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.m4.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.m5.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.m6.toFixed(2))+" €")]),_c('td',[_vm._v(_vm._s(value.current.toFixed(2))+" €")])])]}}])}),_c('h2',{staticClass:"margh20",attrs:{"section":""}},[_vm._v("Graphiques")]),_c('div',{staticClass:"flex column padh40"},[_c('CAGraph',{staticClass:"h-400",attrs:{"data":_vm.CAgraphData}})],1),_c('div',{staticClass:"flex column padh40"},[_c('InscGraph',{staticClass:"h-400",attrs:{"inscriptions":_vm.inscriptions,"reservations":_vm.reservations}})],1)],1)]):_c('div',{staticClass:"flex center pad50"},[_vm._v("Aucune donnée à afficher")])}
var staticRenderFns = []

export { render, staticRenderFns }