import { sgts } from '@services';
import gql from 'graphql-tag';
import { IcommunityNurseryDashboardArgs, IdashboardArgs, IgetDocumentUrlArgs } from '@models';

const dashboardFragment = gql`
  fragment dashboard on Dashboard {
    processedRegistrations
    processedReservations
    pendingRegistrations
    pendingReservations
    nurseryNumber
    recentNurseries
    availabilities
    revenues
    validatedRegistrations
  }
`;

const dashboardCommunityFragment = gql`
  fragment dashboardCommunity on DashboardCommunity {
    processedRegistrations
    processedReservations
    pendingRegistrations
    pendingReservations
    nurseryNumber
    occupancyRate
    availabilities
    revenues
    validatedRegistrations
  }
`;

export const dashboardQuery = async (data: IdashboardArgs) => {
  return sgts.dashboard().$fragment(dashboardFragment).$args(data).$fetch();
};

export const communityNurseryDashboardQuery = async (data: IcommunityNurseryDashboardArgs) => {
  return sgts
    .communityNurseryDashboard()
    .$fragment(dashboardCommunityFragment)
    .$args(data)
    .$fetch();
};

export const getDocumentUrlQuery = async (data: IgetDocumentUrlArgs) => {
  return sgts.getDocumentUrl().$args(data).$fetch();
};
