/* eslint-disable */
import uuid from 'uuid/v4';
import { isTesting } from '@constants';
import { IErrorValidators } from '@validators';
import {
  IConnection,
  IAnyObject,
  ValidationRoot,
  IVariable,
  KeepProperties,
  ExludeProperties,
  IConnexionElement,
} from '@models';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { RuleDecl } from 'vue/types/options';
import { CleaveOptions } from 'cleave.js/options';

function areArraySame(array1: any[], array2: any[]) {
  if (array1.length !== array2.length) return false;
  return array1.every((value, index) => {
    return array1[index] === array2[index];
  });
}

function extractValues(_this, _fieldsData, _fieldsValues, fields) {
  for (let prop in fields) {
    if (fields[prop] instanceof Forms.DefaultFormElement) {
      let { value, ...rest } = fields[prop];
      if (typeof value === 'function') {
        _fieldsValues[prop] = value();
      } else {
        _fieldsValues[prop] = value;
      }
      _fieldsData[prop] = {};
      Object.keys(rest).forEach((property) => {
        if (
          typeof rest[property] === 'function' &&
          !['handler', 'search', 'handlerParams', 'formater'].includes(property)
        ) {
          _fieldsData[prop][property] = rest[property]();
        } else {
          _fieldsData[prop][property] = rest[property];
        }
      });
    } else if (
      fields[prop] != null &&
      typeof fields[prop] === 'object' &&
      !(fields[prop] instanceof Forms.Label)
    ) {
      _this[prop] = {};
      _fieldsData[prop] = {};
      _fieldsValues[prop] = {};
      extractValues(_this[prop], _fieldsData[prop], _fieldsValues[prop], fields[prop]);
    } else if (!(fields[prop] instanceof Forms.Label)) {
      _fieldsValues[prop] = fields[prop];
    }
  }
}
function cleanModified(_fields) {
  Object.keys(_fields).forEach((m) => {
    if (
      _fields[m] instanceof Object &&
      _fields[m] != null &&
      !(_fields[m] instanceof File) &&
      !(_fields[m] instanceof Date)
    ) {
      if (isEmpty(_fields[m])) {
        delete _fields[m];
      } else {
        cleanModified(_fields[m]);
        if (isEmpty(_fields[m])) {
          delete _fields[m];
        }
      }
    } else if (_fields[m] == null) {
      delete _fields[m];
    }
  });
}

function recursiveModified(_fields, _fieldsValues, _initialState, _modifiedValues) {
  Object.keys(_fields).forEach((m) => {
    if (_fields[m] instanceof Forms.DefaultFormElement) {
      if (typeof _initialState[m].value === 'function') {
        if (_fieldsValues[m] !== _initialState[m].value()) {
          _modifiedValues[m] = _fieldsValues[m];
        }
      } else {
        if (_fieldsValues[m] instanceof Array) {
          if (!areArraySame(_fieldsValues[m], _initialState[m].value)) {
            _modifiedValues[m] = _fieldsValues[m];
          }
        } else if (_fieldsValues[m] !== _initialState[m].value) {
          _modifiedValues[m] = _fieldsValues[m];
        }
      }
    } else if (_fields[m] instanceof Object) {
      _modifiedValues[m] = {};
      recursiveModified(_fields[m], _fieldsValues[m], _initialState[m], _modifiedValues[m]);
    }
  });
}

function resetValues(_fieldsValues, fields) {
  for (let fieldKey in fields) {
    if (fields[fieldKey] instanceof Forms.DefaultFormElement) {
      let { value } = fields[fieldKey];
      if (typeof value === 'function') {
        _fieldsValues[fieldKey] = value();
      } else {
        _fieldsValues[fieldKey] = value;
      }
    } else if (fields[fieldKey] != null && fields[fieldKey] instanceof Object) {
      resetValues(_fieldsValues[fieldKey], fields[fieldKey]);
    }
  }
}

function resetData(_fieldData, fields) {
  for (let fieldKey in fields) {
    if (fields[fieldKey] instanceof Forms.DefaultFormElement) {
      Object.keys(fields[fieldKey]).forEach((property) => {
        if (property !== 'value') {
          if (typeof fields[fieldKey][property] === 'function') {
            _fieldData[fieldKey][property] = fields[fieldKey][property]();
          } else {
            _fieldData[fieldKey][property] = fields[fieldKey][property];
          }
        }
      });
    } else if (fields[fieldKey] != null && fields[fieldKey] instanceof Object) {
      resetData(_fieldData[fieldKey], fields[fieldKey]);
    } else {
    }
  }
}

export type ExtractedFormElement<T> = {
  value: T;
  reset: () => void;
  data: Forms.FormData<T>;
};

function extractForm<T>(_this: Forms.DefaultFormElement<T>): ExtractedFormElement<T> {
  let { value, reset, ...data } = _this;
  if (value instanceof Function) {
    value = value();
  } else {
    value;
  }
  return {
    value,
    reset,
    data,
  };
}
export namespace Forms {
  export type FormData<D> = Omit<FormPayload<D>, 'value'>;
  export type FormValues<T extends Partial<T>> = {
    [S in keyof ExludeProperties<T, Forms.Label>]: T[S] extends Forms.DefaultFormElement<any>
      ? T[S]['value']
      : T[S] extends IAnyObject
      ? FormValues<T[S]>
      : T[S];
  };
  export type EditFormValues<T extends Partial<T>> = {
    [S in keyof ExludeProperties<T, Forms.Label>]?: T[S] extends Forms.DefaultFormElement<any>
      ? T[S]['value']
      : T[S] extends IAnyObject
      ? EditFormValues<T[S]>
      : T[S];
  };
  type invalidatableProperties = Forms.Label | string | number | boolean | undefined | never | null;
  export type ValidationTree<T> = {
    [K in keyof ExludeProperties<T, Forms.Label>]?: T[K] extends Forms.DefaultFormElement<any>
      ? RuleDecl
      : ValidationTree<T[K]>;
  };
  export type FormStructure<T> = {
    [K in keyof T]?: T[K] extends Forms.DefaultFormElement<any> ? T[K] : FormStructure<T[K]>;
  };
  type FullFormPayload<T> = {
    [K in keyof T]?: T[K] extends Forms.DefaultFormElement<any>
      ? FormData<T[K]>
      : FullFormPayload<T[K]>;
  };

  export type ExtractTree<T extends Form<any>> = T extends Form<infer V> ? V : Record<any, any>;

  export class Form<T extends FormStructure<T>> {
    private initialState: FormStructure<T>;
    public id: string;
    public fieldsData: FullFormPayload<T> = {};
    public fieldsValues: FormValues<T> = {} as any;
    public fields: FormStructure<T>;
    private validations?: ValidationTree<T>;
    public $v: ValidationRoot<T> = {} as any;
    public editMode: boolean;

    constructor({
      fields,
      validations,
      editMode,
    }: {
      fields: FormStructure<T>;
      validations?: ValidationTree<T>;
      editMode?: boolean;
    }) {
      this.id = uuid();
      if (editMode) {
        function recursiveEditmode(_fields) {
          Object.keys(_fields).forEach((m) => {
            if (_fields[m] instanceof DefaultFormElement) {
              _fields[m].editMode = true;
            } else if (typeof _fields[m] === 'object') {
              recursiveEditmode(_fields[m]);
            }
          });
        }
        recursiveEditmode(fields);
        this.editMode = editMode;
      }
      this.initialState = cloneDeep(fields);
      extractValues(this, this.fieldsData, this.fieldsValues, fields);
      this.fields = fields;
      this.validations = validations;
    }

    public reset(): void {
      if (!isEmpty(this.$v)) this.$v.$reset();
      resetValues(this.fieldsValues, this.initialState);
      resetData(this.fieldsData, this.initialState);
      this.id = uuid();
    }

    get isFormModified(): boolean {
      const modifiedValues: any = {};
      recursiveModified(this.fields, this.fieldsValues, this.initialState, modifiedValues);
      cleanModified(modifiedValues);
      return Object.keys(modifiedValues).length > 0;
    }

    public getModifiedData(): EditFormValues<T> {
      const modifiedValues: any = {};
      recursiveModified(this.fields, this.fieldsValues, this.initialState, modifiedValues);
      cleanModified(modifiedValues);

      return modifiedValues;
    }

    /** Does not work yet with recursive mode */
    public updateFieldData(data: { [Field in keyof T]?: FormPayload<Field> }) {
      Object.keys(data).forEach((field) => {
        Object.keys(data[field]).forEach((property) => {
          this.fieldsData[field][property] = field[property];
        });
      });
      return this;
    }

    /** Does not work yet with recursive mode */
    public updateAllFieldsData(data: FormPayload<any>) {
      Object.keys(this.fieldsData).forEach((field) => {
        Object.keys(data).forEach((property) => {
          this.fieldsData[field][property] = field[property];
        });
      });
      return this;
    }

    /** Does not work yet with recursive mode */
    public updateValues(data: Partial<FormValues<T>>) {
      Object.keys(data).forEach((field) => {
        this.fieldsValues[field] = data[field];
      });
      return this;
    }

    public getValues(): FormValues<T> {
      const values = cloneDeep(this.fieldsValues);
      cleanModified(values);
      return values;
    }

    get getValidations(): ValidationTree<T> {
      return this.validations;
    }
    get isFormValid(): boolean {
      if (this.$v.form) {
        if (this.editMode) {
          return !this.$v.form.$invalid && this.isFormModified;
        }
        return !this.$v.form.$invalid;
      }
      return true;
    }
  }

  // Section
  export class SectionConstructor {
    public sectionTitle: string;
    public unwrap: boolean;
    constructor(title, fields) {
      this.sectionTitle = title;
      Object.keys(fields).map((m) => {
        this[m] = fields[m];
      });
    }
  }

  export function Section<T>(title: string, fields: FormStructure<T>, unwrap?: boolean): T {
    return new SectionConstructor(title, fields) as any;
  }

  export class Label {
    public title: string;
    constructor(title: string) {
      this.title = title;
    }
  }

  type FormType = 'text' | 'number' | 'password' | 'email' | 'tel' | 'time';

  type IComponentType =
    | 'FormText'
    | 'FormField'
    | 'StarRating'
    | 'FormSelect'
    | 'FormCheckBox'
    | 'Radio'
    | 'FormCalendar'
    | 'FormPlaceSearch'
    | 'FormUpload'
    | 'RichText'
    | 'RichRadio'
    | 'RichCheckBoxList'
    | 'FormSelectMultiple';

  type Unpacked<T = any> = T | ((...args: any[]) => T);

  export class FormPayload<T> {
    value?: Unpacked<T>;
    testValue?: Unpacked<T>;
    valueType?: 'string' | 'number' | 'float' | 'price';
    displayValue?: string;
    tempValue?: T;
    icon?: string;
    type?: FormType;
    placeholder?: string;
    error?: Unpacked<boolean>;
    disabled?: Unpacked<boolean>;
    noMargin?: boolean;
    noBorder?: boolean;
    label?: string;
    inlineIcon?: boolean;
    debounce?: number;
    component?: IComponentType;
    errorMessages?: { [P in keyof IErrorValidators]?: IErrorValidators[P] };
    editMode?: Unpacked<boolean>;
    noEdit?: boolean;
    update?: (value: T) => void;
    id?: string;
    halfWidth?: boolean;
    width?: string;
    cleaveOptions?: CleaveOptions;
  }
  export class DefaultFormElement<T> extends FormPayload<T> {
    public initialValue: Unpacked<T>;
    public value: T;
    constructor({
      value = null,
      error = true,
      noEdit = false,
      editMode = false,
      ...fields
    }: FormPayload<T>) {
      super();
      this.id = uuid();
      if (isTesting && fields.testValue) {
        value = fields.testValue as any;
      }
      this.value = value as T;
      this.valueType = fields.valueType;
      this.displayValue = fields.displayValue;
      this.initialValue = value;
      this.icon = fields.icon || null;
      this.type = fields.type || 'text';
      this.placeholder = fields.placeholder || fields.label || null;
      this.error = error;
      this.disabled = fields.disabled || false;
      this.inlineIcon = fields.inlineIcon || false;
      this.debounce = fields.debounce || null;
      this.noMargin = fields.noMargin || false;
      this.errorMessages = fields.errorMessages || null;
      this.label = fields.label;
      this.halfWidth = fields.halfWidth || false;
      this.width = fields.width;
      this.component = fields.component || null;
      this.tempValue = fields.tempValue;
      this.update = fields.update;
      this.noBorder = fields.noBorder;
      this.noEdit = noEdit;
      this.editMode = editMode;
      this.cleaveOptions = fields.cleaveOptions;
    }

    extract() {
      return extractForm<T>(this);
    }

    reset() {
      this.value = cloneDeep(this.initialValue as T);
    }
  }

  // Text
  export class TextForm<T = string> extends DefaultFormElement<T> {
    constructor(fields: FormPayload<T>) {
      super({ ...fields, component: 'FormText' });
    }
  }

  // Textarea
  export class FieldForm<T extends string = string> extends DefaultFormElement<T> {
    constructor(fields: FormPayload<T>) {
      super({ ...fields, component: 'FormField' });
    }
  }

  // RichText
  export interface IRichTextPayload<T> extends FormPayload<T> {
    emailVariables?: IVariable[];
  }
  export class RichText<T extends string = string> extends DefaultFormElement<T> {
    emailVariables?: IVariable[];

    constructor({ type = 'text', ...fields }: IRichTextPayload<T>) {
      super({ ...fields, component: 'RichText' });
      this.emailVariables = fields.emailVariables;
    }
  }

  // Upload
  export interface UploadPayload<T extends File | string = string> extends FormPayload<T> {
    uploadType?: 'image' | 'video' | 'audio' | 'file';
    displayType?: 'upload' | 'avatar';
  }
  export class UploadForm<T extends File | string = string> extends DefaultFormElement<T> {
    public inForm = true;
    public uploadType?: 'image' | 'video' | 'audio' | 'file';
    displayType?: 'upload' | 'avatar';

    constructor(fields: UploadPayload<T>) {
      super({ ...fields, component: 'FormUpload' });
      this.uploadType = fields.uploadType || 'image';
      this.displayType = fields.displayType || 'upload';
    }
  }

  // Radio
  export interface IRadioItem<T> {
    value: T;
    text: string;
  }
  type IRadioStyle = 'row' | 'column';
  interface IRadioPayload<T> {
    radios: IRadioItem<T>[];
    style?: IRadioStyle;
  }

  export class Radio<T extends string = string> extends DefaultFormElement<T> {
    public radios: IRadioItem<T>[];
    public style?: IRadioStyle;
    constructor(fields: FormPayload<T> & IRadioPayload<T>) {
      super({ ...fields, component: 'Radio' });
      this.radios = fields.radios;
      this.style = fields.style || 'row';
    }
  }

  // RichRadio
  export interface IRichRadioItem<T> extends IRadioItem<T> {
    icon?: string;
  }
  export interface IRichRadioPayload<T> {
    radios: IRichRadioItem<T>[];
  }

  export class RichRadio<T extends string = string> extends DefaultFormElement<T> {
    public radios: IRadioItem<T>[];
    constructor(fields: FormPayload<T> & IRichRadioPayload<T>) {
      super({ ...fields, component: 'RichRadio' });
      this.radios = fields.radios;
    }
  }

  // Select Multiple
  export interface ISelectMultiplePayload<T extends any[], V = any> {
    value?: V[];
    options?: IOptions<T[0]>[];
    handler?: (...args: any[]) => Promise<IConnection<any>>;
    handlerParams?: { [x: string]: any };
    formater: (item: V) => IOptions<T[0]>;
    search: (value: string) => IAnyObject;
    displayedValues?: IOptions<T[0]>[];
    alignement?: 'column' | 'row';
  }

  export class SelectMultiple<
    T extends any[] = any[],
    V extends any = any
  > extends DefaultFormElement<T> {
    public options?: IOptions<T[0]>[];
    public handler?: (...args: any[]) => Promise<IConnection<T>>;
    public handlerParams?: { [x: string]: any };
    public formater: (item: V) => IOptions<T[0]>;
    public allOption?: boolean;
    public search: (value: string) => IAnyObject;
    public displayedValues: IOptions<T[0]>[];
    public alignement?: 'column' | 'row';

    constructor({ value, ...fields }: FormPayload<T> & ISelectMultiplePayload<T, V>) {
      super({ ...fields, component: 'FormSelectMultiple' });
      this.value = value ? (value.map((val) => fields.formater(val).value) as T) : ([] as T);
      this.displayedValues = value ? (value.map((val) => fields.formater(val)) as T) : null;
      this.options = fields.options || null;
      this.handler = fields.handler;
      this.handlerParams = fields.handlerParams;
      this.formater = fields.formater;
      this.search = fields.search;
      this.alignement = fields.alignement || 'column';
    }
  }

  // Select
  export type IOptions<T extends any = any> = { value: T; text: string; icon?: string };
  export interface ISelectPayload<T = any, V = any> {
    options?: IOptions<T>[];
    handler?: (...args: any[]) => Promise<IConnection<any>>;
    handlerParams?: { [x: string]: any };
    formater?: (item: IConnexionElement<V>) => IOptions<T>;
    allOption?: boolean;
    search?: (value: string) => IAnyObject;
  }

  export class Select<T extends any = string, V extends any = any> extends DefaultFormElement<T> {
    public options?: IOptions<T>[];
    public handler?: (...args: any[]) => Promise<IConnection<T>>;
    public handlerParams?: { [x: string]: any };
    public formater?: (item: IConnexionElement<V>) => IOptions<T>;
    public allOption?: boolean;
    public search?: (value: string) => IAnyObject;

    constructor({ ...fields }: FormPayload<T> & ISelectPayload<T, V>) {
      super({ ...fields, component: 'FormSelect' });
      this.options = fields.options || null;
      this.handler = fields.handler;
      this.handlerParams = fields.handlerParams;
      this.formater = fields.formater;
      this.allOption = fields.allOption;
      this.search = fields.search;
    }
  }

  // Calendar
  export type ICalendarValue = Date | ICalendarPeriodType;
  export type ICalendarPeriodType = { start: Date; end: Date };
  export type ICalendarType = 'normal' | 'period';
  export type ISideListLabel =
    | 'Personnalisé'
    | 'Ce mois ci'
    | 'Ces 6 derniers mois'
    | "L'année en cours"
    | "L'année dernière";
  export interface ISideListItem {
    label: ISideListLabel;

    actionValue: Forms.ICalendarPeriodType;
    padding?: boolean;
  }
  export interface ICalendarPayload {
    calendarType?: ICalendarType;
    type?: 'date' | 'datetime-local' | 'time';
    sideList?: boolean;
    selectedSideListItem?: ISideListLabel;
    popupProps?: IAnyObject;
  }

  export class Calendar<T extends ICalendarValue = ICalendarValue> extends DefaultFormElement<T> {
    public calendarType: ICalendarType;
    public sideList: boolean;
    public selectedSideListItem?: ISideListLabel;
    popupProps?: IAnyObject;

    constructor({ value, calendarType = 'normal', ...fields }: FormPayload<T> & ICalendarPayload) {
      super({ ...fields, component: 'FormCalendar' });
      this.calendarType = calendarType;
      this.sideList = fields.sideList;
      this.selectedSideListItem = fields.selectedSideListItem;
      this.popupProps = fields.popupProps;
      this.value = value as T;
    }
  }

  // CheckBox
  export class CheckBox<T extends boolean = boolean> extends DefaultFormElement<T> {
    constructor(fields: FormPayload<T>) {
      super({ ...fields, component: 'FormCheckBox' });
    }
  }

  // RichCheckBoxList
  export type IRichCheckBoxListItem<T = any> = {
    text: string;
    value: T;
    icon?: string;
  };
  export interface IRichCheckBoxListPayload<T> {
    checkboxs: IRichCheckBoxListItem<T>[];
  }

  export class RichCheckBoxList<T extends string[] = string[]> extends DefaultFormElement<T> {
    public checkboxs: IRichCheckBoxListItem<T[0]>[];
    constructor({ value, ...fields }: FormPayload<T> & IRichCheckBoxListPayload<T[0]>) {
      super({ ...fields, component: 'RichCheckBoxList' });
      this.value = (value || []) as T;
      this.checkboxs = fields.checkboxs;
    }
  }

  interface StarPayload<T> extends FormPayload<T> {
    starCount?: number;
    baseColor?: string;
    selectedColor?: string;
    hoverColor?: string;
    editable?: boolean;
    init?: number;
    size?: number;
    displayNote?: boolean;
    center?: boolean;
  }

  export class StarRating<T extends number = number> extends DefaultFormElement<T> {
    starCount?: number;
    baseColor?: string;
    selectedColor?: string;
    hoverColor?: string;
    editable?: boolean;
    init?: number;
    size?: number;
    displayNote?: boolean;
    center?: boolean;

    constructor(fields: StarPayload<T>) {
      fields.component = 'StarRating';
      super(fields);
      this.starCount = fields.starCount || 5;
      this.baseColor = fields.baseColor;
      this.selectedColor = fields.selectedColor;
      this.hoverColor = fields.hoverColor;
      this.editable = fields.editable != null ? fields.editable : true;
      this.init = fields.init || 0;
      this.size = fields.size || 25;
      this.displayNote = fields.displayNote != null ? fields.displayNote : false;
      this.center = fields.center != null ? fields.center : true;
    }
  }
}

export default Forms;
