import { render, staticRenderFns } from "./CommunityCrecheInfos.vue?vue&type=template&id=f15abeb6&scoped=true&"
import script from "./CommunityCrecheInfos.vue?vue&type=script&lang=ts&"
export * from "./CommunityCrecheInfos.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f15abeb6",
  null
  
)

export default component.exports