


















import { Component, Prop, Watch } from 'vue-property-decorator';
import shortid from 'shortid';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';

@Component({
  components: {
    FormError,
  },
})
export default class RichRadio extends FormMixin {}
