import { render, staticRenderFns } from "./CrecheDemandesList.vue?vue&type=template&id=0f0b4760&scoped=true&"
import script from "./CrecheDemandesList.vue?vue&type=script&lang=ts&"
export * from "./CrecheDemandesList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0b4760",
  null
  
)

export default component.exports