






















































import {
  CheckBox,
  FormCalendar,
  FormSelect,
  InfiniScroll,
  InfiniScrollMixin,
  Radio,
} from '@components';
import { Forms } from '@constructors';
import { reservationFragment } from '@fragments';
import { nurseriesConnectionQuery } from '@graphql';
import { IgetAllReservationsArgs, INurseryEdgeWithRevenues, IReservation } from '@models';
import { sgts } from '@services';
import { endOfMonth, startOfMonth, format } from 'date-fns';
import { Component } from 'vue-property-decorator';
import { reservationStatusConstant } from '@constants';
import { formatDateExport } from '@utils';

@Component({ components: { FormCalendar, FormSelect, Radio, InfiniScroll, CheckBox } })
export default class Reservations extends InfiniScrollMixin {
  public allNurseries = false;
  public reservationStatusConstant = reservationStatusConstant;

  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };

  public listHandler = (args: IgetAllReservationsArgs) =>
    sgts.getAllReservations().$fragment(reservationFragment).$args(args).$fetch();

  get handlerParams(): IgetAllReservationsArgs {
    return {
      where: {
        startDate: this.calendarStats.value.start,
        endDate: this.calendarStats.value.end,
        ...(this.selectNursery.value && {
          nursery: {
            id: this.selectNursery.value,
          },
        }),
      },
      page: 1,
      limit: 20,
    };
  }

  async getNewReservations() {
    this.allNurseries = false;
    if (this.calendarStats.value.start && this.calendarStats.value.end) {
      this.newSearch();
    }
  }

  async getAllReservations() {
    this.selectNursery.value = null;
    if (this.calendarStats.value.start && this.calendarStats.value.end) {
      this.newSearch();
    }
  }

  formatDate(date: Date) {
    return format(date, 'DD/MM/YYYY à HH:mm');
  }

  created() {
    this.getNewReservations();
  }

  public columns = ['Crèche', 'Code analytique', 'Date de réservation', 'Parent', 'Statut'];

  async exportData() {
    const csvContent = await sgts
      .exportReservationsNurseries()
      .$args({
        where: {
          start: this.calendarStats.value.start,
          end: this.calendarStats.value.end,
          ...(this.selectNursery.value && {
            nurseries: [this.selectNursery.value],
          }),
        },
      })
      .$fetch();
    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Réservations_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  newContent(results: IReservation[]) {
    this.reservations = results;
  }

  public reservations: IReservation[] = [];

  public calendarStats = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '260px',
  }).extract();

  public selectNursery = new Forms.Select<string, INurseryEdgeWithRevenues>({
    placeholder: 'Sélectionner une crèche',
    handler: nurseriesConnectionQuery,
    handlerParams: {
      limit: 10,
    },
    formater: ({ name, id }) => ({
      value: id,
      text: name,
    }),
    width: '260px',
    search: (value) => ({ search: value }),
  }).extract();
}
