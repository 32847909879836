































































































import { FormSelect, InfiniScrollMixin, FormCalendar } from '@components';
import { documentStatusEnum } from '@constants';
import { Forms } from '@constructors';
import {
  IcustomersConnectionArgs,
  ICustomersConnectionFilterEnum,
  ICustomersConnectionOrderEnum,
  IRegisteredConnection,
} from '@models';
import { routesNames } from '@router';
import { sgts } from '@services';
import { CustomersModule } from '@store';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Component } from 'vue-property-decorator';
import { constantToSelectFormater, formatDateExport } from '@utils';

@Component({
  components: { FormSelect, FormCalendar },
})
export default class InscritsList extends InfiniScrollMixin<
  IRegisteredConnection,
  IcustomersConnectionArgs
> {
  public routesNames = routesNames;
  public columns = [
    'Parent',
    "Date d'inscription",
    'Statut dossier',
    'Réservations en attente',
    'Réservations validées',
    '',
  ];

  public filterby = new Forms.Select<ICustomersConnectionFilterEnum>({
    placeholder: 'Filtrer par',
    width: '200px',
    noMargin: true,
    options: constantToSelectFormater(documentStatusEnum),
  }).extract();

  public orderBy = new Forms.Select<ICustomersConnectionOrderEnum>({
    placeholder: 'Trier par',
    width: '200px',
    options: [
      { value: 'RESERVATION_PENDING', text: 'Réservations en attente' },
      { value: 'RESERVATION_DONE', text: 'Réservations validées' },
    ],
  }).extract();

  public byPeriod = new Forms.Calendar<Forms.ICalendarPeriodType>({
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '230px',
  }).extract();

  public listHandler = CustomersModule.actions.getCustomers;

  get customerList() {
    return CustomersModule.state.customers.map((customer) => ({
      ...customer,
      name: customer.firstName + ' ' + customer.lastName,
      createdAt: format(customer.createdAt, 'DD MMM YYYY', { locale: frLocale }),
      status: documentStatusEnum[customer.documentStatus],
    }));
  }

  async exportCustomers() {
    const orderBy = this.orderBy.value;
    const filterBy = this.filterby.value;

    const csvContent = await sgts
      .exportCustomers()
      .$args({
        where: {
          ...(this.search && { search: this.search }),
          orderBy,
          filterBy,
          ...(this.byPeriod.value && {
            ...(this.byPeriod.value.start && { start: this.byPeriod.value.start }),
            ...(this.byPeriod.value.end && { end: this.byPeriod.value.end }),
          }),
        },
      })
      .$fetch();
    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Inscrits_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  //@ts-ignore
  get handlerParams(): IcustomersConnectionArgs {
    const orderBy = this.orderBy.value;
    const filterBy = this.filterby.value;

    return {
      where: {
        search: this.search,
        orderBy,
        filterBy,
        ...(this.byPeriod.value && {
          ...(this.byPeriod.value.start && { start: this.byPeriod.value.start }),
          ...(this.byPeriod.value.end && { end: this.byPeriod.value.end }),
        }),
      },
    };
  }

  get bannedLink() {
    return routesNames.admin.INSCRITS.BANNED;
  }
}
