import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { StoreModuleType } from '@/vuex-typed-modules/types';
import { IConnection } from '@models';

interface InfinityScrollClass<E, W> {
  listHandler?: (payload: W) => Promise<E>;
  readonly handlerParams?: W;
  newContent?: (data: any) => void;
}

@Mixin({})
export default class InfiniScrollMixin<E extends IConnection<any> = any, W extends Object = any>
  extends Vue
  implements InfinityScrollClass<E, W>
{
  public module!: StoreModuleType;
  public search = '';
  public loading = false;
  public hasNextPage = false;
  public hasPreviousPage = false;
  public page = 1;
  public oldPage = 1;

  get searchValue() {
    return this.search;
  }

  set searchValue(value: string) {
    this.search = value;
    this.newSearch();
  }
  listHandler;
  public newContent(...args: any[]) {}
  handlerParams;

  public async newSearch() {
    try {
      this.loading = true;
      this.page = this.oldPage;
      const { edges, pageInfo } = await this.listHandler({
        ...this.getParams,
      });
      this.hasNextPage = pageInfo?.hasNextPage;
      this.hasPreviousPage = pageInfo?.hasPreviousPage;
      if (this.newContent && this.newContent instanceof Function) {
        this.newContent(edges?.map((m) => m.node));
      }
      return edges?.map((m) => m.node);
    } finally {
      this.loading = false;
    }
  }

  get getParams(): W {
    return {
      ...this.handlerParams,
      page: this.page,
    };
  }
}
