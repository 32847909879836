import { sgts } from '@services';
import { ImailsConnectionArgs } from '@models';
import { emailFragment } from '@fragments';

export const mailsConnectionQuery = ({ limit = 20, ...rest }: ImailsConnectionArgs) => {
  return sgts
    .mailsConnection()
    .$fragment(emailFragment)
    .$args({ limit, ...rest })
    .$fetch();
};
