
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class LimitRender extends Vue {
  @Prop() limit: number;

  // render(h) {
  //   return <div class="paginationView-root">{this.$slots.default.slice(0, this.limit)}</div>;
  // }
}
