























import { Component, Vue, Prop } from 'vue-property-decorator';
import { RadioTabs } from '@components';
import { Forms } from '@constructors';
import {
  IregistrationsConnectionArgs,
  IRegistrationsConnection,
  IreservationsConnectionArgs,
  IReservationConnection,
  INursery,
  IReservationStatus,
} from '@models';

import Registrations from './Registrations.vue';
import Reservations from './Reservations.vue';

@Component({
  components: {
    RadioTabs,
    Registrations,
    Reservations,
  },
})
export default class DemandesTemplate extends Vue {
  @Prop() redirect: string;
  @Prop() handlers: {
    registrations: (args: IregistrationsConnectionArgs) => Promise<IRegistrationsConnection>;
    reservations: (args: IreservationsConnectionArgs) => Promise<IReservationConnection>;
  };
  @Prop() nursery: INursery;
  @Prop({ default: true }) showTabs: boolean;
  @Prop({ default: null, required: false }) customLimit: number | null;

  public filterValue = '';

  get handlerParams(): IregistrationsConnectionArgs | IreservationsConnectionArgs {
    return {
      where: {
        nursery: {
          id: this.nursery.id,
        },
        ...(this.status && { status: this.status }),
        ...(this.filterValue === 'PENDING' && { startDate: new Date() }),
      },
      ...(this.customLimit && { limit: this.customLimit }),
    };
  }
  get status(): IReservationStatus {
    return this.filterValue as IReservationStatus;
  }

  get resaTitle() {
    if (this.filterValue === 'PENDING') return 'Demandes de réservations en attente';
    else if (this.filterValue === 'REFUSED') return 'Réservations refusées';
    else if (this.filterValue === 'CANCELED') return 'Réservations annulées';
    else if (this.filterValue === 'DONE') return 'Réservations confirmées à venir';
    return '';
  }

  get getFilterValue() {
    return this.filterValue;
  }
  set getFilterValue(value) {
    this.filterValue = value;
  }
  public tabs: Forms.IRadioItem<string>[] = [
    { value: '', text: 'Inscriptions' },
    { value: 'DONE', text: 'Réservations' },
    { value: 'PENDING', text: 'À venir' },
    { value: 'REFUSED', text: 'Refusées' },
    { value: 'CANCELED', text: 'Annulées' },
  ];
}
