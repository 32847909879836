

















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DirectorNurseryModule, NurseryModule } from '@store';

import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

@Component({})
export default class CommunityCrecheInfos extends Vue {
  get nursery() {
    let res = DirectorNurseryModule.state.nursery;
    res.closingDates?.sort((a, b) => {
      let val = (new Date(b.closingDateStart) as any) - (new Date(a.closingDateStart) as any);
      return -val;
    });
    return res;
  }
  formatDate(date) {
    return format(date, 'DD MMM YYYY', { locale: frLocale });
  }

  get totalCapacity() {
    return this.nursery.sections.reduce((acc, section) => {
      return (acc += section.capacity);
    }, 0);
  }

  get isCommunityNursery() {
    return this.nursery.communityNursery === true;
  }

  get emailToHref() {
    return 'mailto:' + this.nursery?.responsibleEmail;
  }
}
