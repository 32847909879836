import { sgts } from '@services';
import {
  IreservationsConnectionArgs,
  IregistrationsConnectionArgs,
  IRegistrationWhereUniqueInput,
} from '@models';
import { registrationFragment, reservationFragment } from '@fragments';

export const reservationsConnectionQuery = ({
  limit = 20,
  where,
  page,
}: IreservationsConnectionArgs) => {
  return sgts
    .reservationsConnection()
    .$fragment(reservationFragment)
    .$args({
      limit,
      page,
      where: {
        noRegistration: true,
        ...where,
      },
    })
    .$fetch();
};

export const registrationsConnectionQuery = ({
  limit = 20,
  page,
  where,
}: IregistrationsConnectionArgs) => {
  return sgts
    .registrationsConnection()
    .$fragment(registrationFragment)
    .$args({ limit, page, where })
    .$fetch();
};

export const getRegistrationQuery = (where: IRegistrationWhereUniqueInput) => {
  return sgts.registration().$fragment(registrationFragment).$args({ where }).$fetch();
};

export const getReservationQuery = (id: string) => {
  return sgts.reservation().$fragment(reservationFragment).$args({ id }).$fetch();
};
