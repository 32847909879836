import gql from 'graphql-tag';
import { childFragment } from './customer.fragment';
import { reservationWithoutChildFragment, reservationFragment } from './reservation.fragment';

export const registrationFragment = gql`
  fragment registrationFragment on Registration {
    id
    child {
      ...childFragment
    }
    nursery {
      id
      name
      type
      communityNursery
      dspNursery
    }
    status
    registrationDate
    reservation {
      ...reservationFragment
    }
  }
  ${childFragment}
  ${reservationFragment}
`;

export const registrationWithoutChildFragment = gql`
  fragment registrationWithoutChildFragment on Registration {
    id
    nursery {
      id
      name
      type
      communityNursery
      dspNursery
    }
    status
    registrationDate
    reservation {
      ...reservationFragment
    }
  }
  ${reservationFragment}
`;
