import { IchildAdaptationFormArgs, IcustomersConnectionArgs } from '@models';
import {
  customerWithRegistrationFragment,
  customerExtendedFragment,
  registeredFragment,
  childAdaptationFormFragment,
} from '@fragments';
import { sgts } from '@services';

export const customersConnectionQuery = ({ limit = 20, ...rest }: IcustomersConnectionArgs) => {
  return sgts
    .customersConnection()
    .$fragment(registeredFragment)
    .$args({ limit, ...rest })
    .$fetch();
};

export const customerQuery = (customerId: string) => {
  return sgts.customer().$fragment(customerWithRegistrationFragment).$args({ customerId }).$fetch();
};

export const bansConnectionQuery = sgts.bansConnection().$fragment(customerExtendedFragment);

export const getChildAdaptationFormsByParent = async (data: IchildAdaptationFormArgs) => {
  return sgts.childAdaptationForm().$fragment(childAdaptationFormFragment).$args(data).$fetch();
};
