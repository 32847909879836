

























































































































import { Prop, Watch } from 'vue-property-decorator';
import { Component, Mixin, Mixins } from 'vue-mixin-decorator';
import { InputFileSystem } from 'webpack';
import Axios from 'axios';
import { NotificationsModule } from '@store';
import { Forms, ErrorNotification } from '@constructors';
import { FormMixin } from '../../Mixins/FormMixin';
import FormError from './FormError.vue';

@Component({
  components: { FormError },
})
export default class FormUpload extends FormMixin {
  readonly value: File | string;
  readonly data: Forms.UploadForm<any>;

  get type() {
    return this.data.uploadType;
  }

  public onlyDisplay = false;
  public loadingFile = false;
  public fileUploaded = false;
  public fileTitle: string = null;
  public previsualisationData: string = null;
  public videoSizeLimitTriggered = false;
  public videoPreviewSizeLimit = 10000000;

  public preloadedFileSize: number = null;

  public acceptedImageTypes = ['image/jpeg', 'image/png'];
  public acceptedVideosTypes = [
    'video/mp4',
    'video/x-m4v',
    'video/webm',
    'video/x-msvideo',
    'video/quicktime',
  ];
  public acceptedAudioTypes = ['audio/x-m4a', 'audio/*'];
  public acceptedFileTypes = [...this.acceptedImageTypes, 'application/pdf'];

  get formatedImage() {
    return { backgroundImage: `url(${this.previsualisationData})` };
  }

  get getIcon() {
    if (this.type === 'image') {
      return 'icons/Forms/image.svg';
    } else if (this.type === 'video') {
      return 'icons/Forms/video.svg';
    } else if (this.type === 'audio') {
      return 'icons/Forms/audio.svg';
    } else if (this.type === 'file') {
      return 'icons/Forms/file.svg';
    }
    return 'icons/Forms/file.svg';
  }

  get getTypeDisplay() {
    if (this.type === 'image') {
      return 'une image';
    } else if (this.type === 'video') {
      return 'une vidéo';
    } else if (this.type === 'audio') {
      return 'un fichier audio';
    }
    return 'le document';
  }

  get getFileSize() {
    let size = null;
    if (this.value instanceof File) size = this.value.size;
    else size = this.preloadedFileSize;

    if (size < 1024) {
      return size + ' octets';
    } else if (size >= 1024 && size < 1048576) {
      return (size / 1024).toFixed(1) + ' Ko';
    } else if (size >= 1048576) {
      return (size / 1048576).toFixed(1) + ' Mo';
    }
    return null;
  }

  get getAccept(): string {
    if (this.type === 'image') {
      return this.acceptedImageTypes.join(',');
    } else if (this.type === 'video') {
      return this.acceptedVideosTypes.join(',');
    } else if (this.type === 'audio') {
      return this.acceptedAudioTypes.join(',');
    }
    return this.acceptedFileTypes.join(',');
  }

  get sizeLimit(): number {
    if (this.type === 'image') {
      return 3000000;
    } else {
      return 300000000;
    }
  }

  handleImportFile(event): void {
    const file = this.$refs.inputFile.files[0];
    if (file) {
      const success = this.readImage(file);
      if (success) {
        this.$emit('input', file);
        if (this.vl) this.vl.$touch();
      } else {
        this.$refs.inputFile.value = '';
      }
    }
  }

  readImage(image: File): boolean {
    if (image.size > this.sizeLimit) {
      var maxSize = new ErrorNotification(
        `La taille maximale est de ${this.sizeLimit / 1000000}Mo`
      );
      return false;
    } else if (image.size < this.videoPreviewSizeLimit) {
      const reader = new FileReader();
      this.loadingFile = true;
      this.fileTitle = image.name;

      reader.onload = (event: any) => {
        this.previsualisationData = event.target.result;
        this.fileUploaded = true;
        this.loadingFile = false;
      };
      reader.onerror = () => {
        this.loadingFile = false;
      };
      reader.readAsDataURL(image);
      return true;
    } else {
      this.fileUploaded = true;
      this.videoSizeLimitTriggered = true;
      return false;
    }
  }

  deleteFile() {
    this.loadingFile = false;
    this.fileUploaded = false;
    this.fileTitle = null;
    this.previsualisationData = null;
    this.onlyDisplay = false;

    this.$emit('input', null);
    if (this.vl) this.vl.$touch();
    this.$refs.inputFile.value = null;
  }

  triggerInput() {
    if (!this.value || this.data.displayType === 'avatar') {
      this.$refs.inputFile.click();
    }
  }

  @Watch('value')
  valueChanged(newVal, oldVal) {
    if (this.data.editMode) {
      if (newVal === this.initialValue) {
        if (this.vl) this.vl.$reset();
        this.preloadInitialImage();
      }
    }
  }

  async preloadInitialImage() {
    if (this.value) {
      if (this.value instanceof File) {
        this.readImage(this.value);
      } else {
        this.onlyDisplay = true;
        this.previsualisationData = this.value;
        this.fileUploaded = true;
      }
    }
  }

  created() {
    this.preloadInitialImage();
  }
}
