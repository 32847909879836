





















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import shortid from 'shortid';

@Component({})
export default class CheckBox extends Vue {
  @Prop({ type: null })
  value!: boolean | string;
  @Prop()
  label: string;
  @Prop()
  trueValue: string;
  @Prop()
  falseValue: string;
  @Prop()
  big: boolean;
  @Prop()
  disabled: boolean;
  @Prop()
  labelLink: string;

  public formId: string = null;

  get formatedValue() {
    if (this.trueValue && this.falseValue) {
      return this.value === this.trueValue;
    } else {
      return this.value;
    }
  }

  updateValue(value) {
    if (this.trueValue && this.falseValue) {
      this.$emit('input', value ? this.trueValue : this.falseValue);
    } else {
      this.$emit('input', value);
    }
  }

  created() {
    this.formId = shortid.generate();
  }
}
