import { INurseryEdgeWithRevenues } from '@models';
import { Forms } from '@constructors';

export const constantToSelectFormater = <T extends { [K in keyof T]: string }>(
  item: T
): Forms.IOptions<keyof T>[] => {
  return Object.keys(item).map((m) => ({
    value: (!isNaN(Number(m)) ? Number(m) : m) as keyof T,
    text: item[m],
  }));
};

export const nurseriesFormater = (item: INurseryEdgeWithRevenues) => ({
  value: item.node.id,
  text: item.node.name + ' - ' + item.node.analyticCode,
});

export const hoursFormater = (item: number) => {
  return `${item > 9 ? item : '0' + item}:00`;
};
