















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Forms, ErrorNotification } from '@constructors';
import { FormCalendar } from '@components';
import { routesNames } from '@router';
import { AuthModule } from '@store';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { IDashboard } from '@models';
import { dashboardQuery } from '@graphql';
import { sgts } from '../../../services';
import { formatDateExport } from '@utils';

@Component({
  components: { FormCalendar },
})
export default class HomeAdmin extends Vue {
  get adminName() {
    return AuthModule.state.userInfos.firstName;
  }

  public dashboardData: IDashboard = null;
  public loading = false;

  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };
  public calendar = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '260px',
  }).extract();

  get formatedDates() {
    return {
      start: format(this.calendar.value.start, 'DD/MM/YYYY'),
      end: format(this.calendar.value.end, 'DD/MM/YYYY'),
    };
  }

  async exportPayments() {
    const csvContent = await sgts
      .exportPayments()
      .$args({
        start: this.calendar.value.start,
        end: this.calendar.value.end,
      })
      .$fetch();
    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Paiements_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  async getDashBoard(period: Forms.ICalendarPeriodType) {
    if (period.start && period.end) {
      try {
        this.loading = true;
        this.dashboardData = await dashboardQuery(period);
      } catch (e) {
        var alert = new ErrorNotification('Impossible de récupérer les données du dashboard');
      } finally {
        this.loading = false;
      }
    }
  }

  get toCrecheList() {
    return { name: routesNames.admin.CRECHES };
  }
  get toInscritsList() {
    return { name: routesNames.admin.INSCRITS.LIST };
  }

  async exportToCSV() {
    const {
      availabilities,
      nurseryNumber,
      pendingRegistrations,
      pendingReservations,
      processedRegistrations,
      processedReservations,
      recentNurseries,
      revenues,
      validatedRegistrations,
    } = this.dashboardData;

    const csvContent = `Export Dashboard;${this.formatedDates.start}-${this.formatedDates.end};;Ce mois-ci;;Total
    Inscriptions traitées;${processedRegistrations};Chiffre d’affaires;${revenues};Nombre de crèches;${nurseryNumber}
    Réservations traitées;${processedReservations};Inscription validées;${processedRegistrations};;
    Disponibilités;${availabilities};;;;
    Crèches ajoutées;${recentNurseries};;;;`;

    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Données_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  mounted() {
    this.getDashBoard(this.defaultPeriod);
  }
}
