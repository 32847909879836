












import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavLink } from '@models';

@Component
export default class NavTab extends Vue {
  @Prop() data: NavLink;
  @Prop() list: boolean;

  get getColor() {
    let active = false;
    if (this.data.exact) {
      active = this.$route.path === this.data.link;
    } else {
      active = this.$route.matched.some((s) => {
        return s.path === this.data.link;
      });
    }
    if (active) return 'text';
    else return 'white';
  }
}
