









































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  BackLink,
  DocumentDisplay,
  CafDocumentDisplay,
  CodeFamille,
  FormContainer,
} from '@components';
import { routesNames } from '@router';
import { uniqBy } from 'lodash';
import { format } from 'date-fns';
import { Location } from 'vue-router';
import { ICustomerWithRegistration, IChildAdaptationForm, IEffortRate } from '@models';
import {
  AuthModule,
  CustomersModule,
  DirectorCustomersModule,
  DirectorNurseryModule,
  NotificationsModule,
} from '@store';
import {
  BanReasonConstant,
  allergyConstants,
  breastfedType,
  hygieneType,
  motorSkillsType,
  sleepHoursConstant,
} from '@constants';
import { calculatePsuPrice, constantToSelectFormater } from '@utils';
import { Alerts, Forms, SuccessNotification, ErrorNotification } from '@constructors';
import {
  required,
  maxLength,
  decimal,
  maxValue,
  minValue,
  numeric,
} from 'vuelidate/lib/validators';
import { oc } from 'ts-optchain';
import {
  deleteBanMutation,
  getAllEffortRatesQuery,
  getChildAdaptationFormsByParent,
  updateCustomerMutation,
} from '@graphql';
import OneInscritHistory from './OneInscritHistory.vue';
import OneInscritBillings from './OneInscritBillings.vue';
import { dateValidator, isDateBeforeToday, positiveNumber } from '../../../utils/validators';
import { sgts } from '../../../services';
import { billingFragment, cafDocumentFragment } from '../../../fragments';
import FormUpload from '../../../components/Shared/Forms/FormUpload.vue';
import FormText from '../../../components/Shared/Forms/FormText.vue';

@Component({
  components: {
    BackLink,
    DocumentDisplay,
    CafDocumentDisplay,
    OneInscritHistory,
    OneInscritBillings,
    CodeFamille,
    FormContainer,
    FormUpload,
    FormText,
  },
})
export default class OneInscritTemplate extends Vue {
  @Prop() inscrit: ICustomerWithRegistration;
  public nurseryColumns = ['Nom de la crèche', "Date d'inscription"];
  public bansColumns = ['Crèche', 'Motif', 'Description', 'Actions'];
  public reservationsColumns = ['Date', 'Prix'];
  childrenAdaptationItems = [];
  public effortRates: IEffortRate[] = [];

  //@ts-ignore
  public tarifPsu = this.inscrit?.price?.toFixed(2);

  $refs: {
    billings: OneInscritBillings;
  };

  priceForm = new Forms.Form({
    fields: {
      price: new Forms.TextForm<string>({
        label: 'Prix en €',
        valueType: 'price',
        width: '200px',
        //@ts-ignore
        value: this.inscrit?.price?.toFixed(2),
      }),
    },
    validations: {
      price: { required, decimal, positiveNumber },
    },
  });

  @Watch('priceForm.fieldsValues.price')
  valueChanged(price, oldVal) {
    let value: number = 0;
    if (typeof price == 'string') value = +price.replace(',', '.');
    else value = price;
    if (value) {
      this.pricePerHourForm.fieldsValues.price = Number((+value / 6).toFixed(2)).toFixed(2);
    }
  }

  pricePerHourForm = new Forms.Form({
    fields: {
      price: new Forms.TextForm<string>({
        label: 'Prix par heure en €',
        valueType: 'price',
        width: '200px',
        disabled: true,
      }),
    },
    validations: {
      price: { required, decimal, positiveNumber },
    },
  });

  public psuCalculateForm = new Forms.Form({
    fields: {
      children: new Forms.TextForm<number>({
        type: 'number',
        valueType: 'number',
        value: 1,
        label: "Nombre d'enfants à charge",
        halfWidth: true,
      }),
      salary: new Forms.TextForm<number>({
        label: 'Ressources annuelles (CAF)',
        valueType: 'number',
        type: 'number',
        placeholder: 'en €',
        halfWidth: true,
      }),
    },
    validations: {
      children: { required, maxValue: maxValue(10), minValue: minValue(1), numeric },
      salary: { required, maxValue: maxValue(1000000), minValue: minValue(0), numeric },
    },
  });
  get isNotResident() {
    return !this.inscrit.isResident;
  }

  calculatePrice() {
    this.priceForm.fieldsValues.price = calculatePsuPrice(
      this.psuCalculateForm.getValues().salary,
      this.psuCalculateForm.getValues().children,
      this.isNotResident,
      this.effortRates
    );
  }

  async createBill() {
    var alert = new Alerts.FormAlert({
      title: 'Ajouter une facture',
      formElement: {
        form: new Forms.Form({
          fields: {
            name: new Forms.TextForm({
              label: 'Nom',
            }),
            document: new Forms.UploadForm<File>({
              label: 'Importer la facture',
              uploadType: 'file',
            }),
            date: new Forms.Calendar<Date>({
              label: 'Date de la facture',
              popupProps: {
                nested: true,
              },
            }),
          },
          validations: {
            date: { required, date: dateValidator, isDateBeforeToday: isDateBeforeToday },
            name: { required, maxLength: maxLength(30) },
            document: { required },
          },
        }),
        submit: {
          handler: async ({ form }) => {
            try {
              console.log(form);
              const bill = await sgts
                .createBilling()
                .$fragment(billingFragment)
                .$args({
                  data: {
                    ...form,
                    customer: {
                      connect: {
                        id: this.inscrit.id,
                      },
                    },
                  },
                })
                .$post();
              this.$refs.billings.newSearch();
              new SuccessNotification('Facture ajoutée avec succès');
            } catch (e) {
              var error = new ErrorNotification('Erreur lors de la création de la facture');
            }
          },
        },
      },
    });
  }

  getAlimentationValueForChildAdaptationForm(adaptationForm: IChildAdaptationForm) {
    let res = 'Aucune notes';
    if (adaptationForm?.foodRestriction != null) {
      res = adaptationForm.foodRestriction ? 'Oui' : 'Non';
      if (adaptationForm.foodRestriction)
        res = res + ', ' + adaptationForm.foodRestrictionDescription;
    }
    return res;
  }

  getBreastfedValueForChildAdaptationForm(adaptationForm: IChildAdaptationForm) {
    let res = 'Aucune notes';
    if (adaptationForm?.breastfed != null) {
      res = breastfedType[adaptationForm.breastfed];
    }
    return res;
  }

  getHygieneValueForChildAdaptationForm(adaptationForm: IChildAdaptationForm) {
    let res = 'Aucune notes';
    if (adaptationForm?.hygiene != null) {
      res = hygieneType[adaptationForm.hygiene];
    }
    return res;
  }

  getMotorSkillsValueForChildAdaptationForm(adaptationForm: IChildAdaptationForm) {
    let res = 'Aucune notes';
    if (
      adaptationForm?.childAdaptationFormMotorSkills != null &&
      adaptationForm?.childAdaptationFormMotorSkills.length > 0
    ) {
      res = '';
      adaptationForm?.childAdaptationFormMotorSkills.forEach((element) => {
        res += `${motorSkillsType[element.motorSkills]}, `;
      });
    }
    return res;
  }

  getSleepValueForChildAdaptationForm(adaptationForm: IChildAdaptationForm) {
    let res = 'Aucune notes';
    if (adaptationForm?.morningSleepTime != null) {
      res = `Matin (${sleepHoursConstant[adaptationForm.morningSleepTime]}) / Après-midi (${
        sleepHoursConstant[adaptationForm.afternoonSleepTime]
      })`;
    }
    return res;
  }

  getAllergyValueForChildAdaptationForm(adaptationForm: IChildAdaptationForm) {
    let res = 'Aucune notes';
    if (adaptationForm?.allergy != null) {
      res = adaptationForm.allergy ? 'Oui' : 'Non';
      if (adaptationForm.allergyDescription) res = res + ', ' + adaptationForm.allergyDescription;
    }
    return res;
  }

  async mounted() {
    this.effortRates = await getAllEffortRatesQuery({ where: { id: '' } });
    const res = await getChildAdaptationFormsByParent({ where: { parent: this.inscrit.id } });
    if (res.length > 0) {
      this.childrenAdaptationItems = res;
    }
  }

  get isJobSeeker() {
    return (
      this.inscrit.isJobSeeker &&
      this.documents.some((x) => x.type === 'JOB_SEEKER_CERTIFICATE' && x.status === 'OK')
    );
  }

  get isResident() {
    return this.inscrit.isResident;
  }

  get title() {
    return `Profil utilisateur ${this.inscrit.isBanned ? "(Banni de crècheslib')" : ''}`;
  }

  get address() {
    return `${this.inscrit.address}, ${this.inscrit.zipCode} ${this.inscrit.city}`;
  }

  get companyFirst() {
    return this.inscrit.companyFirst ? this.inscrit.companyFirst : 'Non renseigné';
  }
  get companySecond() {
    return this.inscrit.companySecond ? this.inscrit.companySecond : 'Non renseigné';
  }
  get companyCode() {
    return this.inscrit.companyCode ? this.inscrit.companyCode : 'Non renseigné';
  }

  get redirect() {
    if (this.isDirector) {
      return routesNames.directeur.DEMANDES.DETAIL;
    } else {
      return routesNames.admin.DEMANDES;
    }
  }

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }

  get nurseryId() {
    if (this.isDirector) {
      return DirectorNurseryModule.state.nursery.id;
    } else {
      return this.$route.query.nursery;
    }
  }

  get nurseries() {
    return uniqBy(
      this.inscrit.registrations
        .filter((f) => f.status === 'DONE')
        .map((m) => ({
          ...m.nursery,
          date: format(m.createdAt, 'DD/MM/YYYY'),
          banned: !!this.inscrit.bans.find((f) => f.nursery.id === m.nursery.id),
        })),
      'id'
    );
  }

  get nurseryLink(): (id) => Location {
    return (id: string) => {
      if (this.isAdmin) {
        return {
          name: routesNames.admin.CRECHEDETAIL.DETAIL,
          params: {
            id,
          },
        };
      }
    };
  }

  get banReason() {
    return (reason) => BanReasonConstant[reason];
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get isDirector() {
    return AuthModule.getters.isDirector;
  }

  get children() {
    return this.inscrit.children.map((m) => ({
      ...m,
      allergies: m.allergies.map((m) => allergyConstants[m]),
      adaptationForm: this.childrenAdaptationItems.find((x) => x.child.id == m.id),
    }));
  }

  get documents() {
    return this.inscrit.documents;
  }

  get cafDocuments() {
    return this.inscrit.cafDocuments;
  }

  get nurseryBan() {
    return this.inscrit.bans.find((ban) => ban.nursery.id === oc(this.nursery).id());
  }

  get isBannedNursery() {
    return this.inscrit.bans.some((ban) => ban.nursery.id === oc(this.nursery).id());
  }

  get isBannedFromAnyNursery() {
    return this.inscrit.bans.length;
  }

  get banned() {
    return this.isBannedFromAnyNursery || this.inscrit.isBanned;
  }

  get changeTarifPsu() {
    return this.tarifPsu;
  }

  get formatBirthDate() {
    return (birthdate: Date) => format(birthdate, 'DD/MM/YYYY');
  }

  get backLink(): Location {
    if (AuthModule.getters.isAdmin) {
      return { name: routesNames.admin.INSCRITS.LIST };
    } else {
      return { name: routesNames.directeur.INSCRITS.LIST };
    }
  }

  async deleteBan(id: string) {
    var alert = new Alerts.ConfirmAlert({
      title: 'Débannir de la crèche?',
      actions: [
        new Alerts.ConfirmAction({
          text: 'Débannir',
          handler: async () => {
            try {
              await deleteBanMutation({
                where: { id },
              });
              if (this.isAdmin) {
                CustomersModule.actions.getOneCustomer(this.inscrit.id);
              } else {
                DirectorCustomersModule.actions.getOneCustomer(this.inscrit.id);
              }
              new SuccessNotification('Utilisateur débanni');
            } catch (e) {
              console.log(e);
              var error = new ErrorNotification("Erreur lors du debannissement de l'utilisateur");
            }
          },
        }),
      ],
    });
  }

  async banCustomer() {
    if (AuthModule.getters.isAdmin) {
      var alert = new Alerts.FormAlert({
        title: `Voulez-vous vraiment bannir ${this.inscrit.firstName} du service crècheslib'?`,
        description: `${this.inscrit.firstName} sera informé de son bannissement, il ne serra plus en mesure de réserver ou d’accéder au service de crècheslib'`,
        formElement: {
          form: new Forms.Form({
            fields: {
              reason: new Forms.Select({
                label: 'Raison',
                options: constantToSelectFormater(BanReasonConstant),
              }),
              description: new Forms.FieldForm({
                label: 'Description du bannisement',
              }),
            },
            validations: {
              reason: { required },
            },
          }),
          submit: {
            params: {
              id: this.inscrit.id,
            },
            handler: CustomersModule.actions.banCustomer,
          },
        },
      });
    } else {
      var banishmentAlert = new Alerts.FormAlert({
        title: `Voulez-vous vraiment bannir ${this.inscrit.firstName} de la crèche?`,
        formElement: {
          form: new Forms.Form({
            fields: {
              reason: new Forms.Select({
                label: 'Raison',
                options: constantToSelectFormater(BanReasonConstant),
              }),
              description: new Forms.FieldForm({
                label: 'Description du bannisement',
              }),
            },
            validations: {
              reason: { required },
            },
          }),
          submit: {
            params: {
              customer: this.inscrit.id,
              nursery: DirectorNurseryModule.state.nursery.id,
            },
            handler: DirectorCustomersModule.actions.createBan,
          },
        },
      });
    }
  }

  debanCustomer() {
    var alert = new Alerts.ConfirmAlert({
      title: "Débannir de crècheslib'",
      actions: [
        new Alerts.ConfirmAction({
          text: 'Débannir',
          handler: async () => {
            try {
              await updateCustomerMutation({
                where: { id: this.inscrit.id },
                data: {
                  isBanned: false,
                },
              });
              if (this.isAdmin) {
                CustomersModule.actions.getOneCustomer(this.inscrit.id);
              }
              new SuccessNotification('Utilisateur débanni');
            } catch (e) {
              console.log(e);
              var error = new ErrorNotification("Erreur lors du debannissement de l'utilisateur");
            }
          },
        }),
      ],
    });
  }

  updatePrice() {
    NotificationsModule.actions.addLoader({
      message: 'Modification du prix',
      successMessage: 'Prix modifié!',
      errorMessage: 'Erreur lors de la modification du prix',
      handler: async () => {
        const price = Number(this.priceForm.getValues().price);
        try {
          await updateCustomerMutation({
            where: {
              id: this.inscrit.id,
            },
            data: {
              price,
            },
          });
          this.tarifPsu = price.toFixed(2);
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        }
      },
    });
  }

  uploadCafDocument: boolean = false;

  public createCafDocForm = new Forms.UploadForm({
    uploadType: 'file',
  }).extract();

  public cafDocumentTitle: string = '';
  cafDocumentTitleForm = new Forms.TextForm<any>({
    value: this.cafDocumentTitle,
    editMode: !!this.cafDocumentTitle,
    valueType: 'string',
    placeholder: 'Intitulé de document',
  });

  async createCafDocument() {
    try {
      const document = await sgts
        .uploadCafDocument()
        .$fragment(cafDocumentFragment)
        .$args({
          where: {
            customer: { id: this.inscrit.id },
          },
          data: {
            file: this.createCafDocForm.value as unknown as File,
            title: this.cafDocumentTitle,
          },
        })
        .$post();
      new SuccessNotification('Document ajouté!');
      this.cafDocumentTitle = '';
      this.createCafDocForm.reset();
      this.uploadCafDocument = false;
      await CustomersModule.actions.getOneCustomer(this.inscrit.id);
    } catch (e) {
      console.log(e);
      var error = new ErrorNotification("Erreur lors de l'ajout du document");
    }
  }

  async cancelCafDocumentUpload() {
    this.cafDocumentTitle = '';
    this.createCafDocForm.reset();
    this.uploadCafDocument = false;
  }
}
