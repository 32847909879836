


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import PlanningTemplate from '../Shared/PlanningTemplate.vue';
import { INursery, INurseryWithRevenues } from '@models';
import { getNurseryQuery } from '@graphql';
import { DirectorNurseryModule, NurseryModule } from '@store';
import { Alerts, Forms, SuccessNotification } from '@constructors';
import { required } from 'vuelidate/lib/validators';
import { constantToSelectFormater } from '../../../utils';
import { weekDaysConstant } from '../../../constants';
import { isDateAfter, isDateBefore, isTimeAfter } from '../../../utils/validators';
import { CheckBox, RadioTabs } from '@components';
import TemplatePlanningDirecteur from './TemplatePlanningDirecteur.vue';

@Component({
  components: { PlanningTemplate, CheckBox, TemplatePlanningDirecteur, RadioTabs },
})
export default class PlanningDirecteur extends Vue {
  public loading = false;
  public filterValue = '';

  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }

  get isCommunityNursery() {
    return this.nursery?.communityNursery === true;
  }

  get title() {
    return `${this.showPalnningCommuntyTemplate ? 'Modèle de planification' : 'Planning'} ${
      this.nursery.name
    }`;
  }

  async updateValue(type: string) {
    await NurseryModule.actions.updateNursery({
      id: this.nursery.id,
      data: {
        ...(type === 'oneDayBeforeReservation' && {
          oneDayBeforeReservation: this.nursery.oneDayBeforeReservation,
        }),
        ...(type === 'visitable' && { visitable: this.nursery.visitable }),
      },
    });
  }

  modifySchedule() {
    var alert = new Alerts.FormAlert({
      title: 'Modifier les horaires',
      formElement: {
        form: new Forms.Form({
          fields: {
            schedule: {
              title1: new Forms.Label("Horaire d'ouverture et de fermeture"),
              openingHours: new Forms.TextForm({
                type: 'time',
                label: "Horaire d'ouverture",
                value: () => this.nursery.schedule.openingHours,
                editMode: true,
                halfWidth: true,
              }),
              closingHours: new Forms.TextForm({
                type: 'time',
                label: 'Horaire de fermeture',
                value: () => this.nursery.schedule.closingHours,
                editMode: true,
                halfWidth: true,
              }),
              title2: new Forms.Label('Arrivée matinée'),
              morningStartRangeFirst: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.morningStartRangeFirst,
                editMode: true,
                halfWidth: true,
              }),
              morningStartRangeSecond: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.morningStartRangeSecond,
                editMode: true,
                halfWidth: true,
              }),
              title3: new Forms.Label('Départ matinée'),
              morningEndRangeFirst: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.morningEndRangeFirst,
                editMode: true,
                halfWidth: true,
              }),
              morningEndRangeSecond: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.morningEndRangeSecond,
                editMode: true,
                halfWidth: true,
              }),
              title4: new Forms.Label('Arrivée après-midi'),
              afternoonStartRangeFirst: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.afternoonStartRangeFirst,
                editMode: true,
                halfWidth: true,
              }),
              afternoonStartRangeSecond: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.afternoonStartRangeSecond,
                editMode: true,
                halfWidth: true,
              }),
              title5: new Forms.Label('Depart après-midi'),
              afternoonEndRangeFirst: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.afternoonEndRangeFirst,
                editMode: true,
                halfWidth: true,
              }),
              afternoonEndRangeSecond: new Forms.TextForm({
                type: 'time',
                value: () => this.nursery.schedule.afternoonEndRangeSecond,
                editMode: true,
                halfWidth: true,
              }),
            },
          },
          validations: {
            schedule: {
              morningStartRangeFirst: { required },
              openingHours: { required },
              closingHours: { required, isTimeAfter: isTimeAfter('openingHours') },
              morningStartRangeSecond: {
                required,
                isTimeAfter: isTimeAfter('morningStartRangeFirst'),
              },
              morningEndRangeFirst: {
                required,
                isTimeAfter: isTimeAfter('morningStartRangeSecond'),
              },
              morningEndRangeSecond: { required, isTimeAfter: isTimeAfter('morningEndRangeFirst') },
              afternoonStartRangeFirst: {
                required,
                isTimeAfter: isTimeAfter('morningEndRangeSecond'),
              },
              afternoonStartRangeSecond: {
                required,
                isTimeAfter: isTimeAfter('afternoonStartRangeFirst'),
              },
              afternoonEndRangeFirst: {
                required,
                isTimeAfter: isTimeAfter('afternoonStartRangeSecond'),
              },
              afternoonEndRangeSecond: {
                required,
                isTimeAfter: isTimeAfter('afternoonEndRangeFirst'),
              },
            },
          },
        }),
        submit: {
          params: {
            id: this.nursery.id,
          },
          handler: DirectorNurseryModule.actions.updateNursery,
        },
      },
    });
  }

  modifyVisitDay() {
    var alert = new Alerts.FormAlert({
      title: "Choix d'une demi-journée d'accueil",
      formElement: {
        form: new Forms.Form({
          fields: {
            visit: {
              weekDay: new Forms.Select<number>({
                label: 'Jour de la semaine',
                editMode: true,
                value: () => this.nursery.visit.weekDay,
                valueType: 'number',
                options: constantToSelectFormater(weekDaysConstant),
              }),
              start: new Forms.TextForm({
                label: 'Début',
                type: 'time',
                value: () => this.nursery.visit.start,
                editMode: true,
              }),
              end: new Forms.TextForm({
                label: 'Fin',
                type: 'time',
                value: () => this.nursery.visit.end,
                editMode: true,
              }),
            },
          },
          validations: {
            visit: {
              weekDay: { required },
              end: { required, isTimeAfter: isTimeAfter('start') },
              start: { required },
            },
          },
        }),
        submit: {
          params: {
            id: this.nursery.id,
          },
          handler: DirectorNurseryModule.actions.updateNursery,
        },
      },
    });
  }

  get showPalnningCommuntyTemplate() {
    return this.filterValue === 'PLANNING_TEMPLATE';
  }

  get getFilterValue() {
    return this.filterValue;
  }

  set getFilterValue(value) {
    this.filterValue = value;
  }

  public tabs: Forms.IRadioItem<string>[] = [
    { value: 'PLANNING_TEMPLATE', text: 'Planning Template' },
    { value: '', text: 'Planning' },
  ];
}
