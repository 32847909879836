import gql from 'graphql-tag';

export const emailFragment = gql`
  fragment emailFragment on Mail {
    id
    type
    object
    content
    name
    variables {
      id
      name
      value
    }
    createdAt
    updatedAt
  }
`;
