import gql from 'graphql-tag';

const documentFragment2 = gql`
  fragment documentFragment2 on Document {
    id
    type
    status
    format
    required
    expirationDate
    reason
    comment
    updatedAt
  }
`;

export const availabilityFragment = gql`
  fragment availabilityFragment on Availability {
    id
    section {
      id
      name
      capacity
    }
    sections {
      id
      name
      capacity
      remainingPlaces
    }
    remainingPlaces
    type
    date
  }
`;

export const reservationFragment = gql`
  fragment reservationFragment on Reservation {
    id
    status
    statusReason
    price
    extraPrice
    psuPrice
    type
    date
    updatedAt
    refusedBy
    fromHours
    toHours
    isSummerCampDate
    receipts {
      id
      reference
      date
      status
      isCesuActive
      eCesuAmount
      eCesuCode
    }
    nursery {
      id
      name
      type
      analyticCode
      communityNursery
      dspNursery
    }
    child {
      id
      firstName
      lastName
      birthDate
      notes
      picture {
        id
        url
      }
      documents {
        ...documentFragment2
      }
      parent {
        id
        firstName
        lastName
        address
        email
        phone
        zipCode
        price
        isJobSeeker
        isResident
        documents {
          ...documentFragment2
        }
        picture {
          id
          url
        }
      }
    }
  }
  ${documentFragment2}
`;

export const reservationWithoutChildFragment = gql`
  fragment reservationWithoutChildFragment on Reservation {
    id
    status
    statusReason
    price
    type
    date
  }
`;
