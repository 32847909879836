import { sgts } from '@services';
import {
  IupdateDspNurseryZipCodesArgs,
  IupdateNurseryArgs,
  IupdateNurseryConfigArgs,
} from '@models';
import { nurseryFragment } from '@fragments';

export const updateNurseryMutation = (data: IupdateNurseryArgs) => {
  return sgts.updateNursery().$fragment(nurseryFragment).$args(data).$post();
};

export const updateCommunityNurseryAvailabilityMutation = (data: IupdateNurseryArgs) => {};

export const updateNurseryConfigMutation = (data: IupdateNurseryConfigArgs) => {
  return sgts.updateNurseryConfig().$fragment('price').$args(data).$post();
};

export const updateDspNurseryZipCodesMutation = async (data: IupdateDspNurseryZipCodesArgs) => {
  await sgts.updateDspNurseryZipCodes().$args(data).$post();
};
