



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Forms } from '@constructors';
import { InfiniScrollMixin } from '@components';
import { required, decimal } from 'vuelidate/lib/validators';
import { routesNames } from '@router';
import { format, isAfter, differenceInMonths } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { ICustomer, IChildConnection, IwaitingChildArgs, IChild } from '@models';
import { CustomersModule } from '@store';
import { oc } from 'ts-optchain';
import { isDateAfter } from '@validators';
import { sgts } from '@services';
import { childFragment, childValidationFragment } from '@fragments';

const listHandler = (args) =>
  sgts.waitingChild().$fragment(childValidationFragment).$args(args).$fetch();

@Component({
  components: {},
})
export default class InscritsPending extends InfiniScrollMixin<
  IChildConnection,
  IwaitingChildArgs
> {
  public routesNames = routesNames;
  public columns = ['Parent', 'Enfant', "Date d'inscription", 'Demandes en cours', ''];

  public listHandler = listHandler;

  public results: IChild[] = [];

  get customerList() {
    return this.results.map((child) => ({
      ...child,
      documents: [...child.parent.documents, ...child.documents],
      child: `${child.firstName}, ${differenceInMonths(new Date(), child.birthDate)} mois`,
      createdAt: format(child.createdAt, 'DD MMM YYYY', { locale: frLocale }),
      registrations: child.registrations
        ?.filter((f) => f.status === 'PENDING')
        .map((m) => m.nursery.name)
        .join(','),
    }));
  }

  get redirectRoute() {
    return (id: string) => ({
      name: routesNames.admin.INSCRITS.DETAIL,
      params: {
        customerId: id,
      },
    });
  }

  newContent(data: IChild[]) {
    this.results = data;
  }

  get handlerParams(): IwaitingChildArgs {
    return {
      where: {
        search: this.search,
      },
      limit: 20,
    };
  }

  get bannedLink() {
    return routesNames.admin.INSCRITS.BANNED;
  }
}
