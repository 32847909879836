






import { Component, Vue } from 'vue-property-decorator';
import { HeaderComponent } from '@components';
import { AuthModule } from '@store';

@Component({
  components: {
    HeaderComponent,
  },
})
export default class BaseApp extends Vue {
  get isUserLoggedIn() {
    return AuthModule.state.loggedIn;
  }
}
