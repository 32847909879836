







































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPlanningEvent, IReservation, IRegistration } from '@models';
import { AuthModule } from '@store';
import { Location } from 'vue-router';
import { routesNames } from '@router';

@Component({})
export default class PlanningSlot extends Vue {
  @Prop() reservations?: IReservation[];
  @Prop() registrations?: IRegistration[];
  @Prop() type: 'resa' | 'reg';

  get reservationsFiltered() {
    if (this.isResa) {
      return this.reservations;
    } else {
      return this.registrations;
    }
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get redirectLink(): (id: string) => Location {
    return (id) => {
      const query = { type: this.isResa ? 'reservation' : 'registration' };
      const name = this.isAdmin
        ? routesNames.admin.CRECHEDETAIL.DEMANDES.DETAIL
        : routesNames.directeur.DEMANDES.DETAIL;
      return {
        name,
        params: {
          demandeId: id,
        },
        query,
      };
    };
  }

  get isResa() {
    return this.type === 'resa';
  }

  get title() {
    return this.isResa ? 'Réservations' : 'Demandes';
  }

  get terminaison() {
    return this.isResa ? 'rés.' : 'dem.';
  }
}
