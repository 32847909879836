










import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavLink } from '@models';
import CrecheInfos from './CrecheInfos.vue';
import CrecheDescription from './CrecheDescription.vue';
import CrecheChiffres from './CrecheChiffres.vue';

@Component({
  components: {
    CrecheInfos,
    CrecheDescription,
    CrecheChiffres,
  },
})
export default class CrechesDetailRoot extends Vue {}
