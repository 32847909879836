import gql from 'graphql-tag';

export const receipFragment = gql`
  fragment receipFragment on Receipt {
    id
    reference
    date
    status
    isCesuActive
    eCesuAmount
    eCesuCode
  }
`;
