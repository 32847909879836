


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormCalendar } from '@components';
import { Forms } from '@constructors';
import { endOfMonth, startOfMonth } from 'date-fns';
import { INurseryKeyFigures } from '@models';
import { nurseryKeyFiguresQuery } from '@graphql';
import { NurseryModule } from '@store';

@Component({
  components: { FormCalendar },
})
export default class CrecheChiffres extends Vue {
  get nursery() {
    return NurseryModule.state.oneNursery;
  }

  public keyFigures: INurseryKeyFigures = null;
  public defaultPeriod: Forms.ICalendarPeriodType = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };
  public calendar = new Forms.Calendar({
    selectedSideListItem: 'Ce mois ci',
    value: this.defaultPeriod,
    calendarType: 'period',
    placeholder: 'Période',
    sideList: true,
    noMargin: true,
    width: '260px',
  }).extract();

  async getKeyFigures({ start, end }: Forms.ICalendarPeriodType) {
    if (start && end) {
      this.keyFigures = await nurseryKeyFiguresQuery({
        id: this.nursery.id,
        start,
        end,
      });
    }
  }

  mounted() {
    this.getKeyFigures(this.defaultPeriod);
  }
}
