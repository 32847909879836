





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IDocument } from '@models';
import { documentNamesConstant } from '@constants';
import { format } from 'date-fns';
import DocumentViewer from './DocumentViewer.vue';
import { AuthModule, DirectorNurseryModule, NurseryModule } from '@store';

@Component({
  components: { DocumentViewer },
})
export default class DocumentWithAction extends Vue {
  @Prop() document: IDocument;
  @Prop() disabled: boolean;

  public showModal = false;

  get name() {
    if ((this.isCommunityNursery || this.isDspNursery) && this.document.type === 'PROOF_OF_ADDRESS')
      return 'Justificatif de domicile';
    return documentNamesConstant[this.document.type];
  }

  get isCommunityNursery() {
    return this.nursery?.communityNursery === true;
  }

  get isDspNursery() {
    return this.nursery?.dspNursery === true;
  }

  get nursery() {
    return this.isAdmin ? NurseryModule.state.oneNursery : DirectorNurseryModule.state.nursery;
  }

  get isAdmin() {
    return AuthModule.getters.isAdmin;
  }

  get validated() {
    return this.document.status === 'OK';
  }

  get declined() {
    return this.document.status === 'DECLINED';
  }
  get pending() {
    return this.document.status === 'PENDING';
  }
  get expired() {
    return this.document.status === 'EXPIRED';
  }

  get dateUpdated() {
    return format(this.document.updatedAt, 'DD/MM/YYYY');
  }

  declineDocument() {
    this.$emit('decline', this.document.id);
  }

  validateDocument() {
    this.$emit('validate', this.document.id);
  }
}
