import { defineModule } from '@/vuex-typed-modules';
import {
  IUser,
  IcustomersConnectionArgs,
  ICustomer,
  ICustomerExtended,
  IBan,
  ICreateBanInput,
  ICreateBanWhereInput,
  IbanCustomerArgs,
  ICustomerWhereUniqueInput,
  ICustomerWithRegistration,
  IbansConnectionArgs,
  IRegistered,
} from '@models';
import { customersConnectionQuery, customerQuery, bansConnectionQuery } from '@graphql';
import { ErrorNotification, SuccessNotification, Forms, Alerts } from '@constructors';
import { banCustomerMutation } from '@/graphql/Customer/customer.mutations';

interface ICustomersState {
  customers: IRegistered[];
  oneCustomer: ICustomerWithRegistration;
  bannedCustomers: ICustomerExtended[];
}

//State
const state: ICustomersState = {
  customers: [],
  oneCustomer: null,
  bannedCustomers: [],
};

const mutations = {};

const actions = {
  async getCustomers(context, payload: IcustomersConnectionArgs) {
    const { edges, pageInfo, aggregate } = await customersConnectionQuery(payload);
    CustomersModule.updateState({
      customers: edges.map((e) => e.node) || [],
    });
    return { edges, pageInfo, aggregate };
  },
  async getOneCustomer(context, id: string) {
    const customer = await customerQuery(id);
    CustomersModule.updateState({
      oneCustomer: customer,
    });
    return customer;
  },
  async getBannedCustomers(context, payload: IbansConnectionArgs) {
    bansConnectionQuery.$abort();
    const { edges, pageInfo, aggregate } = await bansConnectionQuery.$args(payload).$fetch();
    CustomersModule.updateState({
      bannedCustomers: edges.map((e) => e.node) || [],
    });
    return { edges, pageInfo, aggregate };
  },
  async banCustomer(
    context,
    { form, params }: Alerts.FormPayload<ICreateBanInput, ICustomerWhereUniqueInput>
  ) {
    try {
      await banCustomerMutation({ where: params, data: form });
      CustomersModule.actions.getOneCustomer(params.id);
      new SuccessNotification('Utilisateur bannis avec succès');
    } catch (e) {
      var doNotExist = new ErrorNotification("Erreur lors de l'opération");
    }
  },
};

export const CustomersModule = defineModule('CustomersModule', state, {
  mutations,
  actions,
});
