import { sgts } from '@services';
import {
  ILoginInput,
  IActivateUserInput,
  IResetUserPasswordInput,
  IactivateUserArgs,
} from '@models';
import { authPayloadFragment } from '@fragments';

export const login = (data: ILoginInput) => {
  return sgts.login().$fragment(authPayloadFragment).$args({ data }).$post();
};

export const deleteUserMutation = (userId: string) => {
  return sgts.deleteUser().$args({ userId }).$post();
};

export const activateUser = (data: IActivateUserInput) => {
  return sgts
    .activateUser()
    .$fragment(authPayloadFragment)
    .$args({
      data,
    })
    .$post();
};

export const requestResetUserPassword = (userId: string) => {
  return sgts.requestResetUserPassword().$args({ userId }).$fetch();
};
export const requestResetUserPasswordWithEmail = (userEmail: string) => {
  return sgts.requestResetUserPasswordWithEmail().$args({ userEmail }).$fetch();
};

export const resetUserPassword = (data: IResetUserPasswordInput) => {
  return sgts.resetUserPassword().$args({ data }).$post();
};
