
























































import { Component, Vue, Prop } from 'vue-property-decorator';
// to remove
import { range } from 'lodash';
import { IKeyStatistics } from '@models';
import { keyStatisticConstant } from '@constants';
import { keyStatisticsFragment } from '@fragments';
import { sgts } from '@services';
import CAGraph from './ChiffreAffaires';
import InscGraph from './Inscriptions';
import { formatDateExport } from '@utils';

@Component({
  components: { CAGraph, InscGraph },
})
export default class ReportingHome extends Vue {
  public reportingData: IKeyStatistics = null;
  public loading = false;
  public columns = [
    'Activité',
    'Moyenne',
    'M-1',
    'M-2',
    'M-3',
    'M-4',
    'M-5',
    'M-6',
    'Le mois en cours',
  ];

  public keyConstant = keyStatisticConstant;

  get CAgraphData() {
    const { revenues } = this.reportingData;
    return Object.values(revenues);
  }

  get inscriptions() {
    const { registrationAccepted } = this.reportingData;
    return Object.values(registrationAccepted);
  }

  get reservations() {
    const { reservationAccepted } = this.reportingData;
    return Object.values(reservationAccepted);
  }

  get reportingActivity() {
    const { revenuesCancelled, revenues, revenuesPSU, revenuesPAJE, ...rest } = this.reportingData;
    return {
      ...rest,
    };
  }

  get reportingCA() {
    const { revenuesCancelled, revenues, revenuesPSU, revenuesPAJE } = this.reportingData;
    return {
      revenues,
      revenuesCancelled,
      revenuesPSU,
      revenuesPAJE,
    };
  }

  async exportData() {
    const csvContent = await sgts.exportKeyStatistics().$fetch();

    const data = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `Export_Reporting_${formatDateExport()}.csv`);
    link.click();
    link.remove();
  }

  async getData() {
    try {
      this.loading = true;
      const data = await sgts.keyStatistics().$fragment(keyStatisticsFragment).$fetch();
      this.reportingData = data;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.getData();
  }
}
