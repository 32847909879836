




import { Component, Vue, Prop } from 'vue-property-decorator';
import InscritsBannedTemplate from '../../Shared/InscritsBannedTemplate.vue';
import { DirectorNurseryModule } from '@store';

@Component({
  components: { InscritsBannedTemplate },
})
export default class InscritsBanned extends Vue {
  get nursery() {
    return DirectorNurseryModule.state.nursery;
  }
}
