import gql from 'graphql-tag';
import { reservationFragment, availabilityFragment } from './reservation.fragment';
import { registrationWithoutChildFragment } from './registration.fragment';

export const nurseryListFragment = gql`
  fragment nurseryListFragment on NurseryWithRevenues {
    id
    name
    analyticCode
    revenues
    processed
    pendingRegistration
    pendingReservation
  }
`;

export const dspNurseryZipCodesFragment = gql`
  fragment dspNurseryZipCodesFragment on DspNurseryZipCode {
    id
    nursery {
      id
    }
    zipCode
    createdAt
    updatedAt
  }
`;

export const nurseryFragment = gql`
  fragment nurseryFragment on Nursery {
    id
    address
    city
    lat
    lon
    name
    description
    type
    city
    phone
    reservationPhone
    tags
    pictures {
      id
      url
    }
    cegidCode
    schedule {
      id
      morningStartRangeFirst
      morningStartRangeSecond
      morningEndRangeFirst
      morningEndRangeSecond
      afternoonStartRangeFirst
      afternoonStartRangeSecond
      afternoonEndRangeFirst
      afternoonEndRangeSecond
      openingHours
      closingHours
    }
    sections {
      id
      name
      capacity
      ageMin
      ageMax
    }
    nurseryPlus
    pedagogy
    information
    tagGraine
    tagInternationnal
    tagBio
    zipCode
    responsibleName
    responsibleEmail
    analyticCode
    summerCampStartDate
    summerCampEndDate
    formularId
    oneDayBeforeReservation
    onlyPhoneReservationEnable
    visitable
    communityNursery
    dspNursery
    visit {
      end
      start
      weekDay
    }
    closingDates {
      closingDateEnd
      closingDateStart
      id
    }
    communityNurseryZipCodes {
      id
      zipCode
    }
    dspNurseryZipCodes {
      id
      zipCode
    }
  }
`;

export const nurseryPlanningFragment = gql`
  fragment efez on NurseryPlanning {
    reservations {
      ...reservationFragment
    }
    registrations {
      ...registrationWithoutChildFragment
    }
    availabilities {
      ...availabilityFragment
    }
    welcomings {
      id
      date
      status
      child {
        id
        firstName
        lastName
        parent {
          id
          firstName
          lastName
        }
      }
    }
    communityNurseryAvailabilities {
      id
      analyticCode
      nursery {
        id
      }
      type
      code
      capacity
      remainingPlaces
      date
      createdAt
      updatedAt
    }
  }
  ${reservationFragment}
  ${registrationWithoutChildFragment}
  ${availabilityFragment}
`;
