











import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { errorMessagesFormater } from '@validators';
import { Forms } from '@constructors';

@Component({})
export default class FormError extends Vue {
  @Prop({ required: true })
  vl;
  @Prop()
  data: Forms.FormPayload<any> | Forms.UploadForm;

  get filterErrors() {
    return this.vl ? Object.keys(this.vl.$params).filter((key) => !this.vl[key]) : null;
  }
  get error() {
    return this.vl ? this.vl.$error : null;
  }
  get isPending() {
    return this.vl ? this.vl.$pending : null;
  }
  get dirty() {
    return this.vl ? this.vl.$dirty : null;
  }

  public errorMessages = null;

  mounted() {
    this.errorMessages = errorMessagesFormater(this.vl, this.data?.errorMessages);
  }
}
