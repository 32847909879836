





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class PageTemplate extends Vue {
  @Prop() title: string;
}
