













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RadioTabs, InfiniScrollMixin } from '@components';
import { Forms } from '@constructors';
import { range } from 'lodash';
import {
  IRegistrationsConnection,
  IRegistrationStatus,
  IRegistration,
  INursery,
  IreservationsConnectionArgs,
  IregistrationsConnectionArgs,
  IReservationStatus,
} from '@models';
import { differenceInMonths, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

import { registrationStatusConstant, reservationTypeConstant } from '@constants';

@Component({
  components: {
    RadioTabs,
  },
})
export default class Registrations extends InfiniScrollMixin<
  IRegistrationsConnection,
  IregistrationsConnectionArgs
> {
  @Prop() redirect: string;
  @Prop() handler: (args: IregistrationsConnectionArgs) => Promise<IRegistrationsConnection>;
  @Prop() params: IregistrationsConnectionArgs;

  public columns = ['Parent', 'Enfant', 'Réservation', 'Statut', ''];

  public registrations: IRegistration[] = [];

  get listHandler() {
    return this.handler;
  }

  get handlerParams() {
    return this.params;
  }

  get status() {
    return this.params.where.status;
  }

  get statusConstant() {
    return (status: IRegistrationStatus) => registrationStatusConstant[status];
  }

  get redirectRoute() {
    return (id: string) => ({
      name: this.redirect,
      params: {
        demandeId: id,
      },
      query: {
        type: 'registration',
      },
    });
  }

  newContent(data: IRegistration[]) {
    this.registrations = data;
  }

  @Watch('status')
  paramsChanged(value: IreservationsConnectionArgs, oldvalue) {
    this.newSearch();
  }

  get registrationsFiltered() {
    return this.registrations.map((m) => ({
      ...m,
      parent: m.child.parent,
      documents: [...m.child.parent.documents, ...m.child.documents],
      child: `${m.child.firstName}, ${differenceInMonths(new Date(), m.child.birthDate)} mois`,
      ...(m.reservation && {
        reservation: `${format(m.reservation.date, 'DD MMM YYYY', { locale: frLocale })} ${
          reservationTypeConstant[m.reservation.type]
        }`,
      }),
      status: m.status,
    }));
  }
}
